import React from "react";
import BottomSection from "../../../component/common/bottomSection/bottomSection";
import PageColor from "../../../component/common/pageColor/PageColor";
import WalletContainer from "../../../component/common/wallet/WalletContainer";
import { useAppDispatch } from "../../../store/hooks";
import { getAllHabits } from "../../../store/reducers/habitCart/action";
import { Tabs } from "../../../store/reducers/navBar/type";
import CategoryList from "../../bakery/components/categoryList/categoryList";
import styles from "./index.module.css";

const Habits = () => {
  const dispatch = useAppDispatch();
  dispatch(getAllHabits());
  return (
    <PageColor className={styles.container} isColored={true}>
      <div
        className={styles.topPart}
        style={{
          backgroundImage: `url('/assets/inverted_top.png')`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
        }}
      >
        <WalletContainer className={styles.margin} />
        <h2 className={styles.bakeryName}>Mes Habitudes</h2>
      </div>
      <div className={styles.mainContainer}>
        <>
          <div className={styles.infoTextBlock}>
            <div className={styles.infoText}>
              Ici, vous pouvez définir vos habitudes de petits déjeuners pour
              vos commandes récurrentes
            </div>
          </div>
        </>
        <CategoryList isHabit={true} />
      </div>
      <BottomSection hasDrawer={false} tab={Tabs.HABITS} isHabitDrawer={true} />
    </PageColor>
  );
};

export default Habits;
