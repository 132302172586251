import classNames from "classnames";
import React from "react";
import { addZero } from "../../../../technical/string/addZero";
import styles from "./index.module.css";

const Chiffre = ({
  caption,
  nb,
  price,
}: {
  caption: string;
  nb: number;
  price?: boolean;
}) => {
  return (
    <div className={styles.line}>
      <div className={classNames(styles.bigElement, styles.caption)}>
        {caption}
      </div>
      {price ? (
        <div className={styles.smallElement}>
          {addZero((nb / 100).toString())}€
        </div>
      ) : (
        <div className={styles.smallElement}>{nb}</div>
      )}
    </div>
  );
};

export default Chiffre;
