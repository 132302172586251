import classNames from "classnames";
import React from "react";
import styles from "./index.module.css";
import { Button as MuiButton } from "@mui/material";

interface Props {
  text: React.ReactNode | string;
  onClick?: (value: any) => void;
  disabled?: boolean;
  outlined?: boolean;
  divClassName?: string;
  textClassName?: string;
  id?: string;
  buttonStyle?: React.CSSProperties;
}

const Button = ({
  onClick,
  text,
  disabled,
  outlined,
  divClassName,
  textClassName,
  id,
  buttonStyle,
}: Props) => {
  return (
    <MuiButton
      className={classNames(
        styles.baseButton,
        divClassName,
        outlined && !disabled ? styles.buttonOutlinedText : styles.buttonText,
        textClassName,

        disabled
          ? styles.confirmButtonDisabled
          : outlined
          ? styles.outlinedButton
          : styles.fullButton
      )}
      disabled={disabled}
      id={id}
      onClick={onClick}
      style={buttonStyle}
    >
      {text}
    </MuiButton>
  );
};
export default Button;
