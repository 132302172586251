export enum Tabs {
  CART = "/",
  CALENDAR = "/calendar",
  HABITS = "/habits",
  PROFILE = "/profile",
  ORDER = "/orders",
  SUIVI_CA = "/suiviCa",
}
export interface NavBarDispatchAction {
  tab: Tabs;
}
