import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BottomSection from "../../../component/common/bottomSection/bottomSection";
import {
  activateLoading,
  deactivateLoading,
} from "../../../store/reducers/appState";
import { Tabs } from "../../../store/reducers/navBar/type";
import Checkout from "../components/checkout/Checkout";
import SelectAmount from "../components/selectAmount/SelectAmount";
import { createCheckoutSession } from "./getCheckoutUrl";
import styles from "./index.module.css";

enum Steps {
  WARNING,
  SELECT_AMOUNT,
  CHECKOUT,
  PAYMENT,
}

const Wallet = () => {
  const [error, setError] = useState<string | undefined>();
  const [steps, setSteps] = useState<Steps>(Steps.WARNING);
  const [amount, setAmount] = useState<number | undefined>();
  const [stripePriceId, setStripePriceId] = useState<string | undefined>();
  const dispatch = useDispatch();
  const handleSelectAmountSteps = () => {
    if (!stripePriceId) {
      setError("Veuillez sélectionner un prix");
    } else {
      setSteps(Steps.CHECKOUT);
    }
  };

  const handleCheckoutSteps = async () => {
    dispatch(activateLoading());
    if (!stripePriceId) {
      setError("Veuillez sélectionner un prix");
      return;
    }
    const checkoutUrl = await createCheckoutSession(
      { price: stripePriceId, quantity: 1 },
      "payment"
    );
    if (checkoutUrl === null) {
      dispatch(deactivateLoading());
      setError("Une erreur est survenue");
      return;
    }
    window.open(checkoutUrl, "_self");
    dispatch(deactivateLoading());
  };

  switch (steps) {
    case Steps.CHECKOUT:
      return (
        <div className={styles.container}>
          <Checkout
            amount={amount!}
            handleClick={handleCheckoutSteps}
            error={error}
          />
          <BottomSection tab={Tabs.PROFILE} />
        </div>
      );
    default:
      return (
        <div className={styles.container}>
          <SelectAmount
            handleClick={handleSelectAmountSteps}
            amount={amount}
            setAmount={setAmount}
            setStripePriceId={setStripePriceId}
            setError={setError}
            error={error}
          />
          <BottomSection tab={Tabs.PROFILE} />
        </div>
      );
  }
};

export default Wallet;
