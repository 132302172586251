import React from "react";
import { Tabs } from "../../../../../store/reducers/navBar/type";
import { ReactComponent as GreyCart } from "../../../../../svg/greyCart.svg";
import { ReactComponent as GreyCalendar } from "../../../../../svg/greyCalendar.svg";
import { ReactComponent as GreyHabits } from "../../../../../svg/greyHabits.svg";
import { ReactComponent as GreyProfile } from "../../../../../svg/greyProfile.svg";
import { ReactComponent as GreyCa } from "../../../../../svg/greyCa.svg";
import { ReactComponent as ActiveCart } from "../../../../../svg/orangeCart.svg";
import { ReactComponent as ActiveCalendar } from "../../../../../svg/orangeCalendar.svg";
import { ReactComponent as ActiveHabits } from "../../../../../svg/orangeHabits.svg";
import { ReactComponent as ActiveProfile } from "../../../../../svg/orangeProfile.svg";
import { ReactComponent as ActiveCa } from "../../../../../svg/activeCa.svg";
import styles from "./index.module.css";
import { Role } from "../../../../../store/reducers/auth/type";

interface Props {
  activeTab: Tabs;
  userRole: Role;
  handleClick: (tab: Tabs) => void;
}

const UserNavBar = ({ handleClick, activeTab, userRole }: Props) => {
  return (
    <div className={styles.container}>
      <div
        onClick={() => handleClick(Tabs.CART)}
        className={styles.iconContainer}
      >
        {activeTab === Tabs.CART ? <ActiveCart /> : <GreyCart />}
        <div
          className={
            activeTab === Tabs.CART ? styles.activeText : styles.inactiveText
          }
        >
          {userRole === Role.DELIVERY_MAN ? "Home" : "Boutique"}
        </div>
      </div>
      {userRole === Role.USER || userRole === Role.BAKER ? (
        <div
          onClick={() => {
            userRole === Role.BAKER
              ? handleClick(Tabs.ORDER)
              : handleClick(Tabs.CALENDAR);
          }}
          className={styles.iconContainer}
        >
          {activeTab === Tabs.CALENDAR ? <ActiveCalendar /> : <GreyCalendar />}
          <div
            className={
              activeTab === Tabs.CALENDAR
                ? styles.activeText
                : styles.inactiveText
            }
          >
            {userRole === Role.USER ? "Calendrier" : "Commandes"}
          </div>
        </div>
      ) : null}
      {userRole === Role.USER ? (
        <div
          onClick={() => handleClick(Tabs.HABITS)}
          className={styles.iconContainer}
        >
          {activeTab === Tabs.HABITS ? <ActiveHabits /> : <GreyHabits />}
          <div
            className={
              activeTab === Tabs.HABITS
                ? styles.activeText
                : styles.inactiveText
            }
          >
            Habitudes
          </div>
        </div>
      ) : null}
      {userRole === Role.BAKER ? (
        <div
          onClick={() => handleClick(Tabs.SUIVI_CA)}
          className={styles.iconContainer}
        >
          {activeTab === Tabs.SUIVI_CA ? <ActiveCa /> : <GreyCa />}
          <div
            className={
              activeTab === Tabs.SUIVI_CA
                ? styles.activeText
                : styles.inactiveText
            }
          >
            Suivi CA
          </div>
        </div>
      ) : null}
      <div
        onClick={() => handleClick(Tabs.PROFILE)}
        className={styles.iconContainer}
      >
        {activeTab === Tabs.PROFILE ? <ActiveProfile /> : <GreyProfile />}
        <div
          className={
            activeTab === Tabs.PROFILE ? styles.activeText : styles.inactiveText
          }
        >
          Profil
        </div>
      </div>
    </div>
  );
};

export default UserNavBar;
