import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Button from "../../../../component/common/button/Button";
import SwipeableTemporaryDrawer from "../../../../component/common/SwipeableTemporaryDrawer/SwipeableTemporaryDrawer";
import { getImageGallery } from "../../../../technical/github/getImageGallery";
import { GithubGalleryResponse } from "../../../../technical/github/type";
import styles from "./index.module.css";

interface Props {
  drawerOpen: boolean;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  onImageSelect: (value: string) => void;
  closeDrawer: () => void;
  preSelectedImage?: string;
}

const Gallery = ({
  drawerOpen,
  toggleDrawer,
  onImageSelect,
  closeDrawer,
  preSelectedImage,
}: Props) => {
  const [thumbnails, setThumbnails] = useState<GithubGalleryResponse[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    preSelectedImage
  );

  useEffect(() => {
    async function getImages() {
      setLoading(true);
      getImageGallery().then((images) => {
        setThumbnails(images);
        setLoading(false);
      });
    }

    getImages();
  }, []);

  return (
    <SwipeableTemporaryDrawer
      drawerOpen={drawerOpen}
      toggleDrawer={toggleDrawer}
    >
      {loading ? (
        <div className={classNames(styles.container, styles.loading)}>
          <CircularProgress />
          Chargement
        </div>
      ) : (
        <div className={classNames(styles.container, styles.galleryContainer)}>
          <div className={styles.galery}>
            {thumbnails.map((thumbnail, index) => {
              if (
                thumbnails.length % 2 !== 0 &&
                index === thumbnails.length - 1
              ) {
                return (
                  <>
                    <img
                      key={thumbnail.download_url}
                      alt={thumbnail.name}
                      src={thumbnail.download_url}
                      className={classNames(
                        styles.image,
                        selectedImage === thumbnail.download_url
                          ? styles.selected
                          : {}
                      )}
                      onClick={() => setSelectedImage(thumbnail.download_url)}
                    />
                    <div className={styles.falseDiv}></div>
                  </>
                );
              }
              return (
                <img
                  key={thumbnail.download_url}
                  alt={thumbnail.name}
                  src={thumbnail.download_url}
                  className={classNames(
                    styles.image,
                    selectedImage === thumbnail.download_url
                      ? styles.selected
                      : {}
                  )}
                  onClick={() => setSelectedImage(thumbnail.download_url)}
                />
              );
            })}
          </div>
          <div>
            <Button
              text={"Sélectionner"}
              disabled={!selectedImage}
              onClick={() => {
                onImageSelect(selectedImage!);
                closeDrawer();
              }}
            />
          </div>
        </div>
      )}
    </SwipeableTemporaryDrawer>
  );
};

export default Gallery;
