import { axiosRequest } from "../api/axiosRequest";
import { GithubGalleryResponse } from "./type";

export async function getImageGallery() {
  const res = await axiosRequest<GithubGalleryResponse[]>({
    method: "get",
    url: "product/images",
  });

  if (res.status >= 300) {
    return [];
  }
  return res.data;
}
