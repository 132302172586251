import { FormikProps } from "formik";
import React from "react";
import Button from "../../../../component/common/button/Button";
import Switch from "../../../../component/common/switch/Switch";
import { ReactComponent as MailBox } from "../../../../svg/coloredMailBox.svg";
import { UserDeliveryInformations } from "../../pages/types";
import styles from "./index.module.css";

interface Props {
  formik: FormikProps<UserDeliveryInformations>;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  flatContext?: boolean;
}
const ConformMailBox = ({ formik, toggleDrawer, flatContext }: Props) => {
  return (
    <div
      className={
        flatContext
          ? styles.conformMailBoxFlatContextContainer
          : formik.values.isMailBoxConform
          ? styles.conformMailBoxMainContainer
          : styles.centerContainer
      }
    >
      <div className={styles.helperTextAndSwitchContainer}>
        <div className={styles.helperTextContainer}>
          <MailBox className={styles.mailBox} />
          <div className={styles.helperTextMailBox}>
            Votre boîte aux lettres est-elle conforme ?
          </div>
        </div>

        <div className={styles.conformMailBoxContainer}>
          <Switch
            isOn={!formik.values.isMailBoxConform}
            handleToggle={() => {
              formik.setFieldValue(
                "isMailBoxConform",
                !formik.values.isMailBoxConform
              );
            }}
            labels={{ left: "Oui", right: "Non" }}
          />
        </div>

        <div className={styles.smallButtonContainer}>
          <Button
            outlined={true}
            text={"Exemple"}
            onClick={toggleDrawer(true)}
            textClassName={styles.smallButtonText}
          />
        </div>
      </div>
    </div>
  );
};

export default ConformMailBox;
