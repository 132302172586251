import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import CustomTextField from "../../../../component/common/customTextField/CustomTextField";
import { ReactComponent as Appartement } from "../../../../svg/appartement.svg";
import { ReactComponent as PassCode } from "../../../../svg/passCode.svg";
import { FlatAccess, UserDeliveryInformations } from "../../pages/types";
import styles from "./index.module.css";

interface Props {
  formik: FormikProps<UserDeliveryInformations>;
}
const FlatAccessForm = ({ formik }: Props) => {
  return (
    <div className={styles.conformMailBoxMainContainer}>
      <div className={styles.ptn}>
        <div className={styles.helperTextContainer}>
          <Appartement style={{ width: "20%" }} />
          <div className={styles.helperTextMailBox}>
            Pouvons-nous rentrer dans votre immeuble sans pass ?
          </div>
        </div>
        <div className={styles.center}>
          <RadioGroup
            value={formik.values.flatDeliveryInformation.flatAccess.toString()}
            onChange={formik.handleChange}
            name="flatDeliveryInformation.flatAccess"
            id="flatDeliveryInformation.flatAccess"
          >
            {Object.values(FlatAccess).map((value) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio className={styles.radioButton} />}
                label={value}
                className={styles.radioText}
                checked={
                  formik.values.flatDeliveryInformation.flatAccess === value
                }
              />
            ))}
          </RadioGroup>
        </div>
      </div>
      {formik.values.flatDeliveryInformation?.flatAccess === FlatAccess.CODE ? (
        <div className={styles.solutionBottomContainer}>
          <div className={styles.solutionParagrah}>
            Veuillez nous indiquer votre code :
          </div>

          <CustomTextField
            fullWidth
            id="flatDeliveryInformation.accessCode"
            name="flatDeliveryInformation.accessCode"
            value={formik.values.flatDeliveryInformation.accessCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
            type="email"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PassCode />
                </InputAdornment>
              ),
              placeholder: "Veuillez nous indiquer votre code",
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FlatAccessForm;
