import classNames from "classnames";
import { getDay } from "date-fns";
import React from "react";
import styles from "./index.module.css";

interface Props {
  selectedDay: number;
  day: number;
  setDay: (day: number) => void;
  label: string;
  hasHabit: boolean;
}

export const WeekDay = ({
  day,
  setDay,
  selectedDay,
  label,
  hasHabit,
}: Props) => {
  const currentDay = getDay(new Date());

  return (
    <div onClick={() => setDay(day)} className={styles.dayContainer}>
      <p
        className={classNames(
          styles.day,
          styles.dayFont,
          currentDay === day ? styles.currentDay : {},
          selectedDay === day ? styles.selectedDay : {}
        )}
      >
        {label}
      </p>
      {hasHabit ? (
        <div className={classNames(styles.smallDot, styles.orange)} />
      ) : (
        <div className={styles.noDot} />
      )}
    </div>
  );
};
