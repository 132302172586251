import { addDays } from "date-fns";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Category } from "../../../business/bakery/components/categoryCard/categoryCard";
import type { RootState } from "../../store";
import { CartDispatchAction } from "./type";

export interface CartContent {
  id: string;
  name: string;
  price: number;
  category: Category;
}

// Define a type for the slice state
interface CartState {
  totalAmount: number;
  content: CartContent[];
  selectedDay: number;
}

// Define the initial state using that type
export const initialState: CartState = {
  totalAmount: 0,
  content: [],
  selectedDay: addDays(new Date(), 1).getTime(),
};

export const cartSlice = createSlice({
  name: "cart",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addToCart: (state, action: PayloadAction<CartDispatchAction>) => {
      const { id, price, name, category } = action.payload;
      state.totalAmount += price;

      const elementToAppend = {
        id,
        price,
        name,
        category,
      };
      if (!state.content) {
        state.content = [elementToAppend];
      } else {
        state.content = [...state.content, elementToAppend];
      }
    },
    removeFromCart: (state, action: PayloadAction<CartDispatchAction>) => {
      const { price, name } = action.payload;
      state.totalAmount -= price;

      const index = state.content.findIndex((elt) => elt.name === name);

      state.content.splice(index, 1);
    },
    deleteCart: (state) => {
      state.content = [];
      state.totalAmount = 0;
    },
    setSelectedDay: (state, action: PayloadAction<{ day: number }>) => {
      state.selectedDay = action.payload.day;
    },
  },
});

export const { addToCart, removeFromCart, deleteCart, setSelectedDay } =
  cartSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCartTotalAmount = (state: RootState) =>
  state.cart.totalAmount;

export const selectCartContent = (state: RootState) => state.cart.content;

export default cartSlice.reducer;
