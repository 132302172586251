import React, { useRef, useEffect, ReactNode } from "react";

const MapComponent: React.FC<{
  center: google.maps.LatLngLiteral;
  zoom: number;
  className?: string;
  children?: ReactNode;
}> = ({
  center,
  zoom,
  className,
  children,
}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
  className?: string;
  children?: ReactNode;
}) => {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [map, setMap] = React.useState<google.maps.Map>();

  useEffect(() => {
    setMap(
      new window.google.maps.Map(ref.current, {
        center,
        zoom,
        clickableIcons: false,
        disableDefaultUI: true,
        fullscreenControl: false,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map) {
      map.panTo(center);
    }
  }, [center, map]);
  return (
    <>
      <div
        ref={ref}
        id="map"
        className={className}
        onClick={(e) => e.preventDefault()}
      />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default MapComponent;
