import React from "react";
import { useNavigate } from "react-router-dom";
import WalletContainer from "../../../../component/common/wallet/WalletContainer";
import { Role } from "../../../../store/reducers/auth/type";
import { ReactComponent as PointLeft } from "../../../../svg/pointLeftArrow.svg";
import WeekDayStrip from "../../../calendar/components/weekStrip/WeekDayStrip";
import styles from "./index.module.css";

export const HabitTopBar = ({
  userRole,
  selectedDay,
  setDate,
}: {
  userRole: Role;
  selectedDay: number;
  setDate: (day: number) => void;
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.habitTopBar}>
      <div className={styles.walletContainer}>
        <PointLeft onClick={() => navigate(-1)} style={{ marginBottom: 5 }} />
        {userRole === Role.USER ? <WalletContainer alternatif /> : null}
      </div>
      <WeekDayStrip selectedDay={selectedDay} setDate={setDate} />
    </div>
  );
};
