import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../../../component/common/button/Button";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  activateLoading,
  deactivateLoading,
} from "../../../../store/reducers/appState";
import { axiosRequest } from "../../../../technical/api/axiosRequest";

const days = [
  {
    day: 1,
    label: "L",
  },
  {
    day: 2,
    label: "M",
  },
  {
    day: 3,
    label: "M",
  },
  {
    day: 4,
    label: "J",
  },
  {
    day: 5,
    label: "V",
  },
  {
    day: 6,
    label: "S",
  },
  {
    day: 0,
    label: "D",
  },
];

const BakeryClosingDays = () => {
  const bakeryId = useAppSelector((state) => state.auth.user.bakeryId);
  const [closingDays, setClosingDays] = useState<number[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [notSaved, setNotSaved] = useState<boolean>(false);
  const [statusText, setStatusText] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getClosingDays = async () => {
      dispatch(activateLoading());
      const { status, data } = await axiosRequest<number[]>({
        url: `bakery/closingDays/${bakeryId}`,
        method: "get",
      });

      dispatch(deactivateLoading());
      if (status === 200) {
        setClosingDays(data);
      }
    };
    getClosingDays();
  }, [bakeryId]);

  const handleSaveClosingDays = async () => {
    dispatch(activateLoading());
    const call = await axiosRequest({
      url: `bakery/closingDays`,
      method: "post",
      data: { bakeryId, closingDays },
    });

    dispatch(deactivateLoading());
    if (call.status > 300) {
      setError(true);
      setStatusText(false);
      setNotSaved(false);
    } else {
      setStatusText(true);
      setError(false);
      setNotSaved(false);
    }
  };

  return (
    <div style={{ margin: "0.75rem auto" }}>
      <b>Mes jours de fermeture</b>
      <div
        style={{
          margin: "0.75rem auto",
          paddingInline: "50px",
          boxSizing: "border-box",
          height: "10%",
        }}
      >
        {days.map(({ day, label }) => (
          <FormControlLabel
            labelPlacement="top"
            label={label}
            value={day}
            control={
              <Checkbox
                sx={{
                  "&.Mui-checked": {
                    color: "#ea974a",
                  },
                }}
                checked={closingDays.includes(day)}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (closingDays.includes(value)) {
                    setClosingDays(closingDays.filter((elt) => elt !== value));
                  } else {
                    setClosingDays([...closingDays, value]);
                  }
                  setNotSaved(true);
                  setStatusText(false);
                }}
              />
            }
          />
        ))}
      </div>
      {error ? (
        <div style={{ color: "red", marginBottom: "0.75rem" }}>
          Une erreur est survenue
        </div>
      ) : null}
      {notSaved ? (
        <div style={{ color: "orange", marginBottom: "0.75rem" }}>
          Modification non enregistrée
        </div>
      ) : null}
      {statusText ? (
        <div style={{ color: "green", marginBottom: "0.75rem" }}>
          Modification enregistrée
        </div>
      ) : null}
      <div style={{ width: "50%", margin: "auto" }}>
        <Button text={"Enregistrer"} onClick={handleSaveClosingDays} />
      </div>
    </div>
  );
};

export default BakeryClosingDays;
