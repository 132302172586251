export enum Errors {
  EMAIL_TAKEN = "EMAIL_ALREADY_TAKEN",
  PHONE_NUMBER_TAKEN = "PHONE_NUMBER_TAKEN",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  BAKERY_NOT_FOUND = "BAKERY_NOT_FOUND",
  BAKERY_NOT_OPEN = "BAKERY_NOT_OPEN",
  INEXISTANT_PRODUCT = "INEXISTANT_PRODUCT",
  WRONG_ORDER = "WRONG_ORDER",
  INCORRECT_DATE = "INCORRECT_DATE",
  NOT_ENOUGH_FUNDS = "NOT_ENOUGH_FUNDS",
  ORDER_MINIMUM_NOT_REACHED = "ORDER_MINIMUM_NOT_REACHED",
}
