import React from "react";
import styles from "./index.module.css";
import { ReactComponent as Success } from "../../svg/success.svg";
import Button from "../../component/common/button/Button";
import { useNavigate } from "react-router-dom";
import { store } from "../../store/store";
import { logout } from "../../store/reducers/auth";

const DeleteSuccess = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/landing");
    store.dispatch(logout());
  };
  return (
    <div className={styles.container}>
      <div className={styles.mainSection}>
        <Success />
      </div>
      <div className={styles.textSection}>
        <div className={styles.deleteSuccess}>
          Votre compte a été supprimé avec succès
        </div>
      </div>
      <div className={styles.buttonSection}>
        <Button text={"Continuer"} onClick={handleClick} />
      </div>
    </div>
  );
};

export default DeleteSuccess;
