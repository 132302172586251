export function addZero(nb: string) {
  if (nb.indexOf(".") === -1) {
    return nb + ".00";
  } else {
    if (nb.indexOf(".") === nb.length - 2) {
      return nb + "0";
    }
  }
  return nb;
}
