import { FormikProps } from "formik";
import React from "react";
import Button from "../../../../component/common/button/Button";
import PageLayout from "../../components/pageLayout/PageLayout";
import { UserDeliveryInformations } from "../types";
import styles from "./index.module.css";
import SwipeableTemporaryDrawer from "../../../../component/common/SwipeableTemporaryDrawer/SwipeableTemporaryDrawer";
import { useSwipeableTemporaryDrawer } from "../../../../component/common/SwipeableTemporaryDrawer/hook";
import ConformBox from "../conformBox/ConformBox";
import ConformMailBox from "../../components/conformMailbox/ConformMailBox";
import IsMailBoxConform from "../../components/isMailBoxConform/IsMailBoxConform";

interface Props {
  formik: FormikProps<UserDeliveryInformations>;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const DeliveryDetailsInformations = ({
  formik,
  handleNextStep,
  handlePreviousStep,
}: Props) => {
  const { drawerOpen, toggleDrawer } = useSwipeableTemporaryDrawer();

  const disableButton = () => {
    if (!formik.values.isMailBoxConform && formik.values.hasDeliverySolution) {
      return !(formik.values.deliverySolution.trim().length > 0);
    } else {
      return false;
    }
  };

  return (
    <PageLayout
      title={"Mes informations de livraison"}
      footer={
        <>
          <>
            <Button
              text={"Valider"}
              onClick={handleNextStep}
              disabled={disableButton()}
              divClassName={styles.marginBottom}
            />
            <Button text={"Retour"} onClick={handlePreviousStep} outlined />
          </>

          <SwipeableTemporaryDrawer
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
          >
            <ConformBox toggleDrawer={toggleDrawer} />
          </SwipeableTemporaryDrawer>
        </>
      }
    >
      <div className={styles.mainContainer}>
        <ConformMailBox
          formik={formik}
          toggleDrawer={toggleDrawer}
          flatContext={true}
        />
        <IsMailBoxConform formik={formik} />
      </div>
    </PageLayout>
  );
};

export default DeliveryDetailsInformations;
