import React, { useEffect, useState } from "react";
import CustomTextField from "../../component/common/customTextField/CustomTextField";
import styles from "./index.module.css";
import { ReactComponent as PointLeft } from "../../svg/pointLeftArrow.svg";
import { useNavigate } from "react-router-dom";
import { axiosRequest } from "../../technical/api/axiosRequest";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  activateLoading,
  deactivateLoading,
} from "../../store/reducers/appState";
import { BakeryInformation as BakeryInfo } from "../bakeryInfo/pages/BakeryInfos";
import Button from "../../component/common/button/Button";

interface BakeryInfoWithCode extends BakeryInfo {
  deliveryCode: string;
}

const BakeryInformation = () => {
  const bakeryId = useAppSelector((state) => state.auth.user.bakeryId);
  const dispatch = useAppDispatch();
  const [bakeryInfo, setBakeryInfo] = useState<BakeryInfoWithCode>();
  const [description, setDescription] = useState<string>();

  useEffect(() => {
    async function fetchData() {
      dispatch(activateLoading());
      const { data, status } = await axiosRequest<BakeryInfoWithCode>({
        method: "get",
        url: `bakery/information/${bakeryId}`,
      });

      if (status < 300) {
        setBakeryInfo({ ...data });
        setDescription(data.description);
      }
      dispatch(deactivateLoading());
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveClick = async () => {
    dispatch(activateLoading());
    const { status } = await axiosRequest<{ success: boolean }>({
      method: "post",
      url: `bakery/description`,
      data: {
        description,
        bakeryId,
      },
    });

    dispatch(deactivateLoading());
    if (status < 300) {
      return true;
    }
    return null;
  };

  const navigate = useNavigate();
  if (!bakeryInfo) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <PointLeft onClick={() => navigate(-1)} />
        <div className={styles.title}>Ma boulangerie</div>
        <div className={styles.textFieldWrapper}>
          <CustomTextField
            fullWidth
            id="bakeryName"
            name="bakeryName"
            value={bakeryInfo.name}
            disabled
          />
        </div>
        <div className={styles.textFieldWrapper}>
          <CustomTextField
            fullWidth
            id="description"
            name="description"
            label="Description"
            value={description}
            multiline
            rows={6}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className={styles.textFieldWrapper}>
          <CustomTextField
            fullWidth
            id="deliveryCode"
            name="deliveryCode"
            label="Code de livraison"
            value={bakeryInfo.deliveryCode}
            disabled
          />
        </div>
        <div style={{ width: "50%", margin: "0.75rem auto" }}>
          <Button text={"Enregistrer"} onClick={handleSaveClick} />
        </div>
      </div>
    </div>
  );
};

export default BakeryInformation;
