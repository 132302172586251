import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

export const initialState = {
  loading: false,
  day: new Date().getTime() / 1000,
};

export const appState = createSlice({
  name: "habitCart",
  initialState,
  reducers: {
    activateLoading: (state) => {
      state.loading = true;
    },
    deactivateLoading: (state) => {
      state.loading = false;
    },
  },
});

export const { activateLoading, deactivateLoading } = appState.actions;

export const appStateLoading = (state: RootState) => state.appState.loading;

export const appStateActiveDay = (state: RootState) => state.appState.day;

export default appState.reducer;
