import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import BottomSection from "../../../component/common/bottomSection/bottomSection";
import PageColor from "../../../component/common/pageColor/PageColor";
import { useAppSelector } from "../../../store/hooks";
import { Role } from "../../../store/reducers/auth/type";
import styles from "./index.module.css";
import { Tabs as PageTab } from "../../../store/reducers/navBar/type";
import { makeStyles } from "@mui/styles";
import ClientOrderTable from "../components/clientOrderTable/ClientOrderTable";
import TabPanel from "../../../component/common/tabPanel";
import DailyCa from "../components/dailyCa/DailyCa";
import { addMonths, format, subMonths } from "date-fns";
import { fr } from "date-fns/locale";
import MonthSelector from "../components/monthSelector/MonthSelector";
import { ReactComponent as PointLeft } from "../../../svg/pointLeftArrow.svg";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTab-root": {
      textTransform: "none",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "130%",

      color: "#000000",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f9b333",
      fontWeight: "500",
    },
  },
});

const SuiviCa = ({ adminBakeryId }: { adminBakeryId?: string }) => {
  const classes = useStyles();
  const userRole = useAppSelector((state) => state.auth.user.role);
  const userBakeryId = useAppSelector((state) => state.auth.user.bakeryId);
  const navigate = useNavigate();

  const bakeryId = adminBakeryId ?? userBakeryId;

  const [value, setValue] = useState(0);
  const [date, setDate] = useState<Date>(
    new Date(format(Date.now(), "yyyy-MM-01"))
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (![Role.BAKER, Role.ADMIN].includes(userRole)) {
    return <Navigate to="/" />;
  }

  return (
    <PageColor>
      <div className={styles.topPartBaker}>
        {userRole === Role.BAKER ? (
          <PointLeft
            onClick={() => navigate("/suiviCa")}
            style={{ margin: "2% 0 2% 2%" }}
          />
        ) : null}
        Suivi chiffre d’affaire du mois
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#ea974a",
              color: "red",
            },
          }}
          className={classes.tabs}
        >
          <Tab label="Par Clients" />
          <Tab label="Par Jours" className={styles.test} />
        </Tabs>
      </div>
      <div className={styles.container}>
        <MonthSelector
          month={format(date, "MMMM yyyy", { locale: fr })}
          dateMinusOne={() => setDate(subMonths(date, 1))}
          datePlusOne={() => setDate(addMonths(date, 1))}
        />
        <TabPanel value={value} index={0}>
          <ClientOrderTable
            bakeryId={bakeryId}
            collapsibleClassName={styles.collapsible}
            date={date}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DailyCa bakeryId={bakeryId} date={date} />
        </TabPanel>
      </div>
      {userRole === Role.BAKER ? (
        <BottomSection tab={PageTab.SUIVI_CA} />
      ) : null}
    </PageColor>
  );
};

export default SuiviCa;
