import {
  ClickAwayListener,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import classnames from "classnames";
import React from "react";
import Button from "../../../../component/common/button/Button";
import { store } from "../../../../store/store";
import { addZero } from "../../../../technical/string/addZero";
import { SelectObject } from "../../page/types";
import AmountRectangle from "../amountRectangle/AmountRectangle";
import styles from "./index.module.css";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
interface Props {
  setStripePriceId: (id: string) => void;
  setAmount: (amount: number) => void;
  handleClick: () => void;
  setError: (val: string | undefined) => void;
  amount?: number;
  error?: string;
}

const SelectAmount = ({
  amount,
  setAmount,
  handleClick,
  setStripePriceId,
  error,
  setError,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const state = store.getState();

  const amounts: SelectObject[] =
    process.env.REACT_APP_ENV === "production"
      ? // prod products
        [
          { amount: 2500, stripePriceId: process.env.REACT_APP_LIVE_25_PRICE! },
          { amount: 3000, stripePriceId: process.env.REACT_APP_LIVE_30_PRICE! },
          { amount: 4000, stripePriceId: process.env.REACT_APP_LIVE_40_PRICE! },
          { amount: 5000, stripePriceId: process.env.REACT_APP_LIVE_50_PRICE! },
        ]
      : // dev products
        [
          { amount: 2500, stripePriceId: process.env.REACT_APP_TEST_25_PRICE! },
          { amount: 3000, stripePriceId: process.env.REACT_APP_TEST_30_PRICE! },
          { amount: 4000, stripePriceId: process.env.REACT_APP_TEST_40_PRICE! },
          { amount: 5000, stripePriceId: process.env.REACT_APP_TEST_50_PRICE! },
        ];

  const handleSelectClick = (am: number, id: string) => {
    setError(undefined);
    setAmount(am);
    setStripePriceId(id);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Recharger la cagnotte</div>
      <div className={styles.walletContainer}>
        <p className={styles.text}>Montant disponible :</p>
        <div
          className={classnames(styles.walletAmountContainer, styles.amount)}
        >
          {addZero((state.auth.user.walletAmount / 100).toString()) + "€"}
        </div>
      </div>
      <div>
        <p className={styles.text}>
          De combien souhaitez-vous recharger votre cagnotte?
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              title={
                <div>
                  Pour information, afin de minimiser les frais de transaction
                  nous imposons un minimum de 25€ par recharge.
                  <br />
                  De plus , merci de tenir en compte que nous n’effectuons pas
                  de remboursement, veuillez prendre vos précautions.
                </div>
              }
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <IconButton onClick={handleTooltipOpen}>
                <InfoIcon style={{ width: "15px" }} />
              </IconButton>
            </Tooltip>
          </ClickAwayListener>
        </p>
        <div className={styles.amountsContainer}>
          {amounts.map(({ amount: selectedAmount, stripePriceId }) => (
            <div className={styles.amountItem}>
              <AmountRectangle
                selected={amount === selectedAmount}
                amount={selectedAmount}
                onClick={() => handleSelectClick(selectedAmount, stripePriceId)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {error ? (
          <Typography color={"error"} className={styles.errorSection}>
            {error}
          </Typography>
        ) : (
          <div className={styles.errorSection}></div>
        )}
        <div className={styles.buttonSection}>
          <Button text="Valider" onClick={handleClick} />
          <Button text="Retour" onClick={() => navigate(-1)} outlined />
        </div>
      </div>
    </div>
  );
};

export default SelectAmount;
