import * as yup from "yup";
import { Category } from "../../bakery/components/categoryCard/categoryCard";

const productValidator = yup.object().shape({
  name: yup.string().max(30).required("Ce champ est requis"),
  description: yup.string().max(75).required("Ce champ est requis"),
  price: yup
    .number()
    .min(0.01, "Le prix doit être supérieur à 0.01€")
    .max(100, "Le prix doit être inférieur à 100€")
    .required(
      "Ce champs est obligatoire et doit être compris entre 0.01€ et 100€"
    ),
  category: yup
    .string()
    .test("isCategory", "Catégorie non existante", (value) => {
      if (!value) {
        return false;
      }
      return Object.values(Category).some((val) => val === value);
    }),
});

export default productValidator;
