import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { activateLoading, deactivateLoading } from "../appState";
import { UserDetails, UserRegistration } from "../auth/type";

export const registerUser = createAsyncThunk<
  AxiosResponse<UserDetails, any>,
  UserRegistration,
  { rejectValue: string }
>(
  // action type string
  "auth/register",
  // callback function
  async (
    {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      role,
    }: UserRegistration,
    { rejectWithValue, ...thunkAPI }
  ) => {
    try {
      thunkAPI.dispatch(activateLoading());
      const config = {
        "Content-Type": "application/json",
      };
      const response = await axiosRequest<UserDetails>({
        url: "auth/register",
        data: {
          firstName,
          lastName,
          email,
          phoneNumber,
          password,
          role,
        },
        method: "post",
        config,
      });

      thunkAPI.dispatch(deactivateLoading());
      return response;
    } catch (error: unknown) {
      thunkAPI.dispatch(deactivateLoading());
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data.message);
      } else {
        return rejectWithValue("Une erreur est survenue");
      }
    }
  }
);
