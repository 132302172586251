import { config, SpringRef } from "@react-spring/web";

interface Props {
  api: SpringRef<{
    x: number;
    y: number;
    scale: number;
    rotateZ: number;
  }>;
  expandedY: number;
  collapsedY: number;
}

export const useZeroDrawer = ({ api, expandedY, collapsedY }: Props) => {
  const open = (velocity = 0) => {
    api.start({
      y: expandedY,
      immediate: false,
      config: { ...config.stiff, velocity },
    });
  };

  const close = (velocity = 0) => {
    api.start({
      y: collapsedY,
      immediate: false,
      config: { ...config.stiff, velocity },
    });
  };

  return { open, close };
};
