import React from "react";
import styles from "./index.module.css";
import { ReactComponent as Minus } from "../../../../svg/minus.svg";
import { ReactComponent as Plus } from "../../../../svg/plus.svg";
import classNames from "classnames";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { addToCart, removeFromCart } from "../../../../store/reducers/cart";
import { Category } from "../../../bakery/components/categoryCard/categoryCard";
import ImageSquare from "../../../../component/common/imageSquare/ImageSquare";

interface Props {
  id: string;
  name: string;
  price: number;
  category: Category;
  imageUrl: string;
}

const SelectProductQuantity = ({
  id,
  name,
  price,
  category,
  imageUrl,
}: Props) => {
  const selector = useAppSelector((state) => state.cart.content);

  const dispatch = useAppDispatch();
  let quantity = 0;
  if (selector) {
    quantity = selector.filter((elt) => elt.id === id).length;
  }

  return (
    <ImageSquare imageUrl={imageUrl}>
      {(() => {
        if (!quantity || quantity === 0) {
          return (
            <div className={styles.badge}>
              <div
                onClick={() => {
                  dispatch(addToCart({ id, name, price, category }));
                }}
                className={styles.iconContainer}
              >
                <Plus className={styles.icons} />
              </div>
            </div>
          );
        } else {
          return (
            <div className={classNames(styles.badge, styles.longBadge)}>
              <div
                onClick={() =>
                  dispatch(removeFromCart({ id, name, price, category }))
                }
                className={styles.iconContainer}
              >
                <Minus className={styles.icons} />
              </div>
              {quantity}
              <div
                onClick={() =>
                  dispatch(addToCart({ id, name, price, category }))
                }
                className={styles.iconContainer}
              >
                <Plus className={styles.icons} />
              </div>
            </div>
          );
        }
      })()}
    </ImageSquare>
  );
};

export default SelectProductQuantity;
