import { FormikProps } from "formik";
import {
  FlatAccess,
  MailBoxLocation,
  Steps,
  UserDeliveryInformations,
} from "./types";

interface Props {
  formik: FormikProps<UserDeliveryInformations>;
  setSteps: (step: Steps) => void;
  setColor: (color: string) => void;
  handleSubmit: () => void;
}

export function handleSteps({
  formik,
  setSteps,
  setColor,
  handleSubmit,
}: Props) {
  const FLAT_ACCESS_WITH_CODE =
    formik.values.flatDeliveryInformation.flatAccess === FlatAccess.CODE &&
    formik.values.flatDeliveryInformation.accessCode.trim().length > 0;
  const FLAT_MAILBOX_OUTSIDE =
    formik.values.flatDeliveryInformation.mailBoxLocation ===
    MailBoxLocation.OUTSIDE;

  const goToMailBoxDetail = () => {
    setSteps(Steps.DELIVERY_DETAILS);
    return;
  };

  const handleBakeryConfirmationValidationStep = async () => {
    setSteps(Steps.IS_HOUSE);
    return;
  };

  const handleReloadAddressStep = async () => {
    window.location.reload();
    return;
  };

  const isValid = () => {
    if (formik.values.isHouse) {
      if (!formik.values.isMailBoxConform) {
        return (
          formik.values.hasDeliverySolution &&
          formik.values.deliverySolution.trim().length > 0
        );
      } else {
        return true;
      }
    }
  };

  const handleHouseNextStep = () => {
    if (formik.values.isHouse) {
      if (isValid()) {
        return handleSubmit();
      } else {
        if (formik.values.isMailBoxConform === false) {
          if (!formik.values.hasDeliverySolution) {
            setSteps(Steps.NOT_POSSIBLE);
          } else {
            if (
              formik.values.deliverySolution &&
              formik.values.deliverySolution.trim().length !== 0
            ) {
              setColor("black");

              setSteps(Steps.DELIVERY_DETAILS);
            } else {
              setColor("error");
            }
          }
        } else {
          setSteps(Steps.DELIVERY_DETAILS);
        }
      }
    }
    return false;
  };

  const handleFlatNextStep = () => {
    if (!formik.values.isHouse) {
      if (FLAT_MAILBOX_OUTSIDE) {
        return goToMailBoxDetail();
      } else {
        if (
          formik.values.flatDeliveryInformation.flatAccess === FlatAccess.OPEN
        ) {
          return goToMailBoxDetail();
        }
        if (FLAT_ACCESS_WITH_CODE) {
          return goToMailBoxDetail();
        }
      }
    }
    setSteps(Steps.NOT_POSSIBLE);
  };

  const handleFlatDeliveryDetailsStep = () => {
    if (formik.values.isMailBoxConform === false) {
      if (!formik.values.hasDeliverySolution) {
        setSteps(Steps.NOT_POSSIBLE);
      } else {
        if (formik.values.deliverySolution) {
          return handleSubmit();
        } else {
          return handleSubmit();
        }
      }
    } else {
      return handleSubmit();
    }
  };

  const handleFailure = () => {
    formik.resetForm();
    setSteps(Steps.ADDRESS);
  };

  return {
    handleFailure,
    handleBakeryConfirmationValidationStep,
    handleFlatDeliveryDetailsStep,
    handleFlatNextStep,
    handleHouseNextStep,
    handleReloadAddressStep,
  };
}
