import { Typography } from "@mui/material";
import React from "react";
import Button from "../../../../component/common/button/Button";
import HorizontalDivider from "../../../../component/common/horizontalDivider/horizontalDivider";
import { addZero } from "../../../../technical/string/addZero";
import styles from "./index.module.css";

interface Props {
  handleClick: () => void;
  amount: number;
  error?: string;
}

const Checkout = ({ amount, handleClick, error }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Résumé de la commande</div>
      <div className={styles.checkoutContainer}>
        <HorizontalDivider />
        <div className={styles.itemLine}>
          <p className={styles.text}>Recharge Cagnotte</p>
          <p className={styles.text}>
            {addZero((amount / 100).toString()) + "€"}
          </p>
        </div>
        <HorizontalDivider />
        <div className={styles.itemLine}>
          <p className={styles.boldText}>TOTAL</p>
          <p className={styles.boldText}>
            {addZero((amount / 100).toString()) + "€"}
          </p>
        </div>
        <HorizontalDivider />
      </div>
      <div className={styles.buttonContainer}>
        {error ? (
          <Typography color={"error"} className={styles.errorSection}>
            Une erreur est survenue
          </Typography>
        ) : (
          <div className={styles.errorSection}></div>
        )}
        <Button text="Procéder au Paiement" onClick={handleClick} />
      </div>
    </div>
  );
};

export default Checkout;
