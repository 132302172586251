import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { registerUser } from "../registration/actions";

interface RegistrationState {
  pending: boolean;
  success: boolean | null;
  message: string | undefined;
}
// Define the initial state using that type
export const initialState: RegistrationState = {
  pending: false,
  success: null,
  message: undefined,
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state, _action) => {
      state.pending = true;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.pending = false;
      state.success = action.payload.status === 201;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.pending = false;
      state.success = false;
      state.message = action.payload;
    });
  },
});

export default registrationSlice.reducer;

export const selectRegistrationPending = (state: RootState) =>
  state.registration.pending;
export const selectRegistrationSuccess = (state: RootState) =>
  state.registration.success;
// export const selectRegistrationMessage = (state: RootState) =>
//   state.registration.message;
