import React from "react";
import { useAppSelector } from "../../../../store/hooks";
import styles from "./index.module.css";
import { WeekDay } from "./WeekDay";

interface Props {
  selectedDay: number;
  setDate: (day: number) => void;
}

const days = ["D", "L", "M", "M", "J", "V", "S"];

const WeekStrip = ({ selectedDay, setDate }: Props) => {
  const habits = useAppSelector((state) => {
    return state.habitCart.cart;
  });
  return (
    <div className={styles.weekDayStrip}>
      {days.map((day, index) => {
        const hasHabit = habits[index].content.length !== 0;
        return (
          <WeekDay
            key={day}
            selectedDay={selectedDay}
            day={index}
            setDay={setDate}
            label={day}
            hasHabit={hasHabit}
          />
        );
      })}
    </div>
  );
};

export default WeekStrip;
