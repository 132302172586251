import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { activateLoading, deactivateLoading } from "../appState";
import {
  ConfirmSubscriptionResponse,
  UserDetails,
  UserLogin,
} from "../auth/type";

export const loginUser = createAsyncThunk<
  AxiosResponse<{ token: string; user: UserDetails }, any>,
  UserLogin,
  { rejectValue: string }
>(
  // action type string
  "auth/login",
  // callback function
  async ({ email, password }: UserLogin, { rejectWithValue }) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };
      const response = await axiosRequest<{ token: string; user: UserDetails }>(
        {
          url: "auth/login",
          method: "post",
          data: {
            email,
            password,
          },
          config,
        }
      );

      return response;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data.message);
      } else {
        return rejectWithValue("Une erreur est survenue");
      }
    }
  }
);

export const walletRefresh = createAsyncThunk(
  // action type string
  "auth/walletRefresh",
  // callback function
  async () => {
    const { data, status } = await axiosRequest<number>({
      method: "get",
      url: "user/walletAmount",
    });

    if (status === 200) {
      return data;
    }
    return null;
  }
);

export const replaceBakeryId = createAsyncThunk(
  // action type string
  "auth/replaceBakeryId",
  // callback function
  async (arg, { getState }) => {
    const state: any = getState();
    const bakeryId = state.auth.user.bakeryId;
    const { data, status } = await axiosRequest<{ bakeryId: string | null }>({
      method: "get",
      url: "user/associatedBakery",
    });
    if (status === 200) {
      return bakeryId !== data.bakeryId ? data.bakeryId : null;
    }
    return null;
  }
);

export const subscriptionAction = createAsyncThunk(
  // action type string
  "auth/subscriptionAction",
  // callback function
  async ({ subscriptionId }: { subscriptionId: string }) => {
    const response = await axiosRequest<ConfirmSubscriptionResponse>({
      method: "post",
      url: "subscription/confirm",
      data: {
        subscriptionId,
      },
    });
    if (response.status < 300) {
      return true;
    }
    return false;
  }
);
