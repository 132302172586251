import React from "react";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { logout } from "../../store/reducers/auth";
import isJwtValid from "../../technical/jwt/isJwtValid";
import IsSubscribed from "./IsSubscribed";

const PrivateRoutes = () => {
  const authToken = useAppSelector((state) => state.auth.token);
  const dispatch = useAppDispatch();

  if (!isJwtValid(authToken)) {
    dispatch(logout());
  }
  if (authToken) {
    return <IsSubscribed />;
  }

  return <Navigate to="/landing" />;
};

export default PrivateRoutes;
