import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageColor from "../../../component/common/pageColor/PageColor";
import styles from "./index.module.css";
import { ReactComponent as PointLeft } from "../../../svg/pointLeftArrow.svg";
import { ReactComponent as Position } from "../../../svg/position.svg";
import { ReactComponent as Phone } from "../../../svg/secondPhone.svg";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { AddressInformations } from "../../firstSteps/pages/types";
import { useAppSelector } from "../../../store/hooks";
import classNames from "classnames";
import { CircularProgress } from "@mui/material";

export interface BakeryInformation {
  name: string;
  description: string;
  address: AddressInformations;
  phone: string;
}

const BakeryInfos = () => {
  const navigate = useNavigate();
  const bakeryId = useAppSelector((state) => state.auth.user.bakeryId);
  const [bakeryInfo, setBakeryInfo] = useState<BakeryInformation | undefined>();

  useEffect(() => {
    async function getInformation() {
      const req = await axiosRequest<BakeryInformation>({
        method: "get",
        url: `bakery/information/${bakeryId}`,
      });

      if (req.status < 300) {
        setBakeryInfo(req.data);
      }
    }

    getInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!bakeryInfo) {
    return (
      <PageColor isColored={true} className={styles.container}>
        <PointLeft onClick={() => navigate(-1)} style={{ marginBottom: 5 }} />
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      </PageColor>
    );
  }
  return (
    <PageColor className={styles.container}>
      <PointLeft onClick={() => navigate(-1)} style={{ marginBottom: 5 }} />

      {/* TITLE */}
      <div
        className={classNames(
          styles.smallContainer,
          styles.padding,
          styles.center
        )}
      >
        <div className={styles.bakeryName}>{bakeryInfo.name}</div>
        <div className={styles.divider}></div>
      </div>
      {/* DESCRIPTION */}
      <div
        className={classNames(
          styles.bigContainer,
          styles.padding,
          styles.spaceAround
        )}
      >
        <div className={styles.description}>{bakeryInfo.description}</div>
        <div
          className={classNames(
            styles.specialText,
            styles.smallWidth,
            styles.alignEnd
          )}
        >
          Toute l’équipe vous souhaite un bon petit déjeuner
        </div>
      </div>
      {/* INFORMATIONS */}
      <div
        className={classNames(
          styles.smallContainer,
          styles.bottom,
          styles.padding
        )}
      >
        <div className={styles.icons}>
          <Position className={styles.marginRight} />
          {bakeryInfo.address.address}
        </div>
        <div className={styles.icons}>
          <Phone className={styles.marginRight} />
          {bakeryInfo.phone}
        </div>
      </div>
    </PageColor>
  );
};

export default BakeryInfos;
