import { CircularProgress } from "@mui/material";
import { FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { axiosRequest } from "../../../../technical/api/axiosRequest";
import AssociatedBakeryResponse from "../../components/associatedBakeryResponse/AssociatedBakeryResponse";
import { BakeryDetails, UserDeliveryInformations } from "../types";
import styles from "./index.module.css";

interface Props {
  formik: FormikProps<UserDeliveryInformations>;
  handleAffirmativeClick: () => void;
  handleReloadStep: () => void;
}
const BakeryConfirmation = ({
  formik,
  handleAffirmativeClick,
  handleReloadStep,
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [associatedBakery, setAssociatedBakery] = useState<
    BakeryDetails | undefined
  >();
  useEffect(() => {
    async function fetchBakeries() {
      try {
        const bakeries = await axiosRequest<BakeryDetails[]>({
          method: "get",
          url: "bakery",
        });
        bakeries.data.forEach((bakery) => {
          const zonePolygon = new google.maps.Polygon({
            paths: bakery.zone,
          });

          const isInArea = google.maps.geometry.poly.containsLocation(
            {
              lat: formik.values.addressInformations.latAndLng[1],
              lng: formik.values.addressInformations.latAndLng[0],
            },
            zonePolygon
          );

          if (isInArea) {
            formik.setFieldValue("bakeryId", bakery.id);
            setAssociatedBakery(bakery);
          }
        });
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }

    fetchBakeries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.addressInformations.latAndLng]);

  return (
    <>
      {loading ? (
        <div className={styles.mainContainer}>
          <CircularProgress />
        </div>
      ) : (
        <AssociatedBakeryResponse
          bakery={associatedBakery}
          handleAffirmativeClick={handleAffirmativeClick}
          handleReloadStep={handleReloadStep}
        />
      )}
    </>
  );
};

export default BakeryConfirmation;
