import React from "react";
import styles from "./index.module.css";

interface Props {
  isOn: boolean;
  handleToggle: () => void;
  labels: {
    left: string;
    right: string;
  };
}

const Switch = ({ isOn, handleToggle, labels }: Props) => {
  return (
    <label className={styles.reactSwitch}>
      <input
        checked={isOn}
        onChange={handleToggle}
        className={styles.reactSwitchCheckbox}
        type="checkbox"
      />
      <div className={styles.reactSwitchButton}>
        {isOn ? labels.right : labels.left}
      </div>
      <div className={styles.reactSwitchLabels}>
        <span>{labels.left}</span>
        <span>{labels.right}</span>
      </div>
    </label>
  );
};

export default Switch;
