import React from "react";
import Button from "../../../../component/common/button/Button";
import HorizontalDivider from "../../../../component/common/horizontalDivider/horizontalDivider";
import { BakeryDetails } from "../../pages/types";
import styles from "./index.module.css";
import { ReactComponent as Failure } from "../../../../svg/fail.svg";

interface Props {
  bakery: BakeryDetails | undefined;
  handleReloadStep: () => void;
  handleAffirmativeClick: () => void;
}

const AssociatedBakeryResponse = ({
  bakery,
  handleAffirmativeClick,
  handleReloadStep,
}: Props) => {
  if (!bakery) {
    return (
      <div className={styles.mainCont}>
        <Failure height={150} />
        <p className={styles.otherWithMargin}>
          Votre adresse n’est malheureusement pas située dans notre zone de
          livraison. Nous faisons tout notre possible pour l’élargir mais cela
          prend du temps.
        </p>
        <p className={styles.otherWithMargin}>
          Nous vous recontacterons dès que nous pourrons livrer votre adresse.
        </p>
        <div className={styles.returnButton}>
          <Button text={"Retour"} onClick={handleReloadStep} />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.mainCont}>
      <>
        <div className={styles.titleCont}>
          <div className={styles.title}>Ma boulangerie de quartier</div>
        </div>
        <HorizontalDivider />
        <div className={styles.centerCont}>
          <div>
            <p className={styles.other}>Votre boulangerie de quartier est :</p>
            <p className={styles.bakery}>{bakery.bakeryName}</p>
            <p className={styles.otherWithMargin}>
              {bakery.addressInformations.address}
            </p>
          </div>
          <div className={styles.buttonSection}>
            <Button text={"J'accepte"} onClick={handleAffirmativeClick} />
          </div>
        </div>
      </>
    </div>
  );
};

export default AssociatedBakeryResponse;
