// @ts-nocheck
import React, { ReactNode, useEffect } from "react";
import { useGesture } from "@use-gesture/react";
import { useSpring, config, animated } from "@react-spring/web";
import styles from "./index.module.css";
import { ReactComponent as Notch } from "../../svg/notch.svg";
import DateComponent from "./dateComponent/DateComponent";
import HorizontalDivider from "../common/horizontalDivider/horizontalDivider";
import { Chip } from "@mui/material";

interface Props {
  children: ReactNode;
  isHabitDrawer?: boolean;
  dateMinusOne?: () => void;
  datePlusOne?: () => void;
  date?: HabitsDays | Date;
}

type Mode = "collapsed" | "expanded";

const getY = (height: number, mode: Mode): number => {
  if (mode === "collapsed") {
    return -height * 0.01;
  }

  return -height * 0.7;
};

const Drawer: React.FC<Props> = ({
  children,
  isHabitDrawer,
  datePlusOne,
  dateMinusOne,
  date,
}: Props) => {
  const { innerHeight: height } = window;

  const collapsedY = getY(height, "collapsed");
  const expandedY = getY(height, "expanded");

  useEffect(() => {
    const handler = (e: Event) => e.preventDefault();
    document.addEventListener("gesturestart", handler);
    document.addEventListener("gesturechange", handler);
    document.addEventListener("gestureend", handler);
    return () => {
      document.removeEventListener("gesturestart", handler);
      document.removeEventListener("gesturechange", handler);
      document.removeEventListener("gestureend", handler);
    };
  }, []);

  const [style, api] = useSpring(() => ({
    x: 0,
    y: collapsedY, // valeur de départ
    scale: 1,
    rotateZ: 0,
  }));

  const ref = React.useRef(null);

  const open = ({ canceled }) => {
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    api.start({ y: expandedY, immediate: false });
  };

  const close = (velocity = 0) => {
    api.start({
      y: collapsedY,
      immediate: false,
      config: { ...config.stiff, velocity },
    });
  };

  useGesture(
    {
      onDrag: ({
        last,
        velocity: [, vy],
        direction: [, dy],
        offset: [, y],
        movement: [, my],
        tap,
      }) => {
        api.start({ y, immediate: false });
        if (last) {
          vy > 0.5 && dy > 0.5
            ? close(vy)
            : api.start({ y: expandedY, immediate: false });
        }
        // }
      },
    },
    {
      target: ref,
      drag: {
        from: () => [style.x.get(), style.y.get()],
        filterTaps: true,
        bounds: { top: expandedY },
        rubberband: true,
      },
    }
  );

  return (
    <animated.div
      className={styles.card}
      ref={ref}
      style={style}
      data-id="maxime"
    >
      <div className={styles.children}>
        <Chip
          label="Panier"
          style={{ backgroundColor: "#ea974a" }}
          onClick={open}
        />
      </div>
      <div className={styles.nothContainer}>
        <div style={{ flex: 1 }}></div>
        <Notch style={{ marginTop: 10, flex: 2 }} />
        <div style={{ flex: 1 }}></div>
      </div>
      <div className={styles.container}>
        <DateComponent
          date={date}
          dateMinusOne={dateMinusOne}
          datePlusOne={datePlusOne}
          isHabitDrawer={isHabitDrawer}
        />
      </div>
      <HorizontalDivider style={{ marginBottom: "0.75rem" }} />
      {children}
    </animated.div>
  );
};

export default Drawer;
