import { useFormik } from "formik";
import React from "react";
import { UserRegistration } from "../../../../store/reducers/auth/type";
import userRegistrationValidator from "../../pages/validator";
import { ReactComponent as Lock } from "../../../../svg/lock.svg";
import { ReactComponent as Phone } from "../../../../svg/phone.svg";
import { ReactComponent as Person } from "../../../../svg/person.svg";
import { ReactComponent as Mail } from "../../../../svg/mail.svg";
import styles from "./index.module.css";
import { Checkbox, InputAdornment, Typography } from "@mui/material";
import { findCorrespondingError } from "../../../../technical/errors/findCorrespondingError";
import Button from "../../../../component/common/button/Button";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../../../../component/common/customTextField/CustomTextField";
import { useState } from "react";

export interface UserRegistrationForm extends UserRegistration {
  passwordConfirmation: string;
}

interface Props {
  initialValues: UserRegistrationForm;
  onSubmit: (values: UserRegistration) => void;
  error?: string;
  success?: string;
  bakerForm?: boolean;
}

const RegistrationForm = ({
  initialValues,
  error,
  success,
  onSubmit,
  bakerForm,
}: Props) => {
  const navigate = useNavigate();

  const formik = useFormik<UserRegistrationForm>({
    initialValues: initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: userRegistrationValidator,
    onSubmit,
  });

  const [hasAccepted, setHasAccepted] = useState<boolean>(false);

  const isDisabled =
    Object.values(formik.values).some((elt) => elt === "") ||
    (formik.dirty && !formik.isValid) ||
    hasAccepted !== true;

  return (
    <div className={styles.registrationFormContainer}>
      <form onSubmit={formik.handleSubmit} className={styles.formContainer}>
        <div>
          <CustomTextField
            fullWidth
            id="firstName"
            name="firstName"
            label="Prénom"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
            required
            helperText={
              Boolean(formik.touched.firstName && formik.errors.firstName)
                ? formik.errors.firstName
                : null
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
              placeholder: !bakerForm ? "Entrez votre prénom" : "Prénom",
            }}
          />
        </div>
        <div>
          <CustomTextField
            fullWidth
            id="lastName"
            name="lastName"
            label="Nom"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
            required
            helperText={
              Boolean(formik.touched.lastName && formik.errors.lastName)
                ? formik.errors.lastName
                : null
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),

              placeholder: !bakerForm ? "Entrez votre nom" : "Nom",
            }}
          />
        </div>
        <div>
          <CustomTextField
            fullWidth
            id="phoneNumber"
            name="phoneNumber"
            label="Numéro de téléphone"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.phoneNumber && formik.errors.phoneNumber
            )}
            required
            helperText={
              Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)
                ? formik.errors.phoneNumber
                : null
            }
            type="tel"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              ),
              placeholder: !bakerForm
                ? "Entrez votre numéro de téléphone"
                : "Numéro de téléphone",
            }}
          />
        </div>
        <div>
          <CustomTextField
            fullWidth
            id="email"
            name="email"
            label="Mail"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.email && formik.errors.email)}
            required
            helperText={
              Boolean(formik.touched.email && formik.errors.email)
                ? formik.errors.email
                : null
            }
            type="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Mail />
                </InputAdornment>
              ),
              placeholder: !bakerForm ? "Entrez votre adresse mail" : "Mail",
            }}
          />
        </div>
        <div>
          <CustomTextField
            fullWidth
            id="password"
            name="password"
            label="Mot de passe"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.password && formik.errors.password)}
            required
            helperText={
              Boolean(formik.touched.password && formik.errors.password)
                ? formik.errors.password
                : null
            }
            type="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              placeholder: !bakerForm
                ? "Entrez votre mot de passe"
                : "Mot de passe",
            }}
          />
        </div>
        <div>
          <CustomTextField
            fullWidth
            id="passwordConfirmation"
            name="passwordConfirmation"
            label="Valider le mot de passe"
            value={formik.values.passwordConfirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation
            )}
            required
            helperText={
              Boolean(
                formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
              )
                ? formik.errors.passwordConfirmation
                : null
            }
            type="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              placeholder: !bakerForm
                ? "Confirmez votre mot de passe"
                : "Confirmation du mot de passe",
            }}
          />
        </div>
      </form>

      <div className={styles.buttonSection}>
        <div>
          <Checkbox
            sx={{
              "&.Mui-checked": {
                color: "#ea974a",
              },
            }}
            checked={hasAccepted}
            onChange={() => setHasAccepted(!hasAccepted)}
          />
          J'ai lu et j'accepte{" "}
          <a href="/conditions" target="_blank">
            les conditions générales de vente
          </a>
        </div>
      </div>
      <div className={styles.buttonSection}>
        {success ? (
          <Typography className={styles.errorSection}>{success}</Typography>
        ) : (
          <div className={styles.errorSection}></div>
        )}
        {error ? (
          <Typography color={"error"} className={styles.errorSection}>
            {findCorrespondingError(error)}
          </Typography>
        ) : (
          <div className={styles.errorSection}></div>
        )}
        <Button
          text="Confirmer"
          disabled={isDisabled}
          onClick={formik.submitForm}
        />
        {!bakerForm ? (
          <div style={{ marginTop: "0.75rem" }}>
            <Button
              text="Me connecter"
              outlined
              onClick={() => navigate("/login")}
            />
          </div>
        ) : (
          <div style={{ marginTop: "0.75rem" }}>
            <Button
              text="Page profil"
              outlined
              onClick={() => navigate("/profile")}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RegistrationForm;
