import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { NavBarDispatchAction, Tabs } from "./type";

interface TabState {
  tab: Tabs;
}

// Define the initial state using that type
export const initialState: TabState = {
  tab: Tabs.CART,
};

export const navBarSlice = createSlice({
  name: "navBar",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateTab: (state, action: PayloadAction<NavBarDispatchAction>) => {
      state.tab = action.payload.tab;
    },
  },
});

export const { updateTab } = navBarSlice.actions;

export const currentActiveTab = (state: RootState) => state.navBar.tab;

export default navBarSlice.reducer;
