import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeActiveDay } from "../../../store/reducers/habitCart";
import { getActiveDay } from "../../../technical/date/getActiveDay";

export enum HabitsDays {
  DIMANCHE,
  LUNDI,
  MARDI,
  MERCREDI,
  JEUDI,
  VENDREDI,
  SAMEDI,
}

interface Props {
  day: HabitsDays;
}

export const useHabitsSelector = ({ day }: Props) => {
  const [date, setDate] = useState<HabitsDays>(getActiveDay(day));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeActiveDay(date));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const dateMinusOne = () => {
    if (date === HabitsDays.DIMANCHE) {
      setDate(HabitsDays.SAMEDI);
    } else {
      setDate(date - 1);
    }
  };

  const datePlusOne = () => {
    if (date === HabitsDays.SAMEDI) {
      setDate(HabitsDays.DIMANCHE);
    } else {
      setDate(date + 1);
    }
  };

  return { date, datePlusOne, dateMinusOne, setDate };
};
