import { AxiosError } from "axios";
import React, { useState } from "react";
import {
  Role,
  UserDetails,
  UserRegistration,
} from "../../../../store/reducers/auth/type";
import { axiosRequest } from "../../../../technical/api/axiosRequest";
import RegistrationForm from "../../../registration/components/registrationForm/RegistrationForm";

const BakerCreationForm = () => {
  const initialValues = {
    passwordConfirmation: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    role: Role.BAKER,
  };
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<string | undefined>();

  const onSubmit = async (values: UserRegistration) => {
    setError(undefined);
    try {
      const config = {
        "Content-Type": "application/json",
      };
      const response = await axiosRequest<UserDetails>({
        url: "auth/register/baker",
        data: values,
        method: "post",
        config,
      });
      setSuccess("Création boulanger : succès");

      return response;
    } catch (err) {
      setError((err as AxiosError<{ message: string }>).response?.data.message);
    }
  };
  return (
    <>
      <RegistrationForm
        initialValues={initialValues}
        error={error}
        onSubmit={onSubmit}
        bakerForm={true}
        success={success}
      />
    </>
  );
};

export default BakerCreationForm;
