import React, { useEffect, useState } from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";
import { SubState } from "../../business/payment/page/subscriptionPage/Subscription";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  activateLoading,
  deactivateLoading,
} from "../../store/reducers/appState";
import { Role } from "../../store/reducers/auth/type";
import { axiosRequest } from "../../technical/api/axiosRequest";

const IsSubscribed = () => {
  const [isSubscribed, setIsSubscribed] = useState<boolean | undefined>();
  const dispatch = useAppDispatch();
  const userRole = useAppSelector((state) => state.auth.user.role);
  const bakeryId = useAppSelector((state) => state.auth.user.bakeryId);
  const isFirstConnection = useAppSelector(
    (state) => state.auth.user.isFirstConnection
  );
  const location = useLocation();

  useEffect(() => {
    dispatch(activateLoading());
    async function fetchData() {
      const res = await axiosRequest<SubState>({
        method: "get",
        url: "subscription/state",
      });
      dispatch(deactivateLoading());
      if (res.status < 300) {
        setIsSubscribed(["ONGOING", "CANCEL"].includes(res.data.status));
      }
    }

    fetchData();
  }, []);

  if (userRole !== Role.USER) {
    return <Outlet />;
  }

  if (isSubscribed && bakeryId !== null) {
    return <Outlet />;
  }

  if (
    isFirstConnection &&
    !location.pathname.includes("first-steps") &&
    !location.pathname.includes("profile")
  ) {
    return <Navigate to="/first-steps" />;
  }

  if (
    [
      "payment-callback",
      "subscription",
      "first-steps",
      "profile",
      "deleteAccount",
    ].some((elt) => location.pathname.includes(elt))
  ) {
    return <Outlet />;
  }

  return <Navigate to="/subscription" />;
};

export default IsSubscribed;
