import React from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import styles from "./index.module.css";

const ResetPassword = () => {
  return (
    <div className={styles.container}>
      <div className={styles.registrationText}>
        Réinitialisation du mot de passe
      </div>
      <div className={styles.logoAndForm}>
        <img
          src="/assets/logo/orange_black.png"
          alt="Logo Morning Baguette"
          className={styles.logo}
        />
        <ResetPasswordForm />
      </div>
    </div>
  );
};

export default ResetPassword;
