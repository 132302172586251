const numberToDay: Record<number, string> = {
  0: "Dimanche",
  1: "Lundi",
  2: "Mardi",
  3: "Mercredi",
  4: "Jeudi",
  5: "Vendredi",
  6: "Samedi",
};

export function closingDaysToWeekDay(closingDays: number[]) {
  return closingDays.map((day) => numberToDay[day]);
}
