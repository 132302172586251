import classNames from "classnames";
import React from "react";
import { addZero } from "../../../technical/string/addZero";
import styles from "./index.module.css";

const TotalLine = ({ amount }: { amount: number }) => {
  return (
    <div className={styles.totalLine}>
      <div
        className={classNames(styles.totalTitle, styles.columnWidthFlexStart)}
      >
        Total
      </div>
      <div className={styles.columnWidthCenter}></div>
      <div className={classNames(styles.productPrice, styles.columnWidthEnd)}>
        {addZero((amount / 100).toFixed(2).toString())}€
      </div>
    </div>
  );
};

export default TotalLine;
