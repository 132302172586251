import classNames from "classnames";
import React from "react";
import { addZero } from "../../../../technical/string/addZero";
import styles from "./index.module.css";

interface Props {
  amount: number;
  selected: boolean;
  onClick?: (am: number) => void;
}

const AmountRectangle = ({ amount, selected, onClick }: Props) => {
  return (
    <div
      className={classNames(
        styles.rectangle,
        selected ? styles.selected : styles.notSelected
      )}
      onClick={() => (onClick ? onClick(amount) : null)}
    >
      {addZero((amount / 100).toString()) + "€"}
    </div>
  );
};

export default AmountRectangle;
