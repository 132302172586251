import React, { useEffect, useState } from "react";
import { useSpring } from "@react-spring/web";
import { format } from "date-fns";
import BottomSection from "../../../component/common/bottomSection/bottomSection";
import DateComponent from "../../../component/drawer/dateComponent/DateComponent";
import useDate from "../../../component/drawer/dateComponent/useDate";
import ZeroDrawer from "../../../component/zeroDrawer";
import { useZeroDrawer } from "../../../component/zeroDrawer/hook";
import { Category } from "../../bakery/components/categoryCard/categoryCard";
import DisplayOrder from "../components/displayOrder/displayOrder";
import OrderComponent from "../components/orderComponent/orderComponent";
import styles from "./index.module.css";
import { OrderQueryResponse } from "./types";
import { getY } from "../../../component/zeroDrawer/getY";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { Tabs } from "../../../store/reducers/navBar/type";
import PageColor from "../../../component/common/pageColor/PageColor";

const BakerOrders = () => {
  const { datePlusOne, dateMinusOne, date } = useDate(new Date());
  const [orders, setOrders] = useState<OrderQueryResponse | undefined>();
  const { innerHeight: height } = window;

  const collapsedY = getY(height, "collapsed");
  const expandedY = getY(height, "expanded");

  const [style, api] = useSpring(() => ({
    x: 0,
    y: collapsedY, // valeur de départ
    scale: 1,
    rotateZ: 0,
  }));

  const { open, close } = useZeroDrawer({ api, expandedY, collapsedY });

  useEffect(() => {
    const fetchData = async () => {
      const ordersCall = await axiosRequest<OrderQueryResponse>({
        url: `order/date/${format(date, "yyyy-MM-dd")}`,
        method: "get",
      });

      if (ordersCall.status === 200) {
        setOrders(ordersCall.data);
      }
    };
    fetchData();
  }, [date]);

  return (
    <PageColor>
      <div className={styles.topPartBaker}>
        Mes commandes
        <DateComponent
          className={styles.dateText}
          date={date}
          dateMinusOne={dateMinusOne}
          datePlusOne={datePlusOne}
        />
        <OrderComponent date={date} openDrawer={open} />
      </div>
      <div className={styles.orders}>
        {orders
          ? Object.values(Category).map((cat) => (
              <DisplayOrder category={cat} orders={orders[cat]} key={cat} />
            ))
          : "Aucune commande aujourd'hui"}
      </div>
      <BottomSection
        customDrawer={
          <ZeroDrawer
            close={close}
            collapsedY={collapsedY}
            expandedY={expandedY}
            api={api}
            style={style}
          />
        }
        tab={Tabs.CALENDAR}
      />
    </PageColor>
  );
};

export default BakerOrders;
