import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import { ReactComponent as MailBox } from "../../../../svg/coloredMailBox.svg";
import { MailBoxLocation, UserDeliveryInformations } from "../../pages/types";
import styles from "./index.module.css";

interface Props {
  formik: FormikProps<UserDeliveryInformations>;
}
const MailBoxLocationForm = ({ formik }: Props) => {
  return (
    <div className={styles.conformMailBoxMainContainer}>
      <div className={styles.helperTextContainer}>
        <MailBox style={{ width: "20%" }} />
        <div className={styles.helperTextMailBox}>
          Où est située votre boîte aux lettres ?
        </div>
      </div>
      <>
        <RadioGroup
          value={formik.values.flatDeliveryInformation.mailBoxLocation.toString()}
          onChange={formik.handleChange}
          name="flatDeliveryInformation.mailBoxLocation"
          id="flatDeliveryInformation.mailBoxLocation"
        >
          {Object.values(MailBoxLocation).map((value) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio className={styles.radioButton} />}
              label={value}
              className={styles.radioText}
            />
          ))}
        </RadioGroup>
      </>
    </div>
  );
};

export default MailBoxLocationForm;
