import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { Category } from "../../bakery/components/categoryCard/categoryCard";
import { IProduct } from "../../categoryDetail/components/product/product";
import { ProductQueryResponse } from "../../categoryDetail/page/categoryDetail";
import ProductForm from "../components/productForm/productForm";

interface State {
  categoryName: Category;
}

const ProductCreation = () => {
  const { state } = useLocation();

  let { id } = useParams();

  const [product, setProduct] = useState<IProduct | undefined>();

  useEffect(() => {
    async function fetchData() {
      const res = await axiosRequest<ProductQueryResponse>({
        url: `product/${id}`,
        method: "get",
      });

      if (res.status < 400) {
        const { _id, name, description, price, category, imageUrl } = res.data;
        const tmpProduct: IProduct = {
          id: _id,
          name,
          description,
          price: price / 100,
          category,
          imageUrl,
        };

        setProduct(tmpProduct);
      }
    }
    if (!state) {
      fetchData();
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (product) {
    return <ProductForm initialValues={product} />;
  } else {
    if (state) {
      const initialVal: IProduct = {
        name: "",
        price: 0,
        category: (state as State).categoryName,
        description: "",
        id: "",
        imageUrl: "",
      };
      return <ProductForm initialValues={initialVal} />;
    }
    return <div>Fetching</div>;
  }
};

export default ProductCreation;
