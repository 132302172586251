import classNames from "classnames";
import React, { useState } from "react";
import Button from "../../../component/common/button/Button";
import CustomTextField from "../../../component/common/customTextField/CustomTextField";
import { useAppDispatch } from "../../../store/hooks";
import {
  activateLoading,
  deactivateLoading,
} from "../../../store/reducers/appState";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { ProductLineQuantity } from "../../bakerOrders/components/displayOrder/displayOrder";
import { Order } from "../../cart/OrderCart";
import styles from "../page/index.module.css";

type Location = [number, number];

interface Delivery {
  id: string;
  firstName: number;
  lastName: number;
  phoneNumber: number;
  address: number;
  zipCode: number;
  isHouse: boolean;
  isMailBoxConform: boolean;
  deliverySolution: string;
  keyType?: string;
  flatDeliveryInformation?: {
    flatAccess: string;
    mailBoxLocation: string;
    accessCode: string;
  };
  location: Location;
  order: { order: Order };
}

const DeliveryField = ({
  index,
  delivery,
}: {
  index: number;
  delivery: Delivery;
}) => {
  const { location } = delivery;
  const [disabledList, setDisabledList] = useState<number[]>([]);
  const dispatch = useAppDispatch();

  const arrayFromSetOfNames = Array.from(
    new Set(delivery.order.order.products.map((item) => item.name))
  );

  const handleFinishedClick = (idx: number) => {
    setDisabledList([...disabledList, idx]);
  };

  const handleSaveKeyType = async (keyType: string | undefined) => {
    dispatch(activateLoading());
    await axiosRequest<{ success: boolean }>({
      method: "post",
      url: "user/keyType",
      data: { keyType, id: delivery.id },
    });

    dispatch(deactivateLoading());
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const [keyValue, setKeyValue] = useState<string | undefined>(
    delivery.keyType
  );

  return (
    <div className={styles.mainContainer} key={delivery.lastName}>
      <div
        className={classNames(
          disabledList.includes(index) ? styles.linkDone : styles.link
        )}
      >
        Livraison #{index + 1}
      </div>
      <div className={styles.infoContainer}>
        <b className={styles.marginRight}>Nom :</b>
        <div className={styles.marginRight}>{delivery.firstName}</div>
        <div>{delivery.lastName}</div>
      </div>
      <div className={styles.infoContainer}>
        <b className={styles.marginRight}>Numéro de téléphone :</b>
        <div>{delivery.phoneNumber}</div>
      </div>
      <br />
      <div className={styles.infoColumnContainer}>
        <h3 className={classNames(styles.marginRight, styles.marginBottom)}>
          Information de livraison :
        </h3>
        <div>{delivery.address}</div>
        <b>{delivery.isHouse ? "Maison" : "Appartement"}</b>
        <div>
          {delivery.isMailBoxConform
            ? "Boîte à lettre conforme"
            : "Boîte à lettre non conforme"}
        </div>
        <>
          {delivery.deliverySolution ? (
            <div className={styles.infoContainer}>
              <b className={styles.marginRight}>Solution de livraison :</b>
              {delivery.deliverySolution}
            </div>
          ) : null}
        </>
        {delivery.flatDeliveryInformation ? (
          <>
            <div className={styles.infoContainer}>
              <b className={styles.marginRight}>Location BAL :</b>
              <p>{delivery.flatDeliveryInformation.mailBoxLocation ?? null}</p>
            </div>
            <div className={styles.infoContainer}>
              <b className={styles.marginRight}>BAL accessible :</b>
              <p>{delivery.flatDeliveryInformation.flatAccess ?? null}</p>
            </div>
            <div className={styles.infoContainer}>
              <b className={styles.marginRight}>Code :</b>
              <p>{delivery.flatDeliveryInformation.accessCode ?? null}</p>
            </div>
          </>
        ) : null}
      </div>
      <div className={styles.productContainer}>
        <h3 className={classNames(styles.marginBottom)}>Produits à livrer :</h3>
        {arrayFromSetOfNames.map((name) => (
          <ProductLineQuantity
            name={name}
            orders={delivery.order.order.products.map(
              ({ id, name, description, price, category }) => ({
                id,
                name,
                description: description ?? "",
                category,
                price,
              })
            )}
            key={name}
          />
        ))}
      </div>
      <div className={styles.keyTypeContainer}>
        <b style={{ marginBottom: "20px" }}>Type de clé :</b>
        <div style={{ marginBottom: "20px" }}>
          <CustomTextField
            fullWidth
            id="keyType"
            name="keyType"
            label="Type de clé"
            value={keyValue}
            onChange={(e) => setKeyValue(e.target.value)}
            multiline
            rows={4}
          />
        </div>
        <div>
          <Button
            text={"Enregistrer"}
            onClick={() => handleSaveKeyType(keyValue)}
            disabled={disabledList.includes(index)}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <div>
          <Button
            text={"Effectué"}
            onClick={() => handleFinishedClick(index)}
            disabled={disabledList.includes(index)}
            outlined={true}
          />
        </div>
        <div>
          <Button
            text={"Guidage"}
            onClick={() =>
              openInNewTab(
                `https://maps.google.com/?q=${location[1]},${location[0]}`
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DeliveryField;
