import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { AxiosError } from "axios";
import classNames from "classnames";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../component/common/button/Button";
import Marker from "../../../component/marker/Marker";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import BakeryCreationForm from "../components/bakeryCreationForm/BakeryCreationForm";
import { BakerRegistrationValues } from "../components/bakeryCreationForm/type";
import bakeryFormValidator from "../components/bakeryCreationForm/validator";
import DrawingMapComponent from "../components/DrawingMapComponent/DrawingMapComponent";
import styles from "./index.module.css";

const render = (status: Status) => {
  switch (status) {
    case Status.LOADING:
      return <div>loading</div>;
    case Status.FAILURE:
      return <div>failed</div>;
    case Status.SUCCESS:
      return <div>success</div>;
  }
};

const Admin = () => {
  const zoom = 16;
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 48.8566,
    lng: 2.3522,
  });
  const [map, setMap] = useState<google.maps.drawing.DrawingManager>();

  const [polygon, setPolygon] = useState<any>();

  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<string | undefined>();
  const navigate = useNavigate();

  const initialValues = {
    userId: "",
    bakeryName: "",
    addressInformations: {
      address: "",
      zipCode: "",
      latAndLng: [],
    },
    phoneNumber: "",
    description: "",
    zone: [],
    deliveryCode: "",
  };

  const bakeryCreationFormik = useFormik<BakerRegistrationValues>({
    initialValues: initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: bakeryFormValidator,
    onSubmit: async (val: BakerRegistrationValues) => {
      try {
        await axiosRequest({ method: "post", url: "bakery", data: val });

        setSuccess("Boulangerie créée avec succès");
        setError(undefined);
      } catch (err: any) {
        console.log(err);
        setSuccess(undefined);
        setError((err as AxiosError).message);
      }
    },
  });

  if (map) {
    google.maps.event.addListener(
      map,
      "overlaycomplete",
      function (event: any) {
        if (event.type === "polygon") {
          setPolygon(event.overlay);
          const zone = event.overlay
            .getPath()
            .getArray()
            .map((elt: any) => ({
              lat: elt.lat(),
              lng: elt.lng(),
            }));

          bakeryCreationFormik.setFieldValue("zone", zone);
        }
      }
    );
  }

  const handleReset = () => {
    if (polygon) {
      polygon.setVisible(false);
    }
  };

  return (
    <div className={styles.admin}>
      <div className={styles.mapComponent}>
        <Wrapper
          apiKey={"AIzaSyDbGc10YTTIxoe-c_MP3_spsCPrEamAZY4"}
          render={render}
        >
          <DrawingMapComponent
            zoom={zoom}
            map={map}
            setMap={setMap}
            center={center}
            className={styles.mapComponent}
          >
            <Marker position={center} />
          </DrawingMapComponent>
        </Wrapper>
      </div>
      {/* Zone de création de la boulangerie ici */}
      <div
        className={classNames(
          styles.autocompleteSection,
          styles.autocompleteInsideLayout
        )}
      >
        <div className={styles.cont}>
          <div style={{ width: "33%" }}>
            <Button
              text={"Vers page profil"}
              onClick={() => navigate("/profile")}
            />
          </div>
          <div style={{ width: "33%" }}>
            <Button outlined text={"Recommencer zone"} onClick={handleReset} />
          </div>
        </div>
        <div className={styles.creationSection}>
          <div className={styles.bakeryCreationSection}>
            Création de la boulangerie
            <BakeryCreationForm
              setCenter={setCenter}
              formik={bakeryCreationFormik}
              error={error}
              success={success}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
