import { Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import BottomSection from "../../../component/common/bottomSection/bottomSection";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { logout } from "../../../store/reducers/auth";
import { Role } from "../../../store/reducers/auth/type";
import { Tabs } from "../../../store/reducers/navBar/type";
import BakeryClosingDays from "../components/bakeryClosingDays/BakeryClosingDays";
import styles from "./index.module.css";

const Profile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userRole = useAppSelector((state) => state.auth.user.role);
  return (
    <div className={styles.mainContainer}>
      <h2>Mon profil</h2>
      <div style={{ width: "100%" }}>
        <Stack spacing={4}>
          {userRole === Role.USER ? (
            <>
              <div
                className={styles.item}
                onClick={() => navigate("/accountInformation")}
              >
                Mes informations personnelles
              </div>
              <div className={styles.item} onClick={() => navigate("/wallet")}>
                Recharger sa cagnotte
              </div>
              <div
                className={styles.item}
                onClick={() => navigate("/subscription")}
              >
                Gestion abonnement
              </div>
              <div
                className={styles.item}
                onClick={() => navigate("/first-steps")}
              >
                Modifier mes informations de livraison
              </div>
              <div
                className={styles.item}
                onClick={() =>
                  window.open(
                    "https://www.morningbaguette.fr/tutoriel",
                    "_blank"
                  )
                }
              >
                Aide
              </div>
            </>
          ) : null}
          {userRole === Role.BAKER ? <BakeryClosingDays /> : null}
          {userRole === Role.ADMIN ? (
            <>
              <div
                className={styles.item}
                onClick={() => navigate("/createBaker")}
              >
                Créer un boulanger
              </div>
              <div className={styles.item} onClick={() => navigate("/admin")}>
                Créer une boulangerie
              </div>
              <div
                className={styles.item}
                onClick={() => navigate("/createDeliveryMan")}
              >
                Créer un livreur
              </div>
              <div
                className={styles.item}
                onClick={() => navigate("/adminUpdateBakery")}
              >
                Modifier une boulangerie
              </div>
              <div
                className={styles.item}
                onClick={() => navigate("/adminSuiviCa")}
              >
                Suivi CA
              </div>
            </>
          ) : null}
          {userRole === Role.BAKER ? (
            <div
              className={styles.item}
              onClick={() => navigate("/bakeryInformation")}
            >
              Ma boulangerie
            </div>
          ) : null}

          <div className={styles.item} onClick={() => dispatch(logout())}>
            Se déconnecter
          </div>
        </Stack>
      </div>
      <BottomSection hasDrawer={false} tab={Tabs.PROFILE} />
    </div>
  );
};

export default Profile;
