import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDay } from "date-fns";
import { Category } from "../../../business/bakery/components/categoryCard/categoryCard";
import { getActiveDay } from "../../../technical/date/getActiveDay";
import type { RootState } from "../../store";
import { replaceBakeryId } from "../auth/actions";
import { changeHabitCart, getAllHabits, submitHabitCart } from "./action";
import { HabitCartDispatchAction } from "./type";

export interface CartContent {
  id: string;
  name: string;
  price: number;
  category: Category;
}

// Define a type for the slice state
export interface HabitCartStateContent {
  totalAmount: number;
  content: CartContent[];
  day: number;
  hasUnsavedChanges: boolean;
}

interface HabitCartState {
  cart: HabitCartStateContent[];
  activeDay: number;
}

// Define the initial state using that type
export const initialState: HabitCartState = {
  cart: [
    {
      totalAmount: 0,
      content: [],
      day: 0,
      hasUnsavedChanges: false,
    },
    {
      totalAmount: 0,
      content: [],
      day: 1,
      hasUnsavedChanges: false,
    },
    {
      totalAmount: 0,
      content: [],
      day: 2,
      hasUnsavedChanges: false,
    },
    {
      totalAmount: 0,
      content: [],
      day: 3,
      hasUnsavedChanges: false,
    },
    {
      totalAmount: 0,
      content: [],
      day: 4,
      hasUnsavedChanges: false,
    },
    {
      totalAmount: 0,
      content: [],
      day: 5,
      hasUnsavedChanges: false,
    },
    {
      totalAmount: 0,
      content: [],
      day: 6,
      hasUnsavedChanges: false,
    },
  ],
  activeDay: getDay(Date.now()),
};

export const habitCartSlice = createSlice({
  name: "habitCart",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addToHabitCart: (state, action: PayloadAction<HabitCartDispatchAction>) => {
      const { product, day } = action.payload;
      state.cart[day].totalAmount += product.price;
      state.cart[day].content = [...state.cart[day].content, product];
      state.cart[day].hasUnsavedChanges = true;
    },
    removeFromHabitCart: (
      state,
      action: PayloadAction<HabitCartDispatchAction>
    ) => {
      const { product, day } = action.payload;
      state.cart[day].totalAmount -= product.price;

      const index = state.cart[day].content.findIndex(
        (elt) => elt.name === product.name
      );

      state.cart[day].content.splice(index, 1);
      state.cart[day].hasUnsavedChanges = true;
    },
    removeByIdFromHabitCart: (
      state,
      action: PayloadAction<{ id: string; day: number; price: number }>
    ) => {
      const { id, day, price } = action.payload;
      const newCart = state.cart[day].content.filter((elt) => elt.id !== id);
      state.cart[day].totalAmount -=
        price * (state.cart[day].content.length - newCart.length);

      state.cart[day].content = newCart;
    },
    changeActiveDay: (state, action: PayloadAction<number>) => {
      const oldActiveDay = getActiveDay(state.activeDay);
      state.cart[oldActiveDay].hasUnsavedChanges = false;

      state.activeDay = action.payload;
    },
    changeBakeryDeleteCart: (state) => {
      for (let i = 0; i < state.cart.length; i++) {
        state.cart[i].content = [];
        state.cart[i].totalAmount = 0;
        state.cart[i].hasUnsavedChanges = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeHabitCart.fulfilled, (state, action) => {
      if (action.payload) {
        const { day, products } = action.payload;
        state.cart[day].content = products;
        state.cart[day].totalAmount = products.reduce(
          (acc, cur) => (acc += cur.price),
          0
        );
      }
    });
    builder.addCase(submitHabitCart.fulfilled, (state, action) => {
      if (action.payload) {
        state.cart[action.payload].hasUnsavedChanges = false;
      }
    });

    builder.addCase(replaceBakeryId.fulfilled, (state, action) => {
      if (action.payload) {
        for (let i = 0; i < state.cart.length; i++) {
          state.cart[i].content = [];
          state.cart[i].totalAmount = 0;
          state.cart[i].hasUnsavedChanges = false;
        }
      }
    });

    builder.addCase(getAllHabits.fulfilled, (state, action) => {
      if (action.payload) {
        for (let i = 0; i < action.payload.length; i++) {
          const day = action.payload[i].day;
          state.cart[day].content = action.payload[i].content;
          state.cart[day].totalAmount = action.payload[i].totalAmount;
          state.cart[day].hasUnsavedChanges = false;
        }
      }
    });
  },
});

export const {
  addToHabitCart,
  removeFromHabitCart,
  changeActiveDay,
  changeBakeryDeleteCart,
  removeByIdFromHabitCart,
} = habitCartSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectHabitCartTotalAmount = (state: RootState) =>
  state.cart.totalAmount;

export const selectHabitCartContent = (state: RootState) => state.cart.content;

export default habitCartSlice.reducer;
