import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { loginUser, replaceBakeryId, walletRefresh } from "./actions";
import { AuthDispatchAction, Role, UserDetails } from "./type";
interface AuthState {
  user: UserDetails;
  token: string | null;
  success: boolean | null;
  loading: boolean;
  message?: string;
}
// Define the initial state using that type
export const initialState: AuthState = {
  user: {
    id: "",
    firstName: "",
    lastName: "",
    role: Role.ANONYMOUS,
    email: "",
    isEmailVerified: false,
    isFirstConnection: false,
    walletAmount: 0,
    bakeryId: null,
  },
  token: null,
  success: null,
  loading: false,
  message: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<AuthDispatchAction>) => {
      const { user, accessToken } = action.payload;
      state.user = user;
      state.token = accessToken;
    },
    setFirstStepsDone: (state, action: PayloadAction<{ bakeryId: string }>) => {
      state.user.isFirstConnection = false;
      state.user.bakeryId = action.payload.bakeryId;
    },
    logout: (state) => {
      state.user = initialState.user;
      state.token = null;
    },
    replaceWallet: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        state.user.walletAmount = action.payload;
      }
    },
    subFromWallet: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        state.user.walletAmount -= action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.token = action.payload.data.token;
      state.user = action.payload.data.user;
      state.success = true;
      state.loading = false;
      state.message = undefined;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.token = null;
      state.success = false;
      state.loading = false;
      state.message = action.payload;
    });
    builder.addCase(walletRefresh.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(walletRefresh.fulfilled, (state, action) => {
      if (action.payload) {
        state.user.walletAmount = action.payload;
      }
      state.success = true;
      state.loading = false;
    });
    builder.addCase(walletRefresh.rejected, (state) => {
      state.success = true;
      state.loading = false;
    });
    builder.addCase(replaceBakeryId.fulfilled, (state, action) => {
      if (action.payload) {
        state.user.bakeryId = action.payload;
      }
    });
  },
});

export const {
  setCredentials,
  logout,
  setFirstStepsDone,
  replaceWallet,
  subFromWallet,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentToken = (state: RootState) => state.auth.token;
