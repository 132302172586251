import * as yup from "yup";

const stringValidator = yup.string().trim().required("Ce champ est requis");

const frenchPhoneNumberRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,}$/;

const userRegistrationValidator = yup.object().shape({
  firstName: stringValidator,
  lastName: stringValidator,
  email: yup
    .string()
    .email("L'adresse mail doit être valide")
    .required("Ce champ est requis"),
  phoneNumber: yup
    .string()
    .matches(frenchPhoneNumberRegex, "Le numéro de téléphone est invalide")
    .required("Ce champ est requis"),
  password: yup
    .string()
    .matches(
      passwordRegex,
      "Le mot de passe doit contenir au moins 8 caractères, avec au minimum une lettre majuscule, une lettre minuscule et un chiffre"
    )
    .required(
      "Le mot de passe doit contenir au moins 8 caractères, avec au minimum une lettre majuscule, une lettre minuscule et un chiffre"
    ),
  passwordConfirmation: yup
    .string()
    .test(
      "passwords-match",
      "Veuillez confirmer votre mot de passe",
      function (value) {
        return this.parent.password === value;
      }
    )
    .required("Veuillez confirmer votre mot de passe"),
});

export default userRegistrationValidator;
