import { Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { capitalizeFirstLetter } from "../../../../technical/string/capitalize";
import { BakeryDetails } from "../../../firstSteps/pages/types";
import styles from "./index.module.css";

interface Props {
  setSelectedBakery: (id: string) => void;
  selectedBakery?: string;
  bakeries: BakeryDetails[];
}

export const SelectBakery = ({
  selectedBakery,
  setSelectedBakery,
  bakeries,
}: Props) => {
  return (
    <Select
      native
      labelId="demo-simple-select-helper-label"
      id="demo-simple-select-helper"
      value={selectedBakery}
      label="Boulanger"
      onChange={(e: SelectChangeEvent) => setSelectedBakery(e.target.value)}
      fullWidth
      required
      className={styles.inputRounded}
    >
      <option>Veuillez sélectionner votre boulanger</option>
      {bakeries.map((bakery) => (
        <option key={bakery.id} value={bakery.id}>
          {capitalizeFirstLetter(bakery.bakeryName)}
        </option>
      ))}
    </Select>
  );
};
