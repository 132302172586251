import React from "react";
import { useNavigate } from "react-router-dom";
import ImageSquare from "../../../../component/common/imageSquare/ImageSquare";
import styles from "./index.module.css";

interface Props {
  id: string;
  imageUrl: string;
}

const SelectProductModify = ({ id, imageUrl }: Props) => {
  const navigation = useNavigate();

  return (
    <ImageSquare imageUrl={imageUrl}>
      <div
        className={styles.longBadge}
        onClick={() => navigation(`/product/${id}`)}
      >
        <div className={styles.iconContainer}>Modifier</div>
      </div>
    </ImageSquare>
  );
};

export default SelectProductModify;
