import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { ReactComponent as Success } from "../../../svg/success.svg";
import styles from "./index.module.css";
import Button from "../../../component/common/button/Button";
const EmailConfirmation = () => {
  let { id } = useParams();

  const navigate = useNavigate();

  useMemo(() => {
    async function verifyEmail() {
      await axiosRequest({
        method: "get",
        url: `auth/verify/${id}`,
      });
    }

    verifyEmail();
  }, [id]);

  return (
    <div className={styles.container}>
      <img
        src="/assets/logo/orange_black.png"
        alt="Logo Morning Baguette"
        className={styles.logo}
      />
      <div>
        <Success />
        <div className={styles.thanks}>Merci</div>
        <div className={styles.text}>Votre email a bien été confirmé.</div>
      </div>
      <div className={styles.button}>
        <Button
          text={"Accéder à la plateforme"}
          onClick={() => navigate("/landing")}
        />
      </div>
    </div>
  );
};

export default EmailConfirmation;
