import React from "react";
import { useNavigate } from "react-router-dom";
import WalletContainer from "../../../../component/common/wallet/WalletContainer";
import { Role } from "../../../../store/reducers/auth/type";
import { ReactComponent as PointLeft } from "../../../../svg/pointLeftArrow.svg";
import styles from "./index.module.css";

const ClassicTopBar = ({ userRole }: { userRole: Role }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.topBar}>
      <PointLeft onClick={() => navigate(-1)} style={{ marginBottom: 5 }} />
      {userRole === Role.USER ? <WalletContainer alternatif /> : null}
    </div>
  );
};

export default ClassicTopBar;
