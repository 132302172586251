import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { ReactComponent as Calendar } from "../../../../svg/calendar.svg";

import styles from "./index.module.css";
import { format } from "date-fns";

interface Props {
  date: Date;
  openDrawer: (vy?: number) => void;
}

const OrderComponent = ({ date, openDrawer }: Props) => {
  const [commandText, setCommandText] = useState<string>();

  useEffect(() => {
    const dateNow = new Date(Date.now());

    if (dateNow.getHours() >= 21) {
      setCommandText("Commandes clôturées");
    } else {
      setCommandText("Clôture des commandes :  21h");
    }
    const interval = setInterval(() => {
      const dateNow = new Date(Date.now());

      if (dateNow.getHours() >= 21) {
        setCommandText("Commandes clôturées");
      } else {
        setCommandText("Clôture des commandes :  21h");
      }
    }, 3600000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classNames(styles.container, styles.text)}>
      <div
        className={styles.dateComponent}
        // onClick={() => openDrawer()}
        onClick={() => {}}
      >
        <Calendar className={styles.marginRight} /> {format(date, "dd.MM")}
      </div>
      <div className={styles.commandComponent}>{commandText}</div>
    </div>
  );
};

export default OrderComponent;
