import React from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import styles from "./index.module.css";

const ForgotPassword = () => {
  return (
    <div className={styles.container}>
      <div className={styles.registrationText}>Mot de passe oublié</div>
      <div className={styles.logoAndForm}>
        <img
          src="/assets/logo/orange_black.png"
          alt="Logo Morning Baguette"
          className={styles.logo}
        />
        <ForgotPasswordForm />
      </div>
    </div>
  );
};

export default ForgotPassword;
