import React from "react";
import styles from "./index.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Button from "../../../component/common/button/Button";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.containerCarousel}>
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          swipeable
          emulateTouch
          infiniteLoop
        >
          <div>
            <img
              src="assets/carousel/carousel_1.png"
              alt="carousel boulangerie"
            />
          </div>
          <div>
            <img
              src="assets/carousel/carousel_2.png"
              alt="carousel boulangerie"
            />
          </div>
          <div>
            <img
              src="assets/carousel/carousel_3.png"
              alt="carousel boulangerie"
            />
          </div>
        </Carousel>
      </div>
      <div className={styles.horizontalMargin}>
        <div className={styles.marginBottom}>
          <Button
            text={"Créer mon compte"}
            onClick={() => navigate("/registration")}
          />
        </div>
        <Button
          text={"Me connecter"}
          outlined
          onClick={() => navigate("/login")}
        />
      </div>
      {/* </div>
      </div> */}
    </div>
  );
};

export default LandingPage;
