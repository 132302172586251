import classNames from "classnames";
import React from "react";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { capitalizeFirstLetter } from "../../../technical/string/capitalize";
import HorizontalDivider from "../horizontalDivider/horizontalDivider";
import { ReactComponent as TrashIcon } from "../../../svg/trash.svg";
import styles from "./index.module.css";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { replaceWallet } from "../../../store/reducers/auth";
import { isFuture } from "date-fns";
import { submitHabitCart } from "../../../store/reducers/habitCart/action";
import { removeByIdFromHabitCart } from "../../../store/reducers/habitCart";
import {
  activateLoading,
  deactivateLoading,
} from "../../../store/reducers/appState";

interface Props {
  name: string;
  price: string;
  quantity: number;
  id?: string;
  date?: Date;
  isHabit?: boolean;
}

const ProductLine = ({ name, quantity, price, id, date, isHabit }: Props) => {
  const dispatch = useAppDispatch();
  const habitCartContent = useAppSelector(
    (state) => state.habitCart.cart[state.habitCart.activeDay].content
  );
  const day = useAppSelector((state) => state.habitCart.activeDay);

  const handleClick = async () => {
    dispatch(activateLoading());
    const orderUpdate = await axiosRequest<{
      success: boolean;
      walletAmount: number;
    }>({
      method: "post",
      url: "order/update",
      data: {
        quantity,
        id,
        name,
        date: date ? date?.toISOString() : new Date().toISOString(),
      },
    });

    if (orderUpdate.status < 300 && orderUpdate.data.success) {
      dispatch(replaceWallet(orderUpdate.data.walletAmount));
    }
    dispatch(deactivateLoading());
  };

  const handleHabitDeleteClick = () => {
    dispatch(removeByIdFromHabitCart({ id: id!, day, price: +price }));
    const newHabitCart = habitCartContent.filter((elt) => elt.id !== id);
    dispatch(
      submitHabitCart({ products: newHabitCart, day, status: "ONGOING" })
    );
  };

  return (
    <div className={styles.productContainer}>
      <div
        className={classNames(
          styles.container,
          !((id && (date ? isFuture(date) : false)) || (id && isHabit))
            ? styles.three
            : null
        )}
      >
        <div className={styles.columnWidthStart}>
          <div className={styles.valueBadge}>x {quantity}</div>
        </div>
        <div
          className={classNames(
            styles.productName,
            styles.columnWidthCenter,
            styles.centerText
          )}
        >
          {capitalizeFirstLetter(name)}
        </div>
        <div className={classNames(styles.columnWidthEnd, styles.productPrice)}>
          {price}€
        </div>
        {(id && (date ? isFuture(date) : false)) || (id && isHabit) ? (
          <div className={styles.columnWidthEnd}>
            <TrashIcon
              onClick={() => {
                if (isHabit) {
                  handleHabitDeleteClick();
                } else {
                  handleClick();
                }
              }}
            />
          </div>
        ) : null}
      </div>
      <div className={styles.divider}>
        <HorizontalDivider />
      </div>
    </div>
  );
};

export default ProductLine;
