import { addDays, isEqual, isTomorrow, subDays } from "date-fns";
import { useState } from "react";

const useDate = (givenDate?: Date) => {
  const [date, setDate] = useState<Date>(givenDate ?? addDays(new Date(), 1));

  function dateMinusOne() {
    if (givenDate) {
      if (isEqual(date.setHours(0, 0, 0, 0), givenDate.setHours(0, 0, 0, 0))) {
        return date;
      }

      setDate(subDays(date, 1));
    } else {
      if (isTomorrow(date)) {
        return date;
      }

      setDate(subDays(date, 1));
    }
  }

  function datePlusOne() {
    setDate(addDays(date, 1));
  }

  return {
    dateMinusOne,
    datePlusOne,
    date,
    setDate,
  };
};

export default useDate;
