import React, { useRef, useEffect, ReactNode } from "react";

interface Props {
  center: google.maps.LatLngLiteral;
  zoom: number;
  map: google.maps.drawing.DrawingManager | undefined;
  setMap: (map: google.maps.drawing.DrawingManager) => void;
  className?: string;
  children?: ReactNode;
}

const DrawingMapComponent = ({
  center,
  zoom,
  map,
  setMap,
  className,
  children,
}: Props) => {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    setMap(
      new window.google.maps.drawing.DrawingManager({
        map: new window.google.maps.Map(ref.current, {
          center,
          zoom,
          clickableIcons: false,
          disableDefaultUI: true,
          fullscreenControl: false,
        }),
        markerOptions: {
          visible: true,
        },
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map && map.getMap()) {
      map.getMap()!.panTo(center);
    }
  }, [center, map]);
  return (
    <>
      <div
        ref={ref}
        id="map"
        className={className}
        onClick={(e) => e.preventDefault()}
      />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default DrawingMapComponent;
