import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BottomSection from "../../../component/common/bottomSection/bottomSection";
import HorizontalDivider from "../../../component/common/horizontalDivider/horizontalDivider";
import { useAppSelector } from "../../../store/hooks";
import CategoryName from "../components/categoryName/categoryName";
import Product, { IProduct } from "../components/product/product";
import styles from "./index.module.css";
import { ReactComponent as PlusFill } from "../../../svg/plusFill.svg";
import { Button } from "@mui/material";
import { Role } from "../../../store/reducers/auth/type";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { useHabitsSelector } from "../../habits/page/useHabitsSelector";
import { Tabs } from "../../../store/reducers/navBar/type";
import PageColor from "../../../component/common/pageColor/PageColor";
import ClassicTopBar from "../components/topBar/ClassicTopBar";
import { HabitTopBar } from "../components/topBar/HabitTopBar";

export interface ProductQueryResponse extends IProduct {
  _id: string;
}
const CategoryDetail = () => {
  let { categoryName, habit } = useParams();

  const navigate = useNavigate();
  const userRole = useAppSelector((state) => state.auth.user.role);
  const bakeryId = useAppSelector((state) => state.auth.user.bakeryId);
  const day = useAppSelector((state) => state.habitCart.activeDay);
  const isHabitPage = habit !== "false";

  const [products, setProducts] = useState<IProduct[] | undefined>();

  useEffect(() => {
    axiosRequest<ProductQueryResponse[]>({
      url: `product/category/${categoryName}/${bakeryId}`,
      method: "get",
    }).then((response) => {
      if (response.status === 200 && response.data.length > 0) {
        const tmpProducts = response.data.map((product) => {
          const { _id, imageUrl, price, description, name, category } = product;
          return {
            id: _id,
            price,
            description,
            name,
            category,
            imageUrl,
          };
        });
        setProducts(tmpProducts);
      }
    });
  }, [bakeryId, categoryName]);

  const { dateMinusOne, datePlusOne, date, setDate } = useHabitsSelector({
    day,
  });

  return (
    <PageColor
      className={styles.container}
      isColored={userRole !== Role.BAKER && isHabitPage}
    >
      <div className={styles.mainContainer}>
        <div style={{ marginBottom: "2rem" }}>
          {habit === "false" ? (
            <ClassicTopBar userRole={userRole} />
          ) : (
            <HabitTopBar
              userRole={userRole}
              selectedDay={date}
              setDate={setDate}
            />
          )}
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <CategoryName
            categoryName={categoryName}
            isHabitPage={isHabitPage}
            className={classNames(
              styles.categoryName,
              userRole === Role.BAKER ? styles.titleHandwriting : {},
              isHabitPage && userRole === Role.USER ? styles.categoryTitle : {}
            )}
          />
          {userRole === Role.USER ? <HorizontalDivider /> : null}
        </div>
        <div
          className={
            isHabitPage ? styles.habitProductSection : styles.productSection
          }
        >
          {products
            ? products.map((elt) => (
                <Product
                  isHabit={habit === "true"}
                  key={elt.id}
                  productProps={elt}
                />
              ))
            : null}
        </div>
      </div>
      <BottomSection
        dateHabits={date}
        dateMinusOneHabits={dateMinusOne}
        datePlusOneHabits={datePlusOne}
        isHabitDrawer={habit === "true"}
        hasDrawer={userRole === Role.USER}
        modifyButton={
          userRole === Role.BAKER ? (
            <div className={styles.sectionAddProduct}>
              <Button
                className={styles.buttonAddProduct}
                onClick={() =>
                  navigate("/product/create", { state: { categoryName } })
                }
              >
                <PlusFill className={styles.icon} />
                Ajouter un nouveau produit
              </Button>
            </div>
          ) : null
        }
        tab={habit === "false" ? Tabs.CART : Tabs.HABITS}
      />
    </PageColor>
  );
};

export default CategoryDetail;
