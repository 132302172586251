import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { addZero } from "../../../../technical/string/addZero";
import { DailyCaResponse } from "../../type/type";

interface Props {
  orders: DailyCaResponse[];
}

const Row = ({ row }: { row: DailyCaResponse }) => {
  const { day, orderNb, totalAmount } = row;

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {format(new Date(day), "yyyy-MM-dd")}
        </TableCell>
        <TableCell align="right">{orderNb}</TableCell>
        <TableCell align="right">
          {addZero((totalAmount / 100).toString())}€
        </TableCell>
      </TableRow>
    </>
  );
};

export const CollapsibleTable = ({ orders }: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell align="right">Commandes</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row) => (
            <Row key={row.day} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
