import React from "react";
import { ProductLineQuantity } from "../../bakerOrders/components/displayOrder/displayOrder";
import { Product } from "../../cart/OrderCart";
import styles from "../page/index.module.css";

interface Props {
  products: Product[];
}

const PickupField = ({ products }: Props) => {
  if (products.length === 0) {
    return null;
  }

  const arrayFromSetOfNames = Array.from(
    new Set(products.map((item) => item.name))
  );

  return (
    <div style={{ marginBottom: "10px" }}>
      <div className={styles.link}>Tous les articles à récupérer</div>
      {arrayFromSetOfNames.map((name, index) => (
        <div
          style={{
            marginBottom:
              index !== arrayFromSetOfNames.length - 1 ? "10px" : "0px",
          }}
        >
          <ProductLineQuantity name={name} orders={products} />
        </div>
      ))}
    </div>
  );
};

export default PickupField;
