import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import CategoryDetail from "./business/categoryDetail/page/categoryDetail";
import BakeryPage from "./business/bakery/page/BakeryPage";
import ProductCreation from "./business/productCreation/page/productCreation";
import { createTheme, ThemeProvider } from "@mui/material";
import BakerOrders from "./business/bakerOrders/page/bakerOrders";
import LoginPage from "./business/login/pages/LoginPage";
import PrivateRoutes from "./utils/privateRoute/PrivateRoutes";
import RegistrationPage from "./business/registration/pages/RegistrationPage";
import FirstSteps from "./business/firstSteps/pages/FirstSteps";
import Wallet from "./business/wallet/page/wallet";
import PaymentCallback from "./business/wallet/components/paymentCallback/PaymentCallback";
import Habits from "./business/habits/page/Habits";
import Admin from "./business/admin/page/Admin";
import Calendar from "./business/calendar/pages/Calendar";
import Delivery from "./business/delivery/page/delivery";
import Subscription from "./business/payment/page/subscriptionPage/Subscription";
import Profile from "./business/profile/page/Profile";
import LandingPage from "./business/landingPage/page/LandingPage";
import EmailConfirmation from "./business/emailConfirmation/page/EmailConfirmation";
import ResetPassword from "./business/resetPassword/pages/ResetPassword";
import ForgotPassword from "./business/forgotPassword/pages/ForgotPassword";
import CreateBaker from "./business/admin/page/CreateBaker";
import CreateDeliveryMan from "./business/admin/page/CreateDeliveryMan";
import { AdminSection } from "./component/common/adminSection/AdminSection";
import BakeryInfos from "./business/bakeryInfo/pages/BakeryInfos";
import SuiviCa from "./business/suiviCa/page/SuiviCa";
import SuiviCaParent from "./business/suiviCa/page/SuiviCaParent";
import CancelCallback from "./business/wallet/components/cancelCallback/CancelCallback";
import { useAppDispatch } from "./store/hooks";
import { AdminSuiviCa } from "./business/admin/page/AdminSuiviCa";
import { AdminUpdateZone } from "./business/admin/page/AdminUpdateZone";
import VerifyEmail from "./business/verifyEmail/VerifyEmail";
import AccountInformation from "./business/accountInformation/AccountInformation";
import DeleteAccountNotice from "./business/deleteAccount/DeleteAccountNotice";
import DeleteSuccess from "./business/deleteSuccess/DeleteSuccess";
import { replaceBakeryId, walletRefresh } from "./store/reducers/auth/actions";
import DeleteSubscriptionNotice from "./business/deleteSubscription/DeleteSubscriptionNotice";
import { Progress } from "./component/common/circularProgress/Progress";
import { useSelector } from "react-redux";
import { appStateLoading } from "./store/reducers/appState";
import BakeryInformation from "./business/bakeryInformation/BakeryInformation";
import CguCgvCv from "./business/cguCgv/CguCgvCv";

const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(", "),
  },
});

const AppHat = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route
              path="/"
              element={
                <ViewContainer>
                  <BakeryPage />
                </ViewContainer>
              }
            />
            <Route
              path="/accountInformation"
              element={
                <ViewContainer>
                  <AccountInformation />
                </ViewContainer>
              }
            />
            <Route
              path="/bakeryInformation"
              element={
                <ViewContainer>
                  <BakeryInformation />
                </ViewContainer>
              }
            />
            <Route
              path="/deleteAccount"
              element={
                <ViewContainer>
                  <DeleteAccountNotice />
                </ViewContainer>
              }
            />
            <Route
              path="/deleteSubscription"
              element={
                <ViewContainer>
                  <DeleteSubscriptionNotice />
                </ViewContainer>
              }
            />
            <Route
              path="/first-steps"
              element={
                <ViewContainer>
                  <FirstSteps />
                </ViewContainer>
              }
            />
            <Route
              path="/habits"
              element={
                <ViewContainer>
                  <Habits />
                </ViewContainer>
              }
            />
            <Route
              path="/category/:categoryName/:habit"
              element={
                <ViewContainer>
                  <CategoryDetail />
                </ViewContainer>
              }
            />
            <Route
              path="/product/create"
              element={
                <ViewContainer>
                  <ProductCreation />
                </ViewContainer>
              }
            />
            <Route
              path="/product/:id"
              element={
                <ViewContainer>
                  <ProductCreation />
                </ViewContainer>
              }
            />
            <Route
              path="/orders"
              element={
                <ViewContainer>
                  <BakerOrders />
                </ViewContainer>
              }
            />
            <Route
              path="/wallet"
              element={
                <ViewContainer>
                  <Wallet />
                </ViewContainer>
              }
            />
            <Route
              path="/payment-callback"
              element={
                <ViewContainer>
                  <PaymentCallback />
                </ViewContainer>
              }
            />
            <Route
              path="/cancel-callback"
              element={
                <ViewContainer>
                  <CancelCallback />
                </ViewContainer>
              }
            />
            <Route
              path="/calendar"
              element={
                <ViewContainer>
                  <Calendar />
                </ViewContainer>
              }
            />
            <Route
              path="/delivery/:code"
              element={
                <ViewContainer>
                  <Delivery />
                </ViewContainer>
              }
            />
            <Route
              path="/subscription"
              element={
                <ViewContainer>
                  <Subscription />
                </ViewContainer>
              }
            />
            <Route
              path="/profile"
              element={
                <ViewContainer>
                  <Profile />
                </ViewContainer>
              }
            />
            <Route
              path="/user/bakeryInformation"
              element={
                <ViewContainer>
                  <BakeryInfos />
                </ViewContainer>
              }
            />
            <Route
              path="/suiviCa"
              element={
                <ViewContainer>
                  <SuiviCaParent />
                </ViewContainer>
              }
            />
            <Route
              path="/suiviCa/tables"
              element={
                <ViewContainer>
                  <SuiviCa />
                </ViewContainer>
              }
            />
            {/* ========== */}
            {/* PAGE ADMIN */}
            {/* ========== */}
            <Route
              path="/admin"
              element={
                <AdminSection>
                  <Admin />
                </AdminSection>
              }
            />
            <Route
              path="/createBaker"
              element={
                <AdminSection>
                  <CreateBaker />
                </AdminSection>
              }
            />
            <Route
              path="/createDeliveryMan"
              element={
                <AdminSection>
                  <CreateDeliveryMan />
                </AdminSection>
              }
            />
            <Route
              path="/adminSuiviCa"
              element={
                <AdminSection>
                  <AdminSuiviCa />
                </AdminSection>
              }
            />
            <Route
              path="/adminUpdateBakery"
              element={
                <AdminSection>
                  <AdminUpdateZone />
                </AdminSection>
              }
            />
          </Route>
          {/* ========== */}
          {/* PAGE SANS AVOIR BESOIN D'ETRE CO */}
          {/* ========== */}
          <Route
            path="/verifyEmail"
            element={
              <ViewContainer>
                <VerifyEmail />
              </ViewContainer>
            }
          />
          <Route
            path="/confirmEmail/:id"
            element={
              <ViewContainer>
                <EmailConfirmation />
              </ViewContainer>
            }
          />
          <Route
            path="/forgotPassword"
            element={
              <ViewContainer>
                <ForgotPassword />
              </ViewContainer>
            }
          />
          <Route
            path="/resetPassword/:resetPasswordToken"
            element={
              <ViewContainer>
                <ResetPassword />
              </ViewContainer>
            }
          />
          <Route
            path="/login"
            element={
              <ViewContainer>
                <LoginPage />
              </ViewContainer>
            }
          />
          <Route
            path="/landing"
            element={
              <ViewContainer>
                <LandingPage />
              </ViewContainer>
            }
          />
          <Route
            path="/registration"
            element={
              <ViewContainer>
                <RegistrationPage />
              </ViewContainer>
            }
          />
          <Route
            path="/deletionSuccess"
            element={
              <ViewContainer>
                <DeleteSuccess />
              </ViewContainer>
            }
          />
          <Route
            path="/conditions"
            element={
              <ViewContainer>
                <CguCgvCv />
              </ViewContainer>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

const ViewContainer = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const loading = useSelector(appStateLoading);

  React.useEffect(() => {
    dispatch(replaceBakeryId());
    dispatch(walletRefresh());
  }, [dispatch]);

  return (
    <div className="AppContainer">
      {loading ? (
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 9999,
          }}
        >
          <Progress />
        </div>
      ) : null}
      <div
        className="App"
        style={
          window.location.pathname === "/landing" ? { height: "auto" } : {}
        }
      >
        {children}
      </div>
    </div>
  );
};

const App = () => <AppHat />;

export default App;
