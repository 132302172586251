import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { setFirstStepsDone } from "../../../store/reducers/auth";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import AddressSelection from "./addressSelection/AddressSelection";
import DeliveryDetailsInformations from "./deliveryDetailsInformations/DeliveryDetailsInformations";
import DeliveryInformations from "./deliveryInformations/DeliveryInformations";
import FailedPage from "./failed/FailedPage";
import { handleSteps } from "./handleSteps";
import SuccessPage from "./success/SuccessPage";
import {
  FlatAccess,
  MailBoxLocation,
  Steps,
  UserDeliveryInformations,
} from "./types";

const FirstSteps = () => {
  const dispatch = useAppDispatch();

  const formik = useFormik<UserDeliveryInformations>({
    initialValues: {
      isDeliverable: false,
      addressInformations: {
        address: "",
        zipCode: "",
        latAndLng: [],
      },
      bakeryId: "",
      isHouse: true,
      isMailBoxConform: true,
      hasDeliverySolution: true,
      deliverySolution: "",
      flatDeliveryInformation: {
        mailBoxLocation: MailBoxLocation.INSIDE,
        flatAccess: FlatAccess.CODE,
        accessCode: "",
      },
    },
    onSubmit: async (values) => {
      dispatch(setFirstStepsDone({ bakeryId: values.bakeryId }));
      const request = await axiosRequest({
        method: "patch",
        url: "user/finished-setup",
        data: values,
      });

      return request.status;
    },
  });
  const [color, setColor] = useState<string>("black");
  const [steps, setSteps] = useState<Steps>(Steps.ADDRESS);

  const handleSubmit = async () => {
    formik.setFieldValue("isDeliverable", true);
    const response = await formik.submitForm();
    if (response === 200) {
      setSteps(Steps.VALIDATION);
    } else {
      setSteps(Steps.NOT_POSSIBLE);
    }
  };

  const {
    handleFailure,
    handleBakeryConfirmationValidationStep,
    handleFlatDeliveryDetailsStep,
    handleFlatNextStep,
    handleHouseNextStep,
    handleReloadAddressStep,
  } = handleSteps({ formik, setSteps, setColor, handleSubmit });

  switch (steps) {
    case Steps.ADDRESS:
      return (
        <AddressSelection
          formik={formik}
          handleNextStep={handleBakeryConfirmationValidationStep}
          handleReloadStep={handleReloadAddressStep}
        />
      );
    case Steps.IS_HOUSE:
      return (
        <DeliveryInformations
          formik={formik}
          handleHouseNextStep={handleHouseNextStep}
          handleFlatNextStep={handleFlatNextStep}
          color={color}
          handlePreviousStep={() => setSteps(Steps.ADDRESS)}
        />
      );
    case Steps.DELIVERY_DETAILS:
      return (
        <DeliveryDetailsInformations
          formik={formik}
          handleNextStep={handleFlatDeliveryDetailsStep}
          handlePreviousStep={() => setSteps(Steps.IS_HOUSE)}
        />
      );
    case Steps.VALIDATION:
      return <SuccessPage />;
    case Steps.NOT_POSSIBLE:
      return <FailedPage handleGoBack={handleFailure} />;
    default:
      return (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
  }
};

export default FirstSteps;
