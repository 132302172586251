import * as yup from "yup";

const userLoginValidator = yup.object().shape({
  email: yup
    .string()
    .email("L'adresse mail doit être valide")
    .required("Ce champ est requis"),
  password: yup.string().required("Ce champ est requis"),
});

export default userLoginValidator;
