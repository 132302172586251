import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { Role } from "../../../store/reducers/auth/type";

export const AdminSection = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const userRole = useAppSelector((state) => state.auth.user.role);
  useEffect(() => {
    if (userRole !== Role.ADMIN) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  return <>{children}</>;
};
