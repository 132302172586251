import { FormikProps } from "formik";
import React from "react";
import Button from "../../../../component/common/button/Button";
import Switch from "../../../../component/common/switch/Switch";
import PageLayout from "../../components/pageLayout/PageLayout";
import { ReactComponent as Appartement } from "../../../../svg/appartement.svg";
import {
  FlatAccess,
  MailBoxLocation,
  UserDeliveryInformations,
} from "../types";
import styles from "./index.module.css";
import SwipeableTemporaryDrawer from "../../../../component/common/SwipeableTemporaryDrawer/SwipeableTemporaryDrawer";
import { useSwipeableTemporaryDrawer } from "../../../../component/common/SwipeableTemporaryDrawer/hook";
import ConformBox from "../conformBox/ConformBox";
import ConformMailBox from "../../components/conformMailbox/ConformMailBox";
import IsMailBoxConform from "../../components/isMailBoxConform/IsMailBoxConform";
import MailBoxLocationForm from "../../components/mailBoxLocationForm/MailBoxLocationForm";
import FlatAccessForm from "../../components/flatAccessForm/FlatAccessForm";

interface Props {
  formik: FormikProps<UserDeliveryInformations>;
  handleHouseNextStep: () => void;
  handleFlatNextStep: () => void;
  handlePreviousStep: () => void;
  color: string;
}

const DeliveryInformations = ({
  formik,
  handleHouseNextStep,
  handleFlatNextStep,
  handlePreviousStep,
  color,
}: Props) => {
  const { drawerOpen, toggleDrawer } = useSwipeableTemporaryDrawer();

  const isValid = () => {
    if (formik.values.isHouse) {
      if (!formik.values.isMailBoxConform) {
        return formik.values.hasDeliverySolution;
      } else {
        return true;
      }
    }
    return false;
  };

  const disableHouseButton = () => {
    if (!formik.values.isMailBoxConform && formik.values.hasDeliverySolution) {
      return !(formik.values.deliverySolution.trim().length > 0);
    } else {
      return false;
    }
  };

  const disableFlatButton = () => {
    if (
      formik.values.flatDeliveryInformation.mailBoxLocation ===
      MailBoxLocation.INSIDE
    ) {
      if (
        formik.values.flatDeliveryInformation.flatAccess === FlatAccess.CODE
      ) {
        return !(
          formik.values.flatDeliveryInformation.accessCode.trim().length > 0
        );
      }
    }
    return false;
  };

  const isButtonDisabled = () => {
    if (formik.values.isHouse) {
      return disableHouseButton();
    } else {
      return disableFlatButton();
    }
  };

  return (
    <PageLayout
      title={"Mes informations de livraison"}
      footer={
        <>
          <>
            <Button
              text={isValid() ? "Valider" : "Suivant"}
              onClick={
                formik.values.isHouse ? handleHouseNextStep : handleFlatNextStep
              }
              disabled={isButtonDisabled()}
              divClassName={styles.marginBottom}
            />
            <Button text={"Retour"} onClick={handlePreviousStep} outlined />
          </>
          <SwipeableTemporaryDrawer
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
          >
            <ConformBox toggleDrawer={toggleDrawer} />
          </SwipeableTemporaryDrawer>
        </>
      }
    >
      <div className={styles.mainContainer}>
        <div className={styles.isHouseSwitchContainer}>
          <div className={styles.helperTextContainer}>
            <Appartement className={styles.mailBox} />
            <div className={styles.helperTextMailBox}>
              Vous habitez {formik.values.isHouse ? "une..." : "un..."}
            </div>
          </div>
          <div className={styles.switchMaxHeight}>
            <Switch
              isOn={!formik.values.isHouse}
              handleToggle={() => {
                formik.setFieldValue("isHouse", !formik.values.isHouse);
              }}
              labels={{ left: "Maison", right: "Appartement" }}
            />
          </div>
        </div>
        {formik.values.isHouse ? (
          <>
            <ConformMailBox formik={formik} toggleDrawer={toggleDrawer} />
            <IsMailBoxConform formik={formik} color={color} />
          </>
        ) : (
          <>
            <MailBoxLocationForm formik={formik} />
            {formik.values.flatDeliveryInformation?.mailBoxLocation ===
            MailBoxLocation.INSIDE ? (
              <FlatAccessForm formik={formik} />
            ) : null}
          </>
        )}
      </div>
    </PageLayout>
  );
};

export default DeliveryInformations;
