import React from "react";
import CustomTextField from "../../component/common/customTextField/CustomTextField";
import { useAppSelector } from "../../store/hooks";
import styles from "./index.module.css";
import { ReactComponent as PointLeft } from "../../svg/pointLeftArrow.svg";
import { useNavigate } from "react-router-dom";

const AccountInformation = () => {
  const { email, firstName, lastName } = useAppSelector(
    (state) => state.auth.user
  );

  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <PointLeft onClick={() => navigate(-1)} />
        <br />
        <div className={styles.title}>Mes informations personnelles</div>
        <br />
        <div className={styles.textFieldWrapper}>
          <CustomTextField
            fullWidth
            id="firstName"
            name="firstName"
            label="Prénom"
            value={firstName}
            disabled
          />
        </div>
        <br />
        <div className={styles.textFieldWrapper}>
          <CustomTextField
            fullWidth
            id="lastName"
            name="lastName"
            label="Nom"
            value={lastName}
            disabled
          />
        </div>
        <br />
        <div className={styles.textFieldWrapper}>
          <CustomTextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={email}
            disabled
          />
        </div>
      </div>
      <div className={styles.deleteContainer}>
        <a
          href="/deleteAccount"
          className={styles.link}
          style={{ marginBottom: "0.75rem" }}
        >
          Supprimer mon compte
        </a>
        <a
          href="/conditions"
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          CGU / CGV / Mention légale
        </a>
      </div>
    </div>
  );
};

export default AccountInformation;
