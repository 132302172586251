import React from "react";
import styles from "./index.module.css";

interface Props {
  imageUrl: string;
  children: React.ReactNode;
}

const ImageSquare = ({ imageUrl, children }: Props) => {
  return (
    <div
      className={styles.image}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
      }}
    >
      {children}
    </div>
  );
};

export default ImageSquare;
