import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UnconnectedPage from "../../../component/common/unconnectedPage/UnconnectedPage";
import { useAppDispatch } from "../../../store/hooks";
import { Role, UserRegistration } from "../../../store/reducers/auth/type";
import { registerUser } from "../../../store/reducers/registration/actions";
import RegistrationForm, {
  UserRegistrationForm,
} from "../components/registrationForm/RegistrationForm";
import styles from "./index.module.css";

const RegistrationPage = () => {
  const initialValues: UserRegistrationForm = {
    passwordConfirmation: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    role: Role.USER,
  };
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | undefined>();

  const navigate = useNavigate();

  const onSubmit = async (values: UserRegistration) => {
    try {
      setError(undefined);
      await dispatch(registerUser(values)).unwrap();
      navigate("/verifyEmail");
    } catch (rejectedValueOrSerializedError) {
      setError(rejectedValueOrSerializedError as string);
    }
  };

  return (
    <UnconnectedPage>
      <div className={styles.registrationText}>Inscription</div>
      <RegistrationForm
        initialValues={initialValues}
        error={error}
        onSubmit={onSubmit}
      />
    </UnconnectedPage>
  );
};

export default RegistrationPage;
