import { InputAdornment } from "@mui/material";
import styles from "./index.module.css";
import React, { useState } from "react";
import { useFormik } from "formik";
import forgotPassValidator from "./validator";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Mail } from "../../../svg/mail.svg";
import Button from "../../../component/common/button/Button";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import CustomTextField from "../../../component/common/customTextField/CustomTextField";

interface IForgotPassword {
  email: string;
}

const ForgotPasswordForm = () => {
  const [error, setError] = useState<string | undefined>();
  const [status, setStatus] = useState<string | undefined>();
  const navigate = useNavigate();

  const resetPasswordEmail = async (email: string) => {
    const req = await axiosRequest<{ status: string; emailSent: boolean }>({
      method: "post",
      url: "auth/forgotPasswordMail",
      data: {
        email,
      },
    });

    if (!req.data.emailSent) {
      setError("Aucun compte n'est associé à ce mail");
      setStatus(undefined);
    } else {
      setError(undefined);
      setStatus(
        "Un mail de réinitialisation de mot de passe vous a été envoyé"
      );
    }
  };

  const formik = useFormik<IForgotPassword>({
    initialValues: {
      email: "",
    },
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: forgotPassValidator,
    onSubmit: async (values: IForgotPassword) => {
      try {
        await resetPasswordEmail(values.email);
      } catch (rejectedValueOrSerializedError) {
        setError(rejectedValueOrSerializedError as string);
      }
    },
  });

  return (
    <div className={styles.form}>
      <CustomTextField
        fullWidth
        id="email"
        name="email"
        label="Mail"
        type="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched.email && formik.errors.email)}
        required
        helperText={
          Boolean(formik.touched.email && formik.errors.email)
            ? formik.errors.email
            : null
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Mail />
            </InputAdornment>
          ),
          placeholder: "Entrez votre adresse mail",
        }}
      />

      {error ? <div style={{ color: "red" }}>{error}</div> : null}
      {status ? <div>{status}</div> : null}
      <div className={styles.buttonSection}>
        <Button text={"Envoyer"} onClick={formik.handleSubmit} />
        <Button text={"Retour"} onClick={() => navigate("/landing")} outlined />
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
