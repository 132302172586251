import React from "react";
import styles from "./index.module.css";
import BottomSection from "../../../../component/common/bottomSection/bottomSection";
import { ReactComponent as FailValidation } from "../../../../svg/fail.svg";
import { Tabs } from "../../../../store/reducers/navBar/type";
import PageColor from "../../../../component/common/pageColor/PageColor";
import classNames from "classnames";

const CancelPayment = () => {
  return (
    <PageColor>
      <div className={classNames(styles.title, styles.centerFlex)}>Mince !</div>
      <div className={classNames(styles.centerFlex, styles.container)}>
        <FailValidation className={styles.marginBottom} />
        <p className={styles.text}>Une erreur est survenue</p>
      </div>
      <BottomSection tab={Tabs.PROFILE} />
    </PageColor>
  );
};

export default CancelPayment;
