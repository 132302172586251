import React from "react";
import styles from "./index.module.css";
import { convertDateToString } from "../../../technical/date/convertDateToString";
import { ReactComponent as PointLeftArrow } from "../../../svg/pointLeftArrow.svg";
import { ReactComponent as PointRightArrow } from "../../../svg/pointRightArrow.svg";
import classNames from "classnames";
import { HabitsDays } from "../../../business/habits/page/useHabitsSelector";
import { convertWeekDayToString } from "../../../technical/date/convertWeekDayToString";

interface Props {
  date: Date | HabitsDays;
  dateMinusOne: () => void;
  datePlusOne: () => void;
  className?: string;
  isHabitDrawer?: boolean;
}

const DateComponent = ({
  date,
  dateMinusOne,
  datePlusOne,
  className,
  isHabitDrawer,
}: Props) => {
  return (
    <div className={classNames(styles.date)}>
      <div onClick={dateMinusOne} className={styles.marginLeft}>
        <PointLeftArrow />
      </div>
      <div className={className ?? styles.text}>
        {isHabitDrawer
          ? convertWeekDayToString(date as HabitsDays)
          : convertDateToString(date as Date)}
      </div>
      <div onClick={datePlusOne} className={styles.marginRight}>
        <PointRightArrow />
      </div>
    </div>
  );
};

export default DateComponent;
