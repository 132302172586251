import { FormikProps } from "formik";
import React, { useState } from "react";
import {
  UserDetails,
  UserRegistration,
} from "../../../../store/reducers/auth/type";
import styles from "./index.module.css";
import {
  Typography,
  Select,
  SelectChangeEvent,
  InputLabel,
} from "@mui/material";
import classNames from "classnames";
import { findCorrespondingError } from "../../../../technical/errors/findCorrespondingError";
import { BakerRegistrationValues } from "./type";
import PlacesAutocomplete from "../../../firstSteps/components/placesAutocomplete/PlacesAutocomplete";
import { capitalizeFirstLetter } from "../../../../technical/string/capitalize";
import Button from "../../../../component/common/button/Button";
import { axiosRequest } from "../../../../technical/api/axiosRequest";
import CustomTextField from "../../../../component/common/customTextField/CustomTextField";

export interface UserRegistrationForm extends UserRegistration {
  passwordConfirmation: string;
}

interface Props {
  setCenter: (coord: google.maps.LatLngLiteral) => void;
  formik: FormikProps<BakerRegistrationValues>;
  error?: string;
  success?: string;
}

const BakeryForm = ({ success, error, setCenter, formik }: Props) => {
  const [bakers, setBakers] = useState<UserDetails[]>([]);

  const refreshBakerList = async () => {
    const response = await axiosRequest<UserDetails[]>({
      method: "get",
      url: "user/bakers",
    });
    setBakers(response.data);
  };
  return (
    <div className={styles.registrationFormContainer}>
      <form onSubmit={formik.handleSubmit} className={styles.formContainer}>
        <div className={styles.textFieldWrapper}>
          <Button text={"Charger les boulangers"} onClick={refreshBakerList} />
          <InputLabel id="demo-simple-select-helper-label">
            Boulanger
          </InputLabel>
          <Select
            native
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={formik.values.userId}
            label="Boulanger"
            onChange={(e: SelectChangeEvent) =>
              formik.setFieldValue("userId", e.target.value)
            }
            fullWidth
            required
            className={styles.inputRounded}
          >
            <option>Veuillez sélectionner votre boulanger</option>
            {bakers.map((elt) => (
              <option key={elt.id} value={elt.id}>
                {capitalizeFirstLetter(elt.firstName) +
                  " " +
                  capitalizeFirstLetter(elt.lastName)}
              </option>
            ))}
          </Select>
        </div>
        <div className={styles.textFieldWrapper}>
          <CustomTextField
            fullWidth
            id="bakeryName"
            name="bakeryName"
            label="Nom de la boulangerie"
            value={formik.values.bakeryName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.bakeryName && formik.errors.bakeryName
            )}
            required
            helperText={
              Boolean(formik.touched.bakeryName && formik.errors.bakeryName)
                ? formik.errors.bakeryName
                : null
            }
            InputProps={{
              placeholder: "Nom de la boulangerie",
            }}
          />
        </div>
        <div className={styles.textFieldWrapper}>
          <CustomTextField
            fullWidth
            id="description"
            name="description"
            label="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.description && formik.errors.description
            )}
            required
            helperText={
              Boolean(formik.touched.description && formik.errors.description)
                ? formik.errors.description
                : null
            }
            InputProps={{
              placeholder: "Description boulangerie",
            }}
          />
        </div>
        <div className={styles.textFieldWrapper}>
          <CustomTextField
            fullWidth
            id="phoneNumber"
            name="phoneNumber"
            label="Numéro de téléphone"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.phoneNumber && formik.errors.phoneNumber
            )}
            required
            helperText={
              Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)
                ? formik.errors.phoneNumber
                : null
            }
            type="tel"
            InputProps={{
              placeholder: "Téléphone de la boulangerie",
            }}
          />
        </div>
        <div className={styles.textFieldWrapper}>
          <CustomTextField
            fullWidth
            id="deliveryCode"
            name="deliveryCode"
            label="Code de livraison"
            value={formik.values.deliveryCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.deliveryCode && formik.errors.deliveryCode
            )}
            required
            helperText={
              Boolean(formik.touched.deliveryCode && formik.errors.deliveryCode)
                ? formik.errors.deliveryCode
                : null
            }
            InputProps={{
              placeholder: "Code de livraison",
            }}
          />
        </div>

        <div className={styles.textFieldWrapper}>
          <PlacesAutocomplete
            onSelect={(coordinates: google.maps.LatLngAltitudeLiteral) => {
              formik.setFieldValue("addressInformations.latAndLng", [
                coordinates.lng,
                coordinates.lat,
              ]);
              setCenter(coordinates);
            }}
            formik={formik}
          />
        </div>
      </form>

      <div
        className={classNames(styles.textFieldWrapper, styles.buttonSection)}
      >
        {error ? (
          <Typography color={"error"} className={styles.errorSection}>
            {findCorrespondingError(error)}
          </Typography>
        ) : (
          <div className={styles.errorSection}></div>
        )}
        {success ? (
          <Typography className={styles.errorSection}>{success}</Typography>
        ) : (
          <div className={styles.errorSection}></div>
        )}
        <div>
          <Button
            text={"Créer boulangerie"}
            onClick={formik.submitForm}
            disabled={!formik.isValid}
          />
        </div>
      </div>
    </div>
  );
};

export default BakeryForm;
