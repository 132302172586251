import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "../../../component/common/button/Button";
import { Progress } from "../../../component/common/circularProgress/Progress";
import { useAppSelector } from "../../../store/hooks";
import { Role } from "../../../store/reducers/auth/type";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { Order } from "../../cart/OrderCart";
import DeliveryField from "../components/DeliveryField";
import PickupField from "../components/PickupField";
import styles from "./index.module.css";

type Location = [number, number];

interface DeliveryResponse {
  duration: number;
  start: { location: Location };
  pickup: { location: Location };
  deliveries: {
    id: string;
    firstName: number;
    lastName: number;
    phoneNumber: number;
    address: number;
    zipCode: number;
    isHouse: boolean;
    isMailBoxConform: boolean;
    deliverySolution: string;
    keyType?: string;
    flatDeliveryInformation?: {
      flatAccess: string;
      mailBoxLocation: string;
      accessCode: string;
    };
    location: Location;
    order: { order: Order };
  }[];
}

interface MapsLinkProps {
  location: [number, number];
  text: string;
}

const MapsLink = ({ location: [lon, lat], text }: MapsLinkProps) => (
  <a
    className={styles.link}
    href={`https://maps.google.com/?q=${lat},${lon}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
  </a>
);

const Delivery = () => {
  const userRole = useAppSelector((state) => state.auth.user.role);
  const { code } = useParams();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<DeliveryResponse>();
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const date = searchParams.get("date") ?? format(new Date(), "yyyy-MM-dd");

  useEffect(() => {
    async function getDeliveryData() {
      const { data, status } = await axiosRequest<DeliveryResponse>({
        url: `delivery/${code}?date=${date}`,
        method: "get",
      });
      if (status < 300) {
        setData(data);
      }
      setLoading(false);
    }

    getDeliveryData();
  }, [code, date]);

  if (userRole !== Role.DELIVERY_MAN) {
    return null;
  }
  if (loading) {
    return <Progress />;
  }
  if (!data) {
    return (
      <div className={styles.container}>
        <div style={{ width: "50%", margin: "0 auto" }}>
          <Button text={"retour"} onClick={() => navigate("/")} />
        </div>
        <div style={{ marginTop: "1.5rem" }}>Aucune livraison aujourd'hui</div>
      </div>
    );
  }

  const { duration, start, deliveries } = data;

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        <Button text={"retour"} onClick={() => navigate("/")} />
        <h4>{`Temps total: ${duration} secondes`}</h4>
        <div className={styles.links}>
          <MapsLink location={start.location} text="Boulangerie" />
          <PickupField
            products={deliveries.flatMap(({ order }) => order.order.products)}
          />
          {deliveries.map((delivery, index) => (
            <DeliveryField
              delivery={delivery}
              index={index}
              key={`${delivery.id}_${index}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Delivery;
