import { addDays, format, getDay, isFuture, isToday, subDays } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import BottomSection from "../../../component/common/bottomSection/bottomSection";
import PageColor from "../../../component/common/pageColor/PageColor";
import WalletContainer from "../../../component/common/wallet/WalletContainer";
import { Tabs } from "../../../store/reducers/navBar/type";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { getMonth } from "../../../technical/date/getMonth";
import { capitalizeFirstLetter } from "../../../technical/string/capitalize";
import { Order } from "../../cart/OrderCart";
import DayHabits from "../components/dayHabits/DayHabits";
import DayOrder from "../components/dayOrder/DayOrder";
import WeekStrip from "../components/weekStrip/WeekStrip";
import styles from "./index.module.css";
import { ReactComponent as DownArrow } from "../../../svg/downArrow.svg";
import { useSwipeableTemporaryDrawer } from "../../../component/common/SwipeableTemporaryDrawer/hook";
import SwipeableTemporaryDrawer from "../../../component/common/SwipeableTemporaryDrawer/SwipeableTemporaryDrawer";
import RCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./customCalendar.css";
import { compareDates } from "../../../technical/date/compareDate";
import classNames from "classnames";
import { useAppSelector } from "../../../store/hooks";
import { useDispatch } from "react-redux";
import {
  activateLoading,
  deactivateLoading,
} from "../../../store/reducers/appState";

const getMonday = (date: Date) => {
  let day: number = getDay(date);
  if (day === 0) {
    day = 7;
  }
  const monday: Date = subDays(date, day - 1);

  return monday;
};

const Calendar = () => {
  const [date, setDate] = useState<Date>(addDays(new Date(), 1));
  const [orders, setOrders] = useState<Order[]>([]);
  const [monthOrders, setMonthOrders] = useState<Order[]>([]);
  const dispatch = useDispatch();

  const { walletAmount } = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    async function fetchWeekOrders() {
      dispatch(activateLoading());
      const monday = getMonday(date);

      const resp = await axiosRequest<Order[]>({
        method: "get",
        url: `order/week/${format(monday, "yyyy-MM-dd")}`,
      });

      if (resp.status < 300) {
        setOrders(resp.data);
      }
      dispatch(deactivateLoading());
    }

    fetchWeekOrders();
  }, [date, walletAmount]);

  useMemo(() => {
    async function fetchMonthOrders() {
      const resp = await axiosRequest<Order[]>({
        method: "get",
        url: `order/month/${format(date, "yyyy-MM-dd")}`,
      });
      if (resp.status < 300) {
        setMonthOrders(resp.data);
      }
    }

    fetchMonthOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.getMonth(), walletAmount]);

  const { drawerOpen, toggleDrawer } = useSwipeableTemporaryDrawer();
  return (
    <>
      <PageColor className={styles.mainContainer}>
        <div className={styles.walletContainer}>
          <div className={styles.monthContainer} onClick={toggleDrawer(true)}>
            <div className={styles.monthText}>
              {capitalizeFirstLetter(getMonth(date))}
            </div>
            <DownArrow style={{ marginLeft: "7px" }} />
          </div>
          <WalletContainer alternatif />
        </div>
        <WeekStrip date={date} setDate={setDate} orders={orders} />
        <div>
          <DayOrder date={date} orders={orders} />
          {isFuture(date) || isToday(date) ? <DayHabits date={date} /> : null}
        </div>
      </PageColor>

      <SwipeableTemporaryDrawer
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
      >
        <div className={styles.calendarContainer}>
          <RCalendar
            onClickDay={(value) => setDate(value)}
            locale={"fr"}
            value={date}
            minDetail={"month"}
            next2Label={null}
            prev2Label={null}
            onActiveStartDateChange={({ activeStartDate }) =>
              setDate(activeStartDate)
            }
            tileContent={(prop) => {
              if (
                monthOrders.some((value) =>
                  compareDates(new Date(value.deliveryDate), prop.date)
                )
              ) {
                return (
                  <div className={classNames(styles.dot, styles.smallDot)} />
                );
              }
              return (
                <div className={classNames(styles.dot, styles.noSmallDot)} />
              );
            }}
          />
          <div style={{ fontWeight: 500, textAlign: "center" }}>
            Vous pouvez visualiser vos commandes en cliquant sur une date
          </div>
        </div>
      </SwipeableTemporaryDrawer>
      <BottomSection tab={Tabs.CALENDAR} />
    </>
  );
};

export default Calendar;
