import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { axiosRequest } from "../../../../technical/api/axiosRequest";
import { addZero } from "../../../../technical/string/addZero";
import { Product } from "../../../cart/OrderCart";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Chiffre from "../chiffre/Chiffre";
import { Role } from "../../../../store/reducers/auth/type";
import { useAppSelector } from "../../../../store/hooks";

interface MonthQueryResponse {
  id: string;
  orders: { products: Product[]; deliveryDate: Date }[];
  user: string;
}

function Row(props: { row: MonthQueryResponse; collapsibleClassName: string }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const price = row.orders.reduce(
    (acc, cur) =>
      acc + cur.products.reduce((accu, current) => accu + current.price, 0),
    0
  );
  const totalOrder = row.orders.reduce(
    (acc, cur) => acc + cur.products.length,
    0
  );

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell size="small">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.user}
        </TableCell>
        <TableCell align="right">{totalOrder}</TableCell>
        <TableCell align="right">
          {addZero((price / 100).toString())}€
        </TableCell>
      </TableRow>
      <TableRow className={props.collapsibleClassName}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
          className={props.collapsibleClassName}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Historique du mois
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Quantité</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.orders.map((order, index) => (
                    <TableRow key={row.id + index.toString()}>
                      <TableCell component="th" scope="row">
                        {format(new Date(order.deliveryDate), "dd/MM/yyyy")}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {order.products.length}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {addZero(
                          (
                            order.products.reduce(
                              (acc, cur) => acc + cur.price,
                              0
                            ) / 100
                          ).toString()
                        )}
                        €
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const ClientOrderTable = ({
  bakeryId,
  collapsibleClassName,
  date,
}: {
  bakeryId: string | null;
  collapsibleClassName: string;
  date: Date;
}) => {
  const formatedDate = format(date, "yyyy-MM-dd");
  const [orders, setOrders] = useState<MonthQueryResponse[]>([]);
  const userRole = useAppSelector((state) => state.auth.user.role);
  const [caDuMois, setCaDuMois] = useState<number>(0);

  useEffect(() => {
    async function getMonthOrders() {
      const resp = await axiosRequest<MonthQueryResponse[]>({
        method: "get",
        url: `order/bakery/${bakeryId}/${formatedDate}`,
      });

      setOrders(resp.data);
      const orders = resp.data.map((elt) => elt.orders);

      const totalOfEach = orders.map((order) =>
        order.reduce(
          (acc, o) => acc + o.products.reduce((acc, cur) => acc + cur.price, 0),
          0
        )
      );

      setCaDuMois(totalOfEach.reduce((acc, cur) => acc + cur, 0));
    }

    getMonthOrders();
  }, [formatedDate, bakeryId]);

  return (
    <>
      {userRole === Role.ADMIN ? (
        <div style={{ margin: "0.75rem 0" }}>
          <Chiffre nb={caDuMois} caption={"CA du mois"} price />
        </div>
      ) : null}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Nom</TableCell>
              <TableCell align="right">Commandes</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row, index) => (
              <Row
                key={row.id}
                row={row}
                collapsibleClassName={collapsibleClassName}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ClientOrderTable;
