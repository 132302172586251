import React from "react";
import BakerCreationForm from "../components/bakerCreationForm/BakerCreationForm";
import styles from "./index.module.css";

const CreateBaker = () => {
  return (
    <div className={styles.bakerCreationSection}>
      <BakerCreationForm />
    </div>
  );
};

export default CreateBaker;
