import { InputAdornment, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../store/hooks";
import { loginUser } from "../../../../store/reducers/auth/actions";
import { UserLogin } from "../../../../store/reducers/auth/type";
import userLoginValidator from "../../pages/validator";
import { ReactComponent as Lock } from "../../../../svg/lock.svg";
import { ReactComponent as Mail } from "../../../../svg/mail.svg";
import styles from "./index.module.css";
import classNames from "classnames";
import { findCorrespondingError } from "../../../../technical/errors/findCorrespondingError";
import Button from "../../../../component/common/button/Button";
import CustomTextField from "../../../../component/common/customTextField/CustomTextField";

interface Props {
  initialValues: UserLogin;
  className?: string;
}

const LoginForm = ({ initialValues, className }: Props) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | undefined>();

  const navigate = useNavigate();

  const formik = useFormik<UserLogin>({
    initialValues: initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: userLoginValidator,
    onSubmit: async (values: UserLogin) => {
      try {
        setError(undefined);
        await dispatch(loginUser(values)).unwrap();
        navigate("/");
      } catch (rejectedValueOrSerializedError) {
        setError(rejectedValueOrSerializedError as string);
      }
    },
  });

  const isDisabled =
    Object.values(formik.values).some((elt) => elt === "") ||
    (formik.dirty && !formik.isValid);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={classNames(styles.formContainer, className)}
    >
      <div className={styles.textBoxContainer}>
        <div className={styles.textFieldWrapper}>
          <CustomTextField
            fullWidth
            id="email"
            name="email"
            label="Mail"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.email && formik.errors.email)}
            required
            helperText={
              Boolean(formik.touched.email && formik.errors.email)
                ? formik.errors.email
                : null
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Mail />
                </InputAdornment>
              ),
              placeholder: "Entrez votre adresse mail",
            }}
          />
        </div>
        <div className={styles.textFieldWrapper}>
          <CustomTextField
            fullWidth
            id="password"
            name="password"
            label="Mot de passe"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.password && formik.errors.password)}
            required
            helperText={
              Boolean(formik.touched.password && formik.errors.password)
                ? formik.errors.password
                : null
            }
            type="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              placeholder: "Entrez votre mot de passe",
            }}
          />
        </div>
      </div>

      <div
        className={classNames(styles.textFieldWrapper, styles.buttonSection)}
      >
        {error ? (
          <Typography
            color={"error"}
            className={styles.errorSection}
            sx={{ fontSize: 14, marginBottom: 2 }}
          >
            {findCorrespondingError(error)}
          </Typography>
        ) : null}
        <div className={styles.baseButton}>
          <a href="/forgotPassword" className={styles.forgotPass}>
            Mot de passe oublié
          </a>
        </div>
        <div className={styles.baseButton}>
          <Button
            disabled={isDisabled}
            onClick={formik.submitForm}
            text={"Se connecter"}
          />
        </div>
        <div className={styles.baseButton}>
          <Button
            onClick={() => navigate("/registration")}
            text={"Créer un compte"}
            outlined
          />
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
