import { FormikProps } from "formik";
import { axiosRequest } from "../../../../technical/api/axiosRequest";
import { IProduct } from "../../../categoryDetail/components/product/product";

export async function submitNewProduct(
  formik: FormikProps<IProduct>,
  values: IProduct,
  price: number,
  bakeryId: string,
  successCallback: () => void,
  errorCallback: () => void
) {
  const res = await axiosRequest({
    url: "product",
    data: { ...values, price: price * 100, bakeryId },
    method: "post",
  });

  if (res.status === 201) {
    successCallback();
    formik.resetForm();
  } else {
    errorCallback();
  }
}

export async function updateProduct(
  formik: FormikProps<IProduct>,
  values: IProduct,
  price: number,
  bakeryId: string,
  successCallback: () => void,
  errorCallback: () => void
) {
  const res = await axiosRequest({
    url: `product/${formik.initialValues.id}`,
    data: { ...values, price: price * 100, bakeryId },
    method: "patch",
  });

  if (res.status === 200) {
    successCallback();
    formik.resetForm();
  } else {
    errorCallback();
  }
}
