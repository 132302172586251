import React, { useMemo, useState, useEffect } from "react";
import Button from "../../component/common/button/Button";
import ProductLine from "../../component/common/productLine/ProductLine";
import TotalLine from "../../component/common/totalLine/TotalLine";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  changeHabitCart,
  submitHabitCart,
} from "../../store/reducers/habitCart/action";
import { axiosRequest } from "../../technical/api/axiosRequest";
import { addZero } from "../../technical/string/addZero";
import { HabitsDays } from "../habits/page/useHabitsSelector";
import styles from "./index.module.css";

interface Props {
  day: HabitsDays;
}

function dayToWeek(day: number) {
  switch (day) {
    case 0:
      return "dimanche";
    case 1:
      return "lundi";
    case 2:
      return "mardi";
    case 3:
      return "mercredi";
    case 4:
      return "jeudi";
    case 5:
      return "vendredi";
    case 6:
      return "samedi";
  }
}

const HabitsCart = ({ day }: Props) => {
  const contentMap = new Map<string, { quantity: number; price: number }>();
  const bakeryId = useAppSelector((state) => state.auth.user.bakeryId);
  const [successText, setSuccessText] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [cartContent, setCartContent] = useState<
    [string, { quantity: number; price: number }][]
  >([]);
  const habitCartContent = useAppSelector(
    (state) => state.habitCart.cart[state.habitCart.activeDay].content
  );
  const cartHasUnsavedChanges = useAppSelector(
    (state) => state.habitCart.cart[state.habitCart.activeDay].hasUnsavedChanges
  );

  const [closingDays, setClosingDays] = useState<number[]>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);

  const isBakeryClosed = closingDays.includes(day);

  const dispatch = useAppDispatch();

  useMemo(() => {
    const getClosingDays = async () => {
      const { status, data } = await axiosRequest<number[]>({
        url: `bakery/closingDays/${bakeryId}`,
        method: "get",
      });

      if (status === 200) {
        setClosingDays(data);
      }
    };
    getClosingDays();
  }, [bakeryId]);

  useEffect(() => {
    dispatch(changeHabitCart({ day }));
    setSuccessText(undefined);
    setError(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day]);

  const handleSubmit = async () => {
    try {
      dispatch(
        submitHabitCart({ products: habitCartContent, day, status: "ONGOING" })
      );
      setSuccessText("Habitude enregistrée !");
      setError(undefined);
    } catch (er) {
      setError("Une erreur est survenue");
      setSuccessText(undefined);
    }
  };

  useMemo(() => {
    setTotalAmount(habitCartContent.reduce((acc, curr) => acc + curr.price, 0));
    habitCartContent.forEach((elt) => {
      const { name, price } = elt;
      contentMap.set(name, {
        quantity: (contentMap.get(name)?.quantity ?? 0) + 1,
        price,
      });
    });
    setCartContent(Array.from(contentMap));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [habitCartContent]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.title}>Mon Panier</div>
      <>
        {cartContent.map(([key, value]) => {
          return (
            <ProductLine
              key={key}
              name={key}
              price={addZero(((value.quantity * value.price) / 100).toString())}
              quantity={value.quantity}
            />
          );
        })}
      </>
      <TotalLine amount={totalAmount} />
      {cartHasUnsavedChanges && (!successText || !error) ? (
        <div style={{ marginBottom: "0.75rem" }}>
          En cliquant <b>définir</b>, les articles ci-dessus seront commandés et
          réglés automatiquement chaque <b>{dayToWeek(day)}</b>
        </div>
      ) : null}
      {isBakeryClosed ? (
        <div style={{ marginBottom: "0.75rem" }}>
          Votre boulangerie est fermée ce jour-ci
        </div>
      ) : null}
      <div style={{ padding: "0 50px" }}>
        <Button
          onClick={handleSubmit}
          text="Définir"
          disabled={isBakeryClosed}
        />
      </div>
      {successText ? (
        <div style={{ padding: "0 50px", marginTop: "0.75rem" }}>
          {successText}
        </div>
      ) : null}
    </div>
  );
};

export default HabitsCart;
