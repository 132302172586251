import React from "react";
import ProductLine from "../../../../component/common/productLine/ProductLine";
import { addZero } from "../../../../technical/string/addZero";
import { Product } from "../../../cart/OrderCart";

interface Props {
  content: [string, number][];
  products: Product[];
  date?: Date;
  isHabit?: boolean;
}

const DisplayProductLines = ({ content, products, date, isHabit }: Props) => {
  return (
    <div>
      {content.map(([key, value]) => {
        const id = products.find((prod) => prod.name === key)!.id;
        const price = addZero(
          (
            (value * products.find((elt) => elt.name === key)!.price) /
            100
          ).toString()
        );
        return (
          <ProductLine
            key={id}
            name={key}
            price={price}
            quantity={value}
            date={date}
            id={id}
            isHabit={isHabit}
          />
        );
      })}
    </div>
  );
};

export default DisplayProductLines;
