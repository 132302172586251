import React from "react";
import classNames from "classnames";
import { format, getDay, isEqual, isFuture, isToday } from "date-fns";
import { fr } from "date-fns/locale";
import styles from "./index.module.css";
import { getDateAsDay } from "../../../../technical/date/getDateAsDay";
import { dateHasOrder } from "./helper";
import { Order } from "../../../cart/OrderCart";
import { useAppSelector } from "../../../../store/hooks";

interface Props {
  day: Date;
  date: Date;
  setDate: (day: Date) => void;
  orders: Order[];
}

const Day = ({ day, setDate, date, orders }: Props) => {
  const hasHabit = useAppSelector((state) => {
    return state.habitCart.cart[getDay(day)].content.length !== 0;
  });

  return (
    <div onClick={() => setDate(day)} className={styles.dayContainer}>
      <p className={styles.weekDay}>{format(day, "EEEEE", { locale: fr })}</p>
      <p
        className={classNames(
          styles.day,
          styles.dayFont,
          isEqual(getDateAsDay(day), getDateAsDay(new Date())) &&
            !isEqual(getDateAsDay(day), getDateAsDay(date))
            ? styles.currentDay
            : {},
          isEqual(getDateAsDay(day), getDateAsDay(date))
            ? styles.selectedDay
            : {}
        )}
      >
        {format(day, "d")}
      </p>
      {dateHasOrder(day, orders) || (hasHabit && isFuture(day)) ? (
        <div
          className={classNames(
            styles.smallDot,
            isFuture(day) || isToday(day) ? styles.orange : styles.grey
          )}
        />
      ) : (
        <div className={styles.noDot} />
      )}
    </div>
  );
};

export default Day;
