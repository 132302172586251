import React from "react";
import CGU from "./CGU";
import CGV from "./CGV";
import styles from "./index.module.css";
import MentionLegale from "./MentionLegale";

const CguCgvCv = () => {
  return (
    <div className={styles.main}>
      <CGU />
      <CGV />
      <MentionLegale />
    </div>
  );
};

export default CguCgvCv;
