import { CircularProgress } from "@mui/material";
import React from "react";
import styles from "./index.module.css";

export const Progress = () => {
  return (
    <div className={styles.loading}>
      <CircularProgress style={{ color: "#ea974a" }} />
    </div>
  );
};
