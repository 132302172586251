import React, { useState } from "react";
import styles from "./index.module.css";
import { ReactComponent as FemmeCarton } from "../../svg/femmeCarton.svg";
import Button from "../../component/common/button/Button";
import { axiosRequest } from "../../technical/api/axiosRequest";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  activateLoading,
  deactivateLoading,
} from "../../store/reducers/appState";

const deleteAccount = async () => {
  const { data } = await axiosRequest<{ success: boolean }>({
    method: "delete",
    url: "user/remove",
  });

  return data.success;
};

const DeleteAccountNotice = () => {
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDeleteClick = async () => {
    dispatch(activateLoading());
    const deletionComplete = await deleteAccount();
    if (!deletionComplete) {
      setError(true);
    } else {
      setError(false);
      navigate("/deletionSuccess");
    }
    dispatch(deactivateLoading());
  };

  return (
    <div className={styles.container}>
      <div className={styles.mainSection}>
        <FemmeCarton />
      </div>
      <div className={styles.textDeleteSection}>
        <div className={styles.title}>Êtes-vous sûr ?</div>
        <br />
        <div className={styles.text}>
          <b>Attention</b>, la suppression de votre compte entraînera la perte
          définitive de vos l’ensemble de vos données.
        </div>
        <br />
        <div className={styles.text}>Souhaitez-vous continuer ?</div>
      </div>
      <div className={styles.buttonSection}>
        {error ? (
          <div style={{ color: "red" }}>
            Une erreur est survenue, veuillez réessayer
          </div>
        ) : null}
        <Button text={"Supprimer"} onClick={handleDeleteClick} />
        <Button text={"Retour"} onClick={() => navigate(-1)} outlined />
      </div>
    </div>
  );
};

export default DeleteAccountNotice;
