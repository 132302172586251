// @ts-nocheck

import React, { ReactNode, useEffect } from "react";
import { useGesture } from "@use-gesture/react";
import { animated, SpringRef, SpringValue } from "@react-spring/web";
import styles from "./index.module.css";
import { ReactComponent as Notch } from "../../svg/notch.svg";
import HorizontalDivider from "../common/horizontalDivider/horizontalDivider";

interface Props {
  style: {
    x: SpringValue<number>;
    y: SpringValue<number>;
    scale: SpringValue<number>;
    rotateZ: SpringValue<number>;
  };
  api: SpringRef<{
    x: number;
    y: number;
    scale: number;
    rotateZ: number;
  }>;
  expandedY: number;
  collapsedY: number;
  close: (vy?: number) => void;
  children?: ReactNode;
}

const ZeroDrawer: React.FC<Props> = ({
  children,
  style,
  api,
  expandedY,
  close,
}: Props) => {
  useEffect(() => {
    const handler = (e: Event) => e.preventDefault();
    document.addEventListener("gesturestart", handler);
    document.addEventListener("gesturechange", handler);
    document.addEventListener("gestureend", handler);
    return () => {
      document.removeEventListener("gesturestart", handler);
      document.removeEventListener("gesturechange", handler);
      document.removeEventListener("gestureend", handler);
    };
  }, []);

  const ref = React.useRef(null);

  useGesture(
    {
      onDrag: ({
        last,
        velocity: [, vy],
        direction: [, dy],
        offset: [, y],
        movement: [, my],
        tap,
      }) => {
        if (!tap) {
          api.start({ y, immediate: false });
          if (last) {
            vy > 0.5 && dy > 0.5
              ? close(vy)
              : api.start({ y: expandedY, immediate: false });
          }
        }
      },
    },
    {
      target: ref,
      drag: {
        from: () => [style.x.get(), style.y.get()],
        filterTaps: true,
        bounds: { top: expandedY },
        rubberband: true,
      },
    }
  );

  return (
    <animated.div className={styles.card} ref={ref} style={style}>
      <Notch style={{ marginTop: 10 }} />
      <div className={styles.container}>Calendrier</div>
      <HorizontalDivider style={{ marginBottom: "5%" }} />
      {children}
    </animated.div>
  );
};

export default ZeroDrawer;
