import React from "react";
import { HabitsDays } from "../habits/page/useHabitsSelector";
import HabitsCart from "./HabitsCart";
import OrderCart from "./OrderCart";

interface Props {
  day?: HabitsDays;
  isHabitCart?: boolean;
}

const Cart = ({ day, isHabitCart }: Props) => {
  if (!isHabitCart) {
    return <OrderCart />;
  } else {
    return <HabitsCart day={day!} />;
  }
};

export default Cart;
