import { getDay } from "date-fns";
import React, { useMemo, useState } from "react";
import Button from "../../component/common/button/Button";
import ProductLine from "../../component/common/productLine/ProductLine";
import TotalLine from "../../component/common/totalLine/TotalLine";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { subFromWallet } from "../../store/reducers/auth";
import { deleteCart, setSelectedDay } from "../../store/reducers/cart";
import { axiosRequest } from "../../technical/api/axiosRequest";
import { addZero } from "../../technical/string/addZero";
import { Category } from "../bakery/components/categoryCard/categoryCard";
import styles from "./index.module.css";
import RCalendar from "react-calendar";
import { AxiosError } from "axios";
import { findCorrespondingError } from "../../technical/errors/findCorrespondingError";
import {
  activateLoading,
  deactivateLoading,
} from "../../store/reducers/appState";

export interface Product {
  id: string;
  name: string;
  description?: string;
  price: number;
  category: Category;
}

export interface Order {
  _id?: string;
  userId: string;
  products: Product[];
  orderDate: string;
  deliveryDate: string;
}
function toLocaleISOString(date: any) {
  var tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = new Date(date - tzoffset).toISOString().slice(0, -1);
  return localISOTime;
}

const OrderCart = () => {
  const dateEpoch = useAppSelector((state) => state.cart.selectedDay);
  const amount = useAppSelector((state) => state.cart.totalAmount);
  const userId = useAppSelector((state) => state.auth.user.id);
  const bakeryId = useAppSelector((state) => state.auth.user.bakeryId);
  const cartContent = useAppSelector((state) => state.cart.content);
  const [closingDays, setClosingDays] = useState<number[]>([]);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const isBakeryClosed = closingDays.includes(getDay(dateEpoch));

  const dispatch = useAppDispatch();

  const contentMap = new Map<string, number>();

  if (cartContent) {
    cartContent.forEach((elt) => {
      const { name } = elt;
      contentMap.set(name, (contentMap.get(name) ?? 0) + 1);
    });
  }

  const contentArr = Array.from(contentMap);

  useMemo(() => {
    const getClosingDays = async () => {
      const { status, data } = await axiosRequest<number[]>({
        url: `bakery/closingDays/${bakeryId}`,
        method: "get",
      });

      if (status === 200) {
        setClosingDays(data);
      }
    };
    getClosingDays();
  }, [bakeryId]);

  const handleSubmit = async () => {
    dispatch(activateLoading());
    const order: Order = {
      userId,
      products: cartContent,
      orderDate: toLocaleISOString(new Date()),
      deliveryDate: toLocaleISOString(new Date(dateEpoch)),
    };

    const amount = order.products.reduce((acc, elt) => {
      acc += elt.price;
      return acc;
    }, 0);

    if (cartContent.length > 0) {
      try {
        const resp = await axiosRequest({
          url: "order",
          data: order,
          method: "post",
        });
        if (resp.status === 201) {
          dispatch(deleteCart());
          dispatch(subFromWallet(amount));
          setIsSaved(true);

          setTimeout(() => {
            setIsSaved(false);
          }, 5000);
        }
      } catch (err) {
        const errorMessage = (
          (err as AxiosError).response?.data as { message: string }
        )["message"];

        setError(findCorrespondingError(errorMessage));

        setTimeout(() => {
          setError(undefined);
        }, 5000);
      }
    }
    dispatch(deactivateLoading());
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.title}>Mon Panier</div>
      <>
        {contentArr.map(([key, value]) => {
          return (
            <ProductLine
              key={key}
              name={key}
              quantity={value}
              price={addZero(
                (
                  (value * cartContent.find((elt) => elt.name === key)!.price) /
                  100
                ).toString()
              )}
            />
          );
        })}
      </>
      <TotalLine amount={amount} />
      {isBakeryClosed ? (
        <div style={{ marginBottom: "0.75rem" }}>
          Votre boulangerie est fermée ce jour-ci
        </div>
      ) : null}
      {isSaved ? (
        <div style={{ marginBottom: "0.75rem", color: "green" }}>
          Commande enregistrée !
        </div>
      ) : null}
      {error ? (
        <div style={{ marginBottom: "0.75rem", color: "red" }}>{error}</div>
      ) : null}
      <div className={styles.buttonSection}>
        <Button
          buttonStyle={{ height: "33%" }}
          onClick={handleSubmit}
          text="Commander"
          disabled={isBakeryClosed}
        />
        <Button
          buttonStyle={{ height: "33%" }}
          outlined
          onClick={() => setOpenCalendar(!openCalendar)}
          text={openCalendar ? "Fermer" : "Choisir un autre jour"}
        />
      </div>

      {openCalendar ? (
        <div className={styles.calendarContainer}>
          <RCalendar
            onClickDay={(value) =>
              dispatch(setSelectedDay({ day: value.getTime() }))
            }
            locale={"fr"}
            value={new Date(dateEpoch)}
            minDetail={"month"}
            next2Label={null}
            prev2Label={null}
            tileDisabled={({ date }) => date < new Date()}
          />
        </div>
      ) : null}
    </div>
  );
};

export default OrderCart;
