import React from "react";
import styles from "./index.module.css";
import { ReactComponent as SvgIcon } from "../../svg/bonhomme.svg";

const VerifyEmail = () => {
  return (
    <div className={styles.container}>
      <div className={styles.mainSection}>
        <SvgIcon />
      </div>
      <div className={styles.textSection}>
        <div className={styles.title}>Vérifiez vos mails</div>
        <br />
        <div className={styles.text}>
          Pour activer votre compte, veuillez appuyez sur le bouton dans le mail
          que nous vous avons envoyé.
        </div>
      </div>
      <div className={styles.connectSection}>
        <div className={styles.alreadyHaveAccountText}>
          Vous avez déjà un compte ?
        </div>
        <a className={styles.link} href="/login">
          Se connecter
        </a>
      </div>
    </div>
  );
};

export default VerifyEmail;
