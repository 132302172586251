import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs } from "../../../store/reducers/navBar/type";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { updateTab } from "../../../store/reducers/navBar";
import UserNavBar from "./components/userNavBar/UserNavBar";

const NavBar = ({ tab }: { tab?: Tabs }) => {
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector((state) => state.navBar.tab);
  const userRole = useAppSelector((state) => state.auth.user.role);

  const navigate = useNavigate();

  const handleClick = (tabFromClick: Tabs) => {
    dispatch(updateTab({ tab: tabFromClick }));
    navigate(tabFromClick);
  };

  useEffect(() => {
    if (tab) {
      dispatch(updateTab({ tab }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <UserNavBar
      handleClick={handleClick}
      activeTab={activeTab}
      userRole={userRole}
    />
  );
};

export default NavBar;
