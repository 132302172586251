import { FormikProps } from "formik";
import React from "react";
import BakeryForm from "./bakeryForm";
import { BakerRegistrationValues } from "./type";

interface Props {
  setCenter: (coord: google.maps.LatLngLiteral) => void;
  formik: FormikProps<BakerRegistrationValues>;
  error?: string;
  success?: string;
}

const BakeryCreationForm = ({ setCenter, formik, error, success }: Props) => {
  return (
    <>
      <BakeryForm
        formik={formik}
        setCenter={setCenter}
        error={error}
        success={success}
      />
    </>
  );
};

export default BakeryCreationForm;
