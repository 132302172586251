import { addDays, isTomorrow, subDays } from "date-fns";
import React from "react";
import Cart from "../../../business/cart/cart";
import { HabitsDays } from "../../../business/habits/page/useHabitsSelector";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setSelectedDay } from "../../../store/reducers/cart";
import { Tabs } from "../../../store/reducers/navBar/type";
import Drawer from "../../drawer";
import NavBar from "../navBar/NavBar";
import styles from "./index.module.css";

interface Props {
  hasDrawer?: boolean;
  isHabitDrawer?: boolean;
  modifyButton?: React.ReactNode;
  customDrawer?: React.ReactNode;
  tab?: Tabs;
  dateMinusOneHabits?: () => void;
  datePlusOneHabits?: () => void;
  dateHabits?: HabitsDays;
}

const BottomSection = ({
  hasDrawer,
  isHabitDrawer,
  modifyButton,
  customDrawer,
  tab,
  datePlusOneHabits,
  dateMinusOneHabits,
  dateHabits,
}: Props) => {
  const dispatch = useAppDispatch();
  const date = new Date(useAppSelector((state: any) => state.cart.selectedDay));

  function dateMinusOne() {
    if (isTomorrow(date)) {
      return date;
    }

    dispatch(setSelectedDay({ day: subDays(date, 1).getTime() }));
  }

  const datePlusOne = () => {
    dispatch(setSelectedDay({ day: addDays(date, 1).getTime() }));
  };

  return (
    <>
      <div className={styles.bottomBar}>
        <NavBar tab={tab} />
      </div>

      {hasDrawer ? (
        <div className={styles.drawerContainer}>
          <Drawer
            isHabitDrawer={isHabitDrawer}
            date={isHabitDrawer ? dateHabits : date}
            dateMinusOne={isHabitDrawer ? dateMinusOneHabits : dateMinusOne}
            datePlusOne={isHabitDrawer ? datePlusOneHabits : datePlusOne}
          >
            <Cart day={dateHabits} isHabitCart={isHabitDrawer} />
          </Drawer>
        </div>
      ) : null}

      {customDrawer ? (
        <div className={styles.drawerContainer}>{customDrawer}</div>
      ) : null}

      {modifyButton ? (
        <div className={styles.drawerContainer}>{modifyButton}</div>
      ) : null}
    </>
  );
};

export default BottomSection;
