export enum MailBoxLocation {
  INSIDE = "Dans mon immeuble",
  OUTSIDE = "A l’extérieur de l’immeuble",
}

export enum FlatAccess {
  CODE = "Oui, avec le code",
  OPEN = "Oui, l’immeuble est ouvert",
  IMPOSSIBLE = "Non, impossible",
}

export interface UserDeliveryInformations {
  isDeliverable: boolean;
  addressInformations: AddressInformations;
  bakeryId: string;
  isHouse: boolean;
  isMailBoxConform: boolean;
  deliverySolution: string;
  hasDeliverySolution: boolean;
  flatDeliveryInformation: FlatDeliveryInformations;
}

export interface BakeryDetails {
  id: string;
  bakeryName: string;
  addressInformations: AddressInformations;
  zone: { lat: number; lng: number }[];
}

export interface AddressInformations {
  address: string;
  zipCode: string;
  latAndLng: number[];
}

export interface FlatDeliveryInformations {
  mailBoxLocation: MailBoxLocation;
  flatAccess: FlatAccess;
  accessCode: string;
}

// Safer than using numbers
export enum Steps {
  ADDRESS = "address",
  IS_HOUSE = "isHouse",
  DELIVERY_DETAILS = "DELIVERY_DETAILS",
  VALIDATION = "Validation",
  NOT_POSSIBLE = "NOT_POSSIBLE",
}
