import React from "react";
import DeliveryManCreationForm from "../components/deliveryManCreationForm/DeliveryManCreationForm";
import styles from "./index.module.css";

const CreateDeliveryMan = () => {
  return (
    <div className={styles.bakerCreationSection}>
      <DeliveryManCreationForm />
    </div>
  );
};

export default CreateDeliveryMan;
