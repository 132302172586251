export enum Role {
  BAKER = "BAKER",
  USER = "USER",
  ANONYMOUS = "ANONYMOUS",
  ADMIN = "ADMIN",
  DELIVERY_MAN = "DELIVERY_MAN",
}

export interface UserDetails {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
  isEmailVerified: boolean;
  isFirstConnection: boolean;
  walletAmount: number;
  bakeryId: string | null;
}

export interface AuthDispatchAction {
  user: UserDetails;
  accessToken: string;
}

export interface UserRegistration {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  role?: Role;
}

export interface UserLogin {
  email: string;
  password: string;
}

export interface ConfirmSubscriptionResponse {
  amount: number;
}
