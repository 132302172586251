import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../component/common/button/Button";
import { ReactComponent as Success } from "../../../../svg/success.svg";
import styles from "./index.module.css";

const SuccessPage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <Success className={styles.logo} />
      <div className={styles.paragraph}>
        <div className={styles.title}>
          Votre compte a été créé avec succès !
        </div>
        <div className={styles.text}>
          De plus, votre adresse et votre boîte aux lettres sont bien éligibles.
        </div>
        <div>
          Vous pouvez dès à présent souscrire à un abonnement et commander vos
          produits de boulangerie frais pour demain matin !
        </div>
      </div>
      <div className={styles.button}>
        <Button
          onClick={() => {
            navigate("/");
          }}
          text={"Accès à la boutique"}
        />
      </div>
    </div>
  );
};

export default SuccessPage;
