import React from "react";
import { Category } from "../../../bakery/components/categoryCard/categoryCard";
import { ReactComponent as IncrementalIcon } from "../../../../svg/increment.svg";

function matchCategoryName(categoryName: string) {
  switch (categoryName) {
    case Category.PAINS_CLASSIQUES:
      return "Les Pains Normaux";
    case Category.PAINS_SPECIAUX:
      return "Les Pains Spéciaux";
    case Category.VIENNOISERIES:
      return "Les Viennoiseries";
    case Category.AUTRES:
      return "Autres";
  }
}

const CategoryName = ({
  categoryName,
  className,
  isHabitPage,
}: {
  categoryName?: string;
  className?: string;
  isHabitPage?: boolean;
}) => {
  if (!categoryName) {
    return null;
  }
  return (
    <div className={className}>
      {matchCategoryName(categoryName)}
      {isHabitPage ? <IncrementalIcon /> : null}
    </div>
  );
};

export default CategoryName;
