import React, { useState } from "react";
import styles from "../deleteAccount/index.module.css";
import { ReactComponent as FemmeCarton } from "../../svg/femmeCarton.svg";
import Button from "../../component/common/button/Button";
import { axiosRequest } from "../../technical/api/axiosRequest";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  activateLoading,
  deactivateLoading,
} from "../../store/reducers/appState";

const DeleteSubscriptionNotice = () => {
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.auth.user.id);

  const handleUnsubscribeClick = async () => {
    try {
      dispatch(activateLoading());
      axiosRequest<{ success: boolean }>({
        method: "post",
        url: "stripe/cancel-subscription",
        data: { userId },
      })
        .then(({ data }) => {
          if (!data.success) {
            throw new Error();
          }
          dispatch(deactivateLoading());
        })
        .finally(() => navigate("/subscription"));
    } catch (err) {
      setError(true);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.mainSection}>
        <FemmeCarton />
      </div>
      <div className={styles.textDeleteSubSection}>
        <div className={styles.title}>
          Êtes vous certain de vouloir vous désabonner ?
        </div>
        <div className={styles.text}>
          Votre abonnement prendra fin à la prochaine date de renouvellement.
          Vous pouvez continuer à profiter du service jusqu’à cette date. Vous
          ne serez plus prélevé automatiquement à partir du moment où vous vous
          désabonnez.
        </div>
        <div className={styles.text}>Souhaitez-vous continuer ?</div>
      </div>
      <div className={styles.buttonSection}>
        {error ? (
          <div style={{ color: "red" }}>
            Une erreur est survenue, veuillez réessayer
          </div>
        ) : null}
        <Button text={"Supprimer"} onClick={handleUnsubscribeClick} />
        <Button text={"Retour"} onClick={() => navigate(-1)} outlined />
      </div>
    </div>
  );
};

export default DeleteSubscriptionNotice;
