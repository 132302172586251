import { InputAdornment } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import Switch from "../../../../component/common/switch/Switch";
import { UserDeliveryInformations } from "../../pages/types";
import { ReactComponent as GreyLetterBox } from "../../../../svg/greyLetterBox.svg";
import styles from "./index.module.css";
import CustomTextField from "../../../../component/common/customTextField/CustomTextField";

interface Props {
  formik: FormikProps<UserDeliveryInformations>;
  color?: string;
}
const IsMailBoxConform = ({ formik, color }: Props) => {
  return (
    <>
      {!formik.values.isMailBoxConform ? (
        <div className={styles.conformMailBoxMainContainer}>
          <div className={styles.solutionText}>Trouvons une solution :</div>
          <div className={styles.solutionParagrah}>
            Pouvez-vous attacher un sac hermétique à votre portail ? Vous pouvez
            également disposer une boîte hermétique facilement accessible depuis
            le trottoir. Nous vous laissons imaginer toutes les solutions pour
            rendre possible la livraison sans contact.
          </div>
          <div className={styles.helperTextAndSwitchContainer}>
            <div className={styles.helperTextContainer}>
              <div className={styles.helperTextMailBox}>
                Avez-vous trouvé une solution ?
              </div>
            </div>

            <div className={styles.conformMailBoxContainer}>
              <Switch
                isOn={!formik.values.hasDeliverySolution}
                handleToggle={() => {
                  formik.setFieldValue(
                    "hasDeliverySolution",
                    !formik.values.hasDeliverySolution
                  );
                }}
                labels={{ left: "Oui", right: "Non" }}
              />
            </div>
          </div>

          {formik.values.hasDeliverySolution ? (
            <div className={styles.solutionBottomContainer}>
              <div className={styles.solutionParagrah}>
                Veuillez nous indiquer votre solution:
              </div>

              <CustomTextField
                fullWidth
                id="deliverySolution"
                name="deliverySolution"
                value={formik.values.deliverySolution}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(color === "error")}
                helperText={
                  Boolean(color === "error")
                    ? "Veuillez nous indiquer votre solution"
                    : null
                }
                required
                type="email"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GreyLetterBox />
                    </InputAdornment>
                  ),
                  placeholder: "Veuillez nous indiquer votre solution",
                }}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default IsMailBoxConform;
