import classNames from "classnames";
import React, { ReactNode } from "react";
import styles from "./index.module.css";

interface Props {
  isColored?: boolean;
  className?: string;
  children?: ReactNode;
}

const PageColor = ({ className, children, isColored }: Props) => {
  return (
    <div
      className={classNames(
        className,
        isColored ? styles.pageColor : styles.pageColorWhite
      )}
    >
      {children}
    </div>
  );
};

export default PageColor;
