import axios, { AxiosResponse } from "axios";
import { logout } from "../../store/reducers/auth";
import { store } from "../../store/store";
import isJwtValid from "../jwt/isJwtValid";

interface Props {
  method: "get" | "post" | "patch" | "delete";
  url: string;
  data?: unknown;
  config?: { "Content-Type": string };
}
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACK_URI}/`,
});

export async function axiosRequest<T>({
  method,
  url,
  data,
  config,
}: Props): Promise<AxiosResponse<T, any>> {
  const state = store.getState();

  const authToken = state.auth.token;

  if (authToken && !isJwtValid(authToken)) {
    store.dispatch(logout());
    throw new Error("JWT Expired");
  }

  let response;
  try {
    response = await axiosInstance.request<T>({
      method,
      url,
      headers: {
        ...config,
        Authorization: `Bearer ${authToken}`,
      },
      data,
    });
    return response;
  } catch (error: any) {
    if (
      error.response.data.statusCode === 401 &&
      error.response.data.message === "Token Expired"
    ) {
      localStorage.clear();
    }
    response = error.response;
  }
  return response;
}
