import React from "react";
import Button from "../../../../component/common/button/Button";
import PageLayout from "../../components/pageLayout/PageLayout";
import styles from "./index.module.css";
interface Props {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const ConformBox = ({ toggleDrawer }: Props) => {
  return (
    <PageLayout
      title={"Qu’est-ce qu’une boîte aux lettres conforme ?"}
      footer={<Button text={"Retour"} onClick={toggleDrawer(false)} />}
    >
      <div className={styles.mainContainer}>
        <div className={styles.typography}>
          Une boîte aux lettres conforme est celle qui peut être ouverte avec
          les clefs du facteur postal. Avez-vous déjà reçu un paquet qui était
          trop grand pour être glissé dans la fente de votre boîte aux lettres ?
          Si c'est le cas, votre boîte aux lettres est conforme aux normes.
          Voici quelques exemples de boîtes aux lettres conformes :
        </div>

        <div
          style={{
            height: "100px",
            backgroundImage: "url(/assets/3-letter-box.png)",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className={styles.typography}>
          Voici également des exemples de boîtes aux lettres non conformes ( il
          s’agit en général de boîtes aux lettres sans serrures, côté rue )
        </div>

        <div
          style={{
            height: "100px",
            backgroundImage: "url(/assets/2-letter-box.png)",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className={styles.typography} style={{ textAlign: "center" }}>
          N’hésitez pas à nous contacter si vous avez un doute : veuillez nous
          envoyer des photos de votre boîte aux lettres ainsi que votre adresse
          à l’adresse suivante:
          <br />
          <br />
          <a href="mailto:contact@morningbaguette.fr">
            contact@morningbaguette.fr
          </a>
        </div>
      </div>
    </PageLayout>
  );
};

export default ConformBox;
