import { isEqual } from "date-fns";
import React from "react";
import TotalLine from "../../../../component/common/totalLine/TotalLine";
import { getDateAsDay } from "../../../../technical/date/getDateAsDay";
import { Order } from "../../../cart/OrderCart";
import DisplayProductLines from "../displayProductLine/DisplayProductLines";
import styles from "./index.module.css";

interface Props {
  date: Date;
  orders: Order[];
}

const DayOrder = ({ date, orders }: Props) => {
  const indexOrderOfTheDay = orders.findIndex((order) =>
    isEqual(getDateAsDay(new Date(order.deliveryDate)), getDateAsDay(date))
  );
  if (indexOrderOfTheDay === -1) {
    return (
      <div className={styles.container}>
        <p className={styles.text}>Commandes du jour</p>
        <TotalLine amount={0} />
      </div>
    );
  }

  const order = orders[indexOrderOfTheDay];

  const contentMap = new Map<string, number>();
  order.products.forEach((elt) => {
    const { name } = elt;
    contentMap.set(name, (contentMap.get(name) ?? 0) + 1);
  });

  const totalAmount = order.products.reduce(
    (acc, elt) => (acc += elt.price),
    0
  );

  const contentArr = Array.from(contentMap);
  return (
    <div className={styles.container}>
      <p className={styles.text}>Commandes du jour</p>
      <DisplayProductLines
        content={contentArr}
        products={order.products}
        date={date}
      />
      <TotalLine amount={totalAmount} />
    </div>
  );
};

export default DayOrder;
