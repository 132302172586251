import { addDays, getDay, subDays } from "date-fns";
import React, { useMemo, useState } from "react";
import { Order } from "../../../cart/OrderCart";
import Day from "./Day";
import styles from "./index.module.css";

interface Props {
  orders: Order[];
  date: Date;
  setDate: (day: Date) => void;
}

function getWeek(date: Date): Date[] {
  let day: number = getDay(date);
  if (day === 0) {
    day = 7;
  }
  const week: Date[] = [];

  const monday: Date = subDays(date, day - 1);

  for (let i = 0; i < 7; i++) {
    week.push(addDays(monday, i));
  }
  return week;
}

const WeekStrip = ({ date, setDate, orders }: Props) => {
  const [week, setWeek] = useState<Date[]>(getWeek(date));

  useMemo(() => {
    setWeek(getWeek(date));
  }, [date]);

  return (
    <div className={styles.weekStrip}>
      {week.map((day, index) => (
        <Day
          day={day}
          date={date}
          setDate={setDate}
          orders={orders}
          key={`${day} - ${index}`}
        />
      ))}
    </div>
  );
};

export default WeekStrip;
