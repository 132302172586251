import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Typography } from "@mui/material";
import classNames from "classnames";
import { FormikProps } from "formik";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../component/common/button/Button";
import HorizontalDivider from "../../../../component/common/horizontalDivider/horizontalDivider";
import MapComponent from "../../../../component/MapComponent/MapComponent";
import Marker from "../../../../component/marker/Marker";
import { useAppSelector } from "../../../../store/hooks";
import PlacesAutocomplete from "../../components/placesAutocomplete/PlacesAutocomplete";
import BakeryConfirmation from "../bakeryConfirmation/BakeryConfirmation";
import { UserDeliveryInformations } from "../types";
import { addressValidator } from "../validator";

import styles from "./index.module.css";

interface Props {
  formik: FormikProps<UserDeliveryInformations>;
  handleNextStep: () => void;
  handleReloadStep: () => void;
}

const render = (status: Status) => {
  switch (status) {
    case Status.LOADING:
      return <div>loading</div>;
    case Status.FAILURE:
      return <div>failed</div>;
    case Status.SUCCESS:
      return <div>success</div>;
  }
};

const AddressSelection = ({
  formik,
  handleNextStep,
  handleReloadStep,
}: Props) => {
  const isFirstConnection = useAppSelector(
    (state) => state.auth.user.isFirstConnection
  );
  const navigate = useNavigate();
  const [color, setColor] = useState<string>("black");
  const [nextStep, setNextStep] = useState(false);
  const zoom = 16;
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 48.8566,
    lng: 2.3522,
  });

  const handleAddressValidationStep = async () => {
    try {
      await addressValidator.validate(formik.values.addressInformations);
      setColor("black");
      setNextStep(true);
    } catch (e) {
      setColor("error");
    }
  };

  const onSelect = (coord: google.maps.LatLngLiteral) => {
    setCenter(coord);
    formik.setFieldValue("addressInformations.latAndLng", [
      coord.lng,
      coord.lat,
    ]);
  };

  return (
    <div className={styles.container}>
      <div className={styles.mapComponent}>
        <Wrapper
          apiKey={"AIzaSyDbGc10YTTIxoe-c_MP3_spsCPrEamAZY4"}
          render={render}
        >
          <MapComponent
            zoom={zoom}
            center={center}
            className={styles.mapComponent}
          >
            <Marker position={center} />
          </MapComponent>
        </Wrapper>
      </div>
      <div
        className={classNames(
          styles.autocompleteSection,
          styles.autocompleteInsideLayout
        )}
      >
        {!nextStep ? (
          <>
            <div className={styles.titleCont}>
              <div className={styles.autocompleteTitle}>Mon adresse</div>
            </div>
            <HorizontalDivider style={{ marginBottom: "5%" }} />
            <div className={styles.placesContainer}>
              <PlacesAutocomplete onSelect={onSelect} formik={formik} />
            </div>
            <div className={styles.buttonSection}>
              <Typography className={styles.helperText} color={color}>
                Veuillez renseigner votre adresse et sélectionner celle-ci dans
                l'encadré ci-dessus
              </Typography>
              <div>
                <Button
                  text={"Suivant"}
                  onClick={handleAddressValidationStep}
                  divClassName={styles.marginBottom}
                />
                {!isFirstConnection ? (
                  <Button
                    text={"Retour"}
                    onClick={() => navigate(-1)}
                    outlined
                  />
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <BakeryConfirmation
            formik={formik}
            handleAffirmativeClick={handleNextStep}
            handleReloadStep={handleReloadStep}
          />
        )}
      </div>
    </div>
  );
};

export default AddressSelection;
