import classNames from "classnames";
import React from "react";
import CategoryCard, { Category } from "../categoryCard/categoryCard";
import styles from "./index.module.css";

interface Props {
  isHabit?: boolean;
}

const CategoryList = ({ isHabit }: Props) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.topContainer}>
        <div className={styles.verticalContainer}>
          <CategoryCard
            imageUri={"/assets/pains-normaux.png"}
            className={classNames(
              styles.firstSmallRectangle,
              styles.smallRectangles
            )}
            category={Category.PAINS_CLASSIQUES}
            isHabit={isHabit}
          />
          <CategoryCard
            imageUri={"/assets/autres.png"}
            className={styles.smallRectangles}
            category={Category.AUTRES}
            isHabit={isHabit}
          />
        </div>
        <CategoryCard
          imageUri={"/assets/pains-speciaux.png"}
          className={styles.fatRectangle}
          category={Category.PAINS_SPECIAUX}
          isHabit={isHabit}
        />
      </div>
      <div className={styles.bottomContainer}>
        <CategoryCard
          imageUri={"/assets/viennoiseries.png"}
          className={styles.slimRectangle}
          category={Category.VIENNOISERIES}
          isHabit={isHabit}
        />
      </div>
    </div>
  );
};

export default CategoryList;
