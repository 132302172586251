import jwtDecode, { JwtPayload } from "jwt-decode";
const isJwtValid = (token: string | null) => {
  if (!token) {
    return false;
  }
  const decodedToken = jwtDecode<JwtPayload>(token);
  const dateNow = new Date();

  if (!decodedToken.exp) {
    return false;
  }

  if (decodedToken.exp * 1000 < dateNow.getTime()) {
    return false;
  } else {
    return true;
  }

  //   return false;
};

export default isJwtValid;
