import { CartContent } from "./../cart/index";
import { HabitsDays } from "./../../../business/habits/page/useHabitsSelector";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { Habit } from "./type";
import { activateLoading, deactivateLoading } from "../appState";

export const changeHabitCart = createAsyncThunk(
  // action type string
  "habitCart/changeHabitCart",
  // callback function
  async ({ day }: { day: HabitsDays }, thunkAPI) => {
    thunkAPI.dispatch(activateLoading());
    const { data } = await axiosRequest<Habit | null>({
      method: "get",
      url: `habits/${day}`,
    });
    thunkAPI.dispatch(deactivateLoading());

    if (data && data?._id !== undefined) {
      return data;
    }

    return null;
  }
);

export const submitHabitCart = createAsyncThunk(
  // action type string
  "habitCart/submitHabitCart",
  // callback function
  async (
    {
      products,
      day,
      status,
    }: {
      products: CartContent[];
      day: HabitsDays;
      status: string;
    },
    thunkAPI
  ) => {
    thunkAPI.dispatch(activateLoading());
    const resp = await axiosRequest({
      method: "post",
      url: "habits",
      data: {
        products,
        day,
        status: "ONGOING",
      },
    });
    thunkAPI.dispatch(deactivateLoading());

    if (resp.status < 300) {
      return day;
    }

    return null;
  }
);

export const getAllHabits = createAsyncThunk(
  // action type string
  "habitCart/getAllHabits",
  // callback function
  async (_, thunkAPI) => {
    thunkAPI.dispatch(activateLoading());
    const { data, status } = await axiosRequest<
      {
        totalAmount: number;
        content: CartContent[];
        day: number;
      }[]
    >({
      method: "get",
      url: "habits",
    });
    thunkAPI.dispatch(deactivateLoading());

    if (status < 300) {
      return data;
    }

    return null;
  }
);
