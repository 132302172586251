import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { addZero } from "../../../technical/string/addZero";
import styles from "./index.module.css";

const WalletContainer = ({
  alternatif,
  className,
}: {
  alternatif?: boolean;
  className?: string;
}) => {
  const navigate = useNavigate();
  const amount = useAppSelector((state) => state.auth.user.walletAmount);
  return (
    <div
      className={classNames(
        alternatif ? styles.amountContainerAlternative : styles.amountContainer,
        className
      )}
      onClick={() => navigate("/wallet")}
    >
      <div className={alternatif ? styles.amountAlternative : styles.amount}>
        {addZero((amount / 100).toString()) + "€"}
      </div>
    </div>
  );
};

export default WalletContainer;
