import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { Role } from "../../../../store/reducers/auth/type";
import { axiosRequest } from "../../../../technical/api/axiosRequest";
import { DailyCaResponse } from "../../type/type";
import Chiffre from "../chiffre/Chiffre";
import { CollapsibleTable } from "../collapsibleTable/CollapsibleTable";

const DailyCa = ({
  bakeryId,
  date,
}: {
  bakeryId: string | null;
  date: Date;
}) => {
  const formatedDate = format(date, "yyyy-MM-dd");
  const [orders, setOrders] = useState<DailyCaResponse[]>([]);
  const [caDuMois, setCaDuMois] = useState<number>(0);
  const userRole = useAppSelector((state) => state.auth.user.role);

  useEffect(() => {
    async function getMonthOrders() {
      const resp = await axiosRequest<DailyCaResponse[]>({
        method: "get",
        url: `order/bakeryMonthly/${bakeryId}/${formatedDate}`,
      });

      setOrders(resp.data);
      setCaDuMois(resp.data.reduce((acc, cur) => acc + cur.totalAmount, 0));
    }

    getMonthOrders();
  }, [formatedDate, bakeryId]);

  return (
    <>
      {userRole === Role.ADMIN ? (
        <div style={{ margin: "0.75rem 0" }}>
          <Chiffre nb={caDuMois} caption={"CA du mois"} price />
        </div>
      ) : null}
      <CollapsibleTable orders={orders} />
    </>
  );
};

export default DailyCa;
