import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../component/common/button/Button";
import { Progress } from "../../../component/common/circularProgress/Progress";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { BakeryDetails } from "../../firstSteps/pages/types";
import SuiviCa from "../../suiviCa/page/SuiviCa";
import { SelectBakery } from "../components/selectBakery/SelectBakery";
import styles from "./index.module.css";

export const AdminSuiviCa = () => {
  const [bakeries, setBakeries] = useState<BakeryDetails[]>([]);
  const [selectedBakery, setSelectedBakery] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchBakeries() {
      const { data, status } = await axiosRequest<BakeryDetails[]>({
        method: "get",
        url: "bakery",
      });
      if (status === 200) {
        setLoading(false);
        setBakeries(data);
        setError(false);
      } else {
        setLoading(false);
        setError(true);
      }
    }
    fetchBakeries();
  }, []);

  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <div className={styles.suiviCa}>
          <div className={styles.buttonSection}>
            <Button
              text={"Retour"}
              onClick={() => navigate("/profile")}
              outlined
            />
          </div>
          {!error ? (
            <>
              <div className={styles.marginBottom}>
                <SelectBakery
                  bakeries={bakeries}
                  setSelectedBakery={setSelectedBakery}
                  selectedBakery={selectedBakery}
                />
              </div>

              <div className={styles.marginBottom}>
                <SuiviCa adminBakeryId={selectedBakery} />
              </div>
            </>
          ) : (
            "Une erreur est survenue"
          )}
        </div>
      )}
    </>
  );
};
