import { useEffect, useState } from "react";
import { createCheckoutSession } from "../../../wallet/page/getCheckoutUrl";
import { ReactComponent as Velo } from "../../../../svg/velo.svg";
import styles from "./index.module.css";
import Button from "../../../../component/common/button/Button";
import BottomSection from "../../../../component/common/bottomSection/bottomSection";
import { Tabs } from "../../../../store/reducers/navBar/type";
import { axiosRequest } from "../../../../technical/api/axiosRequest";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../store/hooks";
import {
  activateLoading,
  deactivateLoading,
} from "../../../../store/reducers/appState";

export interface SubState {
  nextPeriodStartDate: string;
  status: "ONGOING" | "PAUSE" | "CANCEL" | "NOT_STARTED";
}

const SubscriptionState = ({
  subscriptionState,
}: {
  subscriptionState?: SubState;
}) => {
  if (subscriptionState) {
    if (subscriptionState.status === "ONGOING") {
      return (
        <div style={{ color: "green" }}>
          Vous êtes déjà abonné
          <br /> Date de renouvellement :<br />
          {format(
            new Date(subscriptionState.nextPeriodStartDate),
            "dd/MM/yyyy"
          )}
        </div>
      );
    }

    if (subscriptionState.status === "CANCEL") {
      return (
        <div style={{ color: "orange" }}>
          Votre abonnement se termine le :<br />
          {format(
            new Date(subscriptionState.nextPeriodStartDate),
            "dd/MM/yyyy"
          )}
        </div>
      );
    }

    if (subscriptionState.status === "PAUSE") {
      return (
        <div style={{ color: "orange" }}>Vous abonnement est en pause</div>
      );
    }
  }
  return null;
};

const Subscription = () => {
  const [error, setError] = useState<string | undefined>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [subscriptionState, setSubscriptionState] = useState<
    SubState | undefined
  >();

  useEffect(() => {
    dispatch(activateLoading());
    async function fetchData() {
      setSubscriptionState(undefined);
      const res = await axiosRequest<SubState>({
        method: "get",
        url: "subscription/state",
      });
      if (res.status < 300) {
        setSubscriptionState(res.data);
      } else {
        setError(
          "Une erreur est survenue. Veuillez réessayer plus tard où nous contacter sur morningbaguette@gmail.fr"
        );
      }
      dispatch(deactivateLoading());
    }
    setTimeout(() => fetchData(), 500);
  }, []);

  const item =
    process.env.REACT_APP_ENV! === "production"
      ? // prod price
        {
          price: process.env.REACT_APP_LIVE_SUB_PRICE!,
          quantity: 1,
        }
      : // dev price
        {
          price: process.env.REACT_APP_TEST_SUB_PRICE!,
          quantity: 1,
        };

  const handleSubscribeClick = async () => {
    try {
      const checkoutUrl = await createCheckoutSession(item, "subscription");
      if (checkoutUrl === null) {
        setError("Une erreur est survenue");
        return;
      }
      window.open(checkoutUrl, "_self");
    } catch (err) {
      setError(
        "Une erreur est survenue. Veuillez réessayer plus tard où nous contacter sur morningbaguette@gmail.fr"
      );
    }
  };

  return (
    <div className={styles.container}>
      <Velo />
      <div className={styles.title}>Souscrire à l'abonnement</div>
      <div>
        <p className={styles.paragraph}>
          Pour pouvoir assurer le bon fonctionnement de notre service nous
          demandons à nos clients une participation de 11,90€ / mois
        </p>
      </div>
      <div className={styles.buttons}>
        {error ? <div style={{ color: "red" }}>{error}</div> : null}
        <SubscriptionState subscriptionState={subscriptionState} />
        <Button
          text={"Continuer"}
          onClick={handleSubscribeClick}
          disabled={
            subscriptionState &&
            ["ONGOING", "CANCEL"].includes(subscriptionState.status)
          }
        />
        <Button
          text={"Annuler l'abonnement"}
          onClick={() => {
            setSubscriptionState(undefined);
            navigate("/deleteSubscription");
          }}
          disabled={subscriptionState?.status !== "ONGOING"}
          outlined
        />
      </div>
      <BottomSection tab={Tabs.PROFILE} />
    </div>
  );
};

export default Subscription;
