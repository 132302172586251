import { Grid, Typography } from "@mui/material";
import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from "use-places-autocomplete";
import parse from "autosuggest-highlight/parse";

import { ReactComponent as House } from "../../../../svg/house.svg";
import { FormikProps } from "formik";
import { UserDeliveryInformations } from "../../pages/types";
import { BakerRegistrationValues } from "../../../admin/components/bakeryCreationForm/type";
import CustomTextField from "../../../../component/common/customTextField/CustomTextField";

interface Props {
  onSelect: (coordinates: any) => void;
  formik:
    | FormikProps<UserDeliveryInformations>
    | FormikProps<BakerRegistrationValues>;
}

const PlacesAutocomplete = ({ onSelect, formik }: Props) => {
  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "fr" },
    },
    debounce: 300,
  });

  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    formik.handleChange(e);
  };
  const handleSelect =
    ({ description }: { description: string }) =>
    () => {
      setValue(description, false);
      formik.setFieldValue("addressInformations.address", description);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => onSelect({ lat, lng }))
        .catch((error) => console.log("😱 Error: ", error));

      // Get zipcode via utility functions
      getGeocode({ address: description })
        .then((results) => getZipCode(results[0], true))
        .then((zipCode) =>
          formik.setFieldValue("addressInformations.zipCode", zipCode)
        )
        .catch((error) => console.log("😱 Error: ", error));
    };

  return (
    <>
      <CustomTextField
        size="small"
        label="Votre adresse"
        variant="outlined"
        name="address"
        id="address"
        fullWidth
        onChange={(e) => handleInput(e)}
        value={value}
      />
      {data.length > 0
        ? data.slice(0, 3).map((option) => {
            const matches =
              option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [
                match.offset,
                match.offset + match.length,
              ])
            );
            return (
              <Grid
                container
                alignItems="center"
                onClick={handleSelect(option)}
                style={{
                  backgroundColor: "white",
                  border: "solid 1px lightgrey",
                }}
              >
                <Grid item xs={1}>
                  <House />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={`${part}_${index}`}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          })
        : null}
    </>
  );
};

export default PlacesAutocomplete;
