import { format, getDay } from "date-fns";
import { fr } from "date-fns/locale";
import React from "react";
import TotalLine from "../../../../component/common/totalLine/TotalLine";
import { useAppSelector } from "../../../../store/hooks";
import DisplayProductLines from "../displayProductLine/DisplayProductLines";
import styles from "./index.module.css";

interface Props {
  date: Date;
}

const DayHabits = ({ date }: Props) => {
  const dayContents = useAppSelector((state) => {
    if (state.habitCart.cart[getDay(date)]) {
      return state.habitCart.cart[getDay(date)].content;
    }
    return [];
  });

  const contentMap = new Map<string, number>();
  dayContents.forEach((elt) => {
    const { name } = elt;
    contentMap.set(name, (contentMap.get(name) ?? 0) + 1);
  });

  const totalAmount = dayContents.reduce((acc, elt) => (acc += elt.price), 0);

  const contentArr = Array.from(contentMap);
  return (
    <div className={styles.container}>
      <p className={styles.text}>
        Habitude du {format(date, "EEEE", { locale: fr })}
      </p>

      <DisplayProductLines
        content={contentArr}
        products={dayContents}
        isHabit={true}
      />
      <TotalLine amount={totalAmount} />
    </div>
  );
};

export default DayHabits;
