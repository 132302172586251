import * as yup from "yup";

const stringValidator = yup.string().trim().required("Ce champ est requis");

const frenchPhoneNumberRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;

const bakeryFormValidator = yup.object().shape({
  userId: yup.string().test("userID", "Utilisateur inexistant", (value) => {
    if (!value) {
      return false;
    }
    if (value.trim().length > 0) {
      return value !== "Veuillez sélectionner votre boulanger";
    }
    return false;
  }),
  bakeryName: stringValidator,
  address: stringValidator,
  description: stringValidator,
  deliveryCode: stringValidator,
  phoneNumber: yup
    .string()
    .matches(frenchPhoneNumberRegex, "Le numéro de téléphone est invalide")
    .required("Ce champ est requis"),
  zone: yup.array().min(3, ""),
});

export default bakeryFormValidator;
