import React from "react";
import styles from "./index.module.css";
import { ReactComponent as Minus } from "../../../../svg/minus.svg";
import { ReactComponent as Plus } from "../../../../svg/plus.svg";
import classNames from "classnames";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { Category } from "../../../bakery/components/categoryCard/categoryCard";
import {
  addToHabitCart,
  removeFromHabitCart,
} from "../../../../store/reducers/habitCart";
import ImageSquare from "../../../../component/common/imageSquare/ImageSquare";

interface Props {
  id: string;
  name: string;
  price: number;
  category: Category;
  imageUrl: string;
}

const SelectHabitProductQuantity = ({
  id,
  name,
  price,
  category,
  imageUrl,
}: Props) => {
  const day = useAppSelector((state) => state.habitCart.activeDay);
  const selector = useAppSelector((state) => state.habitCart.cart[day].content);

  const dispatch = useAppDispatch();

  const quantity = selector.filter((elt) => elt.id === id).length;

  return (
    <ImageSquare imageUrl={imageUrl}>
      {(() => {
        if (!quantity || quantity === 0) {
          return (
            <div className={styles.badge}>
              <div
                onClick={() =>
                  dispatch(
                    addToHabitCart({
                      product: { id, name, price, category },
                      day,
                    })
                  )
                }
                className={styles.iconContainer}
              >
                <Plus className={styles.icons} />
              </div>
            </div>
          );
        } else {
          return (
            <div className={classNames(styles.badge, styles.longBadge)}>
              <div
                onClick={() =>
                  dispatch(
                    removeFromHabitCart({
                      product: { id, name, price, category },
                      day,
                    })
                  )
                }
                className={styles.iconContainer}
              >
                <Minus className={styles.icons} />
              </div>
              {quantity}
              <div
                onClick={() =>
                  dispatch(
                    addToHabitCart({
                      product: { id, name, price, category },
                      day,
                    })
                  )
                }
                className={styles.iconContainer}
              >
                <Plus className={styles.icons} />
              </div>
            </div>
          );
        }
      })()}
    </ImageSquare>
  );
};

export default SelectHabitProductQuantity;
