import React, { ReactNode, useMemo, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import BottomSection from "../../../component/common/bottomSection/bottomSection";
import Button from "../../../component/common/button/Button";
import CustomTextField from "../../../component/common/customTextField/CustomTextField";
import PageColor from "../../../component/common/pageColor/PageColor";
import WalletContainer from "../../../component/common/wallet/WalletContainer";
import { useAppSelector } from "../../../store/hooks";
import { Role } from "../../../store/reducers/auth/type";
import { Tabs } from "../../../store/reducers/navBar/type";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { closingDaysToWeekDay } from "../../../technical/date/closingDaysToWeekDay";
import CategoryList from "../components/categoryList/categoryList";
import styles from "./index.module.css";
import { ReactComponent as RightArrow } from "../../../svg/rightArrow.svg";
import classNames from "classnames";

interface Props {
  amount: number;
  userRole: Role;
  children?: ReactNode;
}

const Bakery: React.FC<Props> = ({ userRole, children }: Props) => {
  const bakeryId = useAppSelector((state) => state.auth.user.bakeryId);
  const [bakeryName, setBakeryName] = useState<string | undefined>();

  useMemo(() => {
    async function getBakeryName() {
      const { data } = await axiosRequest<string>({
        method: "get",
        url: `bakery/name/${bakeryId}`,
      });

      setBakeryName(data);
    }

    getBakeryName();
  }, [bakeryId]);

  return (
    <PageColor>
      {userRole === Role.USER ? (
        <div
          className={styles.topPart}
          style={{
            backgroundImage: `url('/assets/top.png')`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
          }}
        >
          <WalletContainer className={styles.margin} />
          <h2 className={styles.bakeryName}>{bakeryName}</h2>
        </div>
      ) : (
        <div className={styles.topPartBaker}>Ma boutique</div>
      )}
      <div
        className={classNames(
          styles.children,
          userRole === Role.USER ? styles.shadow : {}
        )}
      >
        {children}
      </div>
    </PageColor>
  );
};

const BakeryPage = () => {
  const userRole = useAppSelector((state) => state.auth.user.role);
  const amount = useAppSelector((state) => state.auth.user.walletAmount);
  const bakeryId = useAppSelector((state) => state.auth.user.bakeryId);
  const [closingDays, setClosingDays] = useState<string[]>([]);

  const [code, setCode] = useState<string>();
  const navigate = useNavigate();

  useMemo(() => {
    const getClosingDays = async () => {
      const { status, data } = await axiosRequest<number[]>({
        url: `bakery/closingDays/${bakeryId}`,
        method: "get",
      });

      if (status === 200) {
        setClosingDays(closingDaysToWeekDay(data));
      }
    };
    getClosingDays();
  }, [bakeryId]);

  if (userRole === Role.ADMIN) {
    return <Navigate to={"/admin"} />;
  }

  return (
    <>
      {userRole === Role.BAKER || userRole === Role.USER ? (
        <Bakery userRole={userRole} amount={amount}>
          {userRole === Role.BAKER ? (
            <>
              <div className={styles.modificationTextBlock}>
                <div className={styles.modificationTitle}>Modifications</div>
                <div className={styles.modificationText}>
                  Selectionner une catégorie pour ajouter ou modifier un article
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.infoTextBlock}>
                <div
                  className={styles.bakeryInfoText}
                  onClick={() => navigate("/user/bakeryInformation")}
                >
                  Voir les infos de la boulangerie
                  <RightArrow />
                </div>
                <div className={styles.closingText}>
                  Fermé le :
                  <div style={{ color: "#EA974A", marginLeft: "4px" }}>
                    {closingDays.join(", ")}
                  </div>
                </div>
              </div>
            </>
          )}
          <CategoryList />
          <BottomSection hasDrawer={false} tab={Tabs.CART} />
        </Bakery>
      ) : null}

      {userRole === Role.DELIVERY_MAN && (
        <div className={styles.deliveryBox}>
          <div className={styles.container}>
            <CustomTextField
              fullWidth
              id="text"
              name="text"
              label="Code de la boulangerie"
              onChange={(e) => setCode(e.target.value)}
              InputProps={{
                placeholder: "Code de la boulangerie",
              }}
              style={{
                marginBottom: "0.75rem",
                background: "white",
                borderRadius: "15px",
              }}
            />

            <Button
              onClick={() => navigate(`/delivery/${code}`)}
              text={"Livraison"}
            />
          </div>
          <BottomSection hasDrawer={false} tab={Tabs.CART} />
        </div>
      )}
    </>
  );
};

export default BakeryPage;
