import { Typography } from "@mui/material";
import React from "react";
import styles from "./index.module.css";

interface Props {
  title: string;
  footer: React.ReactNode;
  children: React.ReactNode;
}

const PageLayout = ({ title, footer, children }: Props) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.titleContainer}>
        <Typography className={styles.title}>{title}</Typography>
      </div>
      <div className={styles.childrenContainer}>{children}</div>
      <div className={styles.footerContainer}>{footer}</div>
    </div>
  );
};

export default PageLayout;
