import React from "react";
import styles from "./index.module.css";
import { ReactComponent as PointLeftArrow } from "../../../../svg/pointLeftArrow.svg";
import { ReactComponent as PointRightArrow } from "../../../../svg/pointRightArrow.svg";
import { capitalizeFirstLetter } from "../../../../technical/string/capitalize";

interface Props {
  month: string;
  dateMinusOne: () => void;
  datePlusOne: () => void;
}

const MonthSelector = ({ month, dateMinusOne, datePlusOne }: Props) => {
  return (
    <div className={styles.date}>
      <div onClick={dateMinusOne} className={styles.marginLeft}>
        <PointLeftArrow />
      </div>
      <div className={styles.text}>{capitalizeFirstLetter(month)}</div>
      <div onClick={datePlusOne} className={styles.marginRight}>
        <PointRightArrow />
      </div>
    </div>
  );
};

export default MonthSelector;
