import { InputAdornment } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import resetPasswordValidator from "./validator";
import { ReactComponent as Lock } from "../../../svg/lock.svg";
import styles from "./index.module.css";
import Button from "../../../component/common/button/Button";
import CustomTextField from "../../../component/common/customTextField/CustomTextField";

interface IResetPassword {
  password: string;
  confirmPassword: string;
}

const ResetPasswordForm = () => {
  const { resetPasswordToken } = useParams();
  const [error, setError] = useState<string | undefined>();
  const [status, setStatus] = useState<string | undefined>();
  const navigate = useNavigate();

  const replacePasswordSubmit = async (password: string) => {
    const req = await axiosRequest<{ success: boolean }>({
      method: "post",
      url: "auth/replacePassword",
      data: {
        resetPasswordToken,
        password,
      },
    });

    if (!req.data.success) {
      setError("Une erreur est survenue");
      setStatus(undefined);
    } else {
      setError(undefined);
      setStatus("Mot de passe changé avec succès !");
    }
  };

  const formik = useFormik<IResetPassword>({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: resetPasswordValidator,
    onSubmit: async (values: IResetPassword) => {
      try {
        await replacePasswordSubmit(values.password);
      } catch (rejectedValueOrSerializedError) {
        setError(rejectedValueOrSerializedError as string);
      }
    },
  });

  return (
    <div className={styles.form}>
      <CustomTextField
        fullWidth
        id="password"
        name="password"
        label="Mot de passe"
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched.password && formik.errors.password)}
        required
        helperText={
          Boolean(formik.touched.password && formik.errors.password)
            ? formik.errors.password
            : null
        }
        type="password"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Lock />
            </InputAdornment>
          ),
          placeholder: "Entrez votre mot de passe",
        }}
      />
      <CustomTextField
        fullWidth
        id="confirmPassword"
        name="confirmPassword"
        label="Valider le mot de passe"
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(
          formik.touched.confirmPassword && formik.errors.confirmPassword
        )}
        required
        helperText={
          Boolean(
            formik.touched.confirmPassword && formik.errors.confirmPassword
          )
            ? formik.errors.confirmPassword
            : null
        }
        type="password"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Lock />
            </InputAdornment>
          ),
          placeholder: "Confirmez votre mot de passe",
        }}
      />

      {error ? <div style={{ color: "red" }}>{error}</div> : null}
      {status ? <div>{status}</div> : null}
      <div className={styles.buttonSection}>
        <Button text={"Envoyer"} onClick={formik.handleSubmit} />
        <Button
          text={"Me connecter"}
          onClick={() => navigate("/login")}
          outlined
        />
      </div>
    </div>
  );
};

export default ResetPasswordForm;
