import React from "react";
import styles from "./index.module.css";

const CGU = () => {
  return (
    <div className={styles.page}>
      <h2>Conditions générales d'utilisation </h2>
      Date de dernière mise à jour : 16 jan. 2023
      <h2>I. Introduction</h2>
      <div>
        Lors de votre navigation sur la Plateforme MORNING BAGUETTE et de
        l’utilisation des Services de vente en ligne qui y sont proposés, des
        données personnelles vous concernant sont collectées et traitées par nos
        services. La présente politique de protection des données a pour objet
        de vous informer sur : La façon dont nous collectons et traitons vos
        données à caractère personnel ; Les cookies que nous déposons sur votre
        terminal de navigation. ​ Lorsque vous utilisez MORNING BAGUETTE vous
        nous confiez vos données personnelles. Nous nous engageons à faire
        honneur à votre confiance. Dans cette optique, nous commençons par vous
        aider à comprendre nos pratiques en matière de confidentialité.
      </div>
      <h2>II. Présentation</h2>
      <div>
        <h3>A. Champ d'application</h3>
        Cette déclaration s'applique aux utilisateurs des services MORNING
        BAGUETTE, y compris tout utilisateur des applications, sites Web,
        fonctionnalités ou autres services MORNING BAGUETTE. MORNING BAGUETTE
        est responsable du traitement des données personnelles recueillies dans
        le cadre de l’utilisation des services MORNING BAGUETTE. Cette
        déclaration s'applique spécifiquement aux utilisateurs suivants :
        Destinataires de livraison : personnes qui commandent des produits et
        services Commerçants partenaires : fournisseurs de produits et services,
        partenaires de MORNING BAGUETTE Livreurs : livreurs indépendants,
        personnes salariées des boulangeries personnelles, personnes salariées
        de sociétés externes ou encore personnes salariées de MORNING BAGUETTE,
        qui assurent la livraison des produits et services Franchisés : entités
        que MORNING BAGUETTE a autorisé à commercialiser des types de produits
        et services déterminés. Cette déclaration régit également d'autres
        collectes de données personnelles effectuées par MORNING BAGUETTE dans
        le cadre de ses services. Par exemple, nous pouvons recueillir les
        coordonnées de personnes qui utilisent des comptes appartenant à des
        clients MORNING BAGUETTE ENTREPRISE, ou celles d’employés de commerçants
        partenaires MORNING BAGUETTE. Nous pouvons également recueillir les
        données personnelles des personnes qui entament (sans le terminer) le
        processus d'inscription pour devenir client, livreur ou partenaire. Nos
        pratiques en matière de données sont soumises à la loi française. Cela
        signifie que les pratiques décrites dans la présente déclaration ne sont
        mises en œuvre que si elles sont autorisées par les lois françaises.
        <h3>B. Responsable du traitement des données</h3>​ MORNING BAGUETTE est
        responsable du traitement des données personnelles recueillies dans le
        cadre de l’utilisation de ses services. ​ Les questions, commentaires et
        réclamations concernant les pratiques relatives aux données de MORNING
        BAGUETTE peuvent être envoyés à l’adresse mail suivante :
        morningbaguettecontact@gmail.com. Vous pouvez remplir ce formulaire pour
        nous poser une question à propos de la protection des données.
      </div>
      <h2>III. Collecte et utilisation des données</h2>
      <div>
        <h3>A. Données recueillies par MORNING BAGUETTE</h3>
        <h4>1. Données fournies par les utilisateurs</h4>
        Celles-ci comprennent : Nous recueillons des données lorsque les
        utilisateurs créent ou mettent à jour leur compte MORNING BAGUETTE.
        Elles peuvent inclure leur nom, leur adresse e-mail, leur(s) numéro(s)
        de téléphone, leur mot de passe, leur adresse, et les différentes
        informations de livraison demandées lors de la création de compte
        (maison ou appartement, code d’immeuble, conformité boîte aux lettres…).
        ​<h4>2. Données créées pendant l'utilisation de nos services</h4>
        Celles-ci comprennent : Les données d’authentification/utilisateur —
        nous recueillons les données relatives à l’authentification de chaque
        utilisateur. Ces données sont composées de l’identifiant unique (un
        numéro spécifique à chaque utilisateur), son adresse e-mail, si
        l’adresse e-mail est vérifiée ou pas, nom, prénom, rôle (quatre types de
        rôle différent : utilisateur, boulanger, livreur ou admin), s’il s’agit
        de la première connexion ou pas (qui change ensuite le parcours
        utilisateur), le montant de sa cagnotte personnelle, l’identifiant de sa
        boulangerie associée et enfin si il est ou non abonné au service. Les
        données sur les transactions — nous recueillons les données relatives
        aux transactions associées à l'utilisation de nos services, y compris le
        type de service commandé ou fourni, les détails de la commande, les
        données relatives à la livraison, l'heure et la date d'exécution du
        service, le montant facturé et le moyen de paiement. Les données
        d'utilisation — nous recueillons des données sur l'interaction des
        utilisateurs avec nos services. Elles incluent des données telles que
        les dates et heures d'accès, les fonctionnalités ou les pages
        d'application utilisées, les pannes d'application et les autres
        activités associées au système, le type de navigateur, et les sites Web
        ou services tiers utilisés avant l'interaction avec nos services. Dans
        certains cas, nous recueillons ces données par l'intermédiaire de
        cookies, de pixels invisibles, de tags et de technologies similaires de
        suivi qui créent et conservent des identifiants uniques. Nous
        enregistrons également un token permettant d’identifier les appels d’un
        utilisateur à la base de données, cela comprend entre autres les
        produits ajoutés au panier et le montant monétaire associé à ces
        produits. Ceux deux données sont doublement enregistrées, d’une part
        pour la boutique classique et d’autres part pour la boutique associée
        aux habitudes (achats récurrents). les données relatives aux appareils —
        nous pouvons recueillir des données au sujet des appareils utilisés pour
        accéder à nos services, y compris le modèle, l'adresse IP, le système
        d'exploitation et la version, les logiciels, la langue d'affichage,
        l'identifiant unique, l'identifiant publicitaire, le numéro de série,
        les données concernant les déplacements des appareils et les données
        relatives au réseau mobile. les données de communication — nous
        permettons aux utilisateurs de communiquer avec MORNING BAGUETTE dans
        les applications mobiles et sur les sites Web. Par exemple, nous
        permettons aux utilisateurs d'échanger des SMS ou des messages. MORNING
        BAGUETTE peut également utiliser ces données pour apporter une
        assistance au client (y compris pour résoudre des litiges), pour assurer
        la sûreté et la sécurité de nos services, pour améliorer nos produits et
        services, et pour effectuer des analyses.
        <h3>B. Utilisation des données personnelles</h3>MORNING BAGUETTE
        recueille et utilise des données pour proposer aux utilisateurs des
        produits et des services (abonnements, livraisons et autres) pratiques
        et fiables. Nous utilisons également les données recueillies : ​ pour
        améliorer la sécurité de nos services et celle de nos utilisateurs, pour
        l'assistance client, pour la recherche et le développement, pour
        permettre aux utilisateurs de communiquer avec MORNING BAGUETTE, pour
        envoyer des communications à visée marketing et non marketing aux
        utilisateurs, à des fins de procédures judiciaires. ​ MORNING BAGUETTE
        ne vend et ne partage pas les données personnelles des utilisateurs avec
        des tiers à des fins de marketing direct, sauf avec l'accord des
        utilisateurs. MORNING BAGUETTE utilise les données recueillies à
        différentes fins, y compris : ​
        <h4>1. Fournir des services et des fonctionnalités.</h4>
        Nous utilisons les données recueillies pour fournir, personnaliser,
        entretenir et améliorer nos produits et services. Cela inclut
        l'utilisation des données aux fins suivantes : ​ créer et mettre à jour
        les comptes des utilisateurs ; permettre la prestation du service de
        livraison et autres ; offrir une fonctionnalité de paiement, traiter les
        paiements ou faciliter les paiements de nos services ; suivre et
        partager l'évolution des livraisons ; proposer des fonctionnalités de
        personnalisation des comptes MORNING BAGUETTE, comme l'enregistrement de
        commerce favoris, et permettre un accès rapide aux commandes
        précédentes. effectuer les opérations internes nécessaires pour fournir
        nos services, y compris pour résoudre des bugs logiciels et des
        problèmes opérationnels, procéder à des analyses de données, des tests
        et des recherches, ainsi que pour surveiller et analyser les tendances
        en matière d'activité et d'utilisation. ​
        <h4>2. Proposer une assistance client.</h4>
        MORNING BAGUETTE utilise les informations afin d'offrir une assistance
        client, y compris pour : envoyer les questions au bon conseiller du
        service d'assistance client ; étudier et traiter les problèmes de
        l'utilisateur ; surveiller et améliorer la qualité et les processus de
        notre service d'assistance client. ​ 3. Travailler sur la recherche et
        le développement. Nous pouvons utiliser les données recueillies à des
        fins de test, de recherche, d'analyse, de développement de produit et
        d'apprentissage automatique dans le but d'améliorer l'expérience
        utilisateur. Cela nous permet de renforcer la sûreté et la sécurité de
        nos services, d'améliorer notre capacité à empêcher l'utilisation de nos
        services à des fins illégales ou inappropriées, de développer de
        nouvelles fonctionnalités et de nouveaux produits.
        <h4>3. Réaliser des actions promotionnelles.</h4>
        MORNING BAGUETTE peut utiliser les données recueillies pour faire la
        promotion de ses services auprès des utilisateurs, par exemple, pour
        leur envoyer des communications concernant les services,
        fonctionnalités, promotions, concours, études, enquêtes, actualités,
        mises à jour et événements MORNING BAGUETTE. Nous pouvons également
        envoyer des communications à nos utilisateurs au sujet des produits et
        des services proposés par les partenaires MORNING BAGUETTE. Par exemple,
        si un utilisateur a passé une commande, nous pouvons lui fournir des
        recommandations, lui offrir des promotions ou lui présenter des annonces
        pour des produits similaires proposés par d'autres partenaires MORNING
        BAGUETTE. Bien que nous puissions envoyer aux utilisateurs des
        communications concernant les produits et services des partenaires
        MORNING BAGUETTE, nous ne vendons et ne partageons pas les données
        personnelles des utilisateurs avec ces partenaires ou d'autres tiers à
        des fins de marketing direct ou de publicité, sauf avec accord de
        l'utilisateur. ​ Nous pouvons utiliser les données recueillies pour
        personnaliser nos communications promotionnelles (dont les annonces), y
        compris selon la localisation de l'utilisateur, son utilisation des
        services MORNING BAGUETTE, ses préférences et ses paramètres
        <h4>5. Envoyer des communications non promotionnelles.</h4>
        MORNING BAGUETTE peut utiliser les données recueillies pour créer des
        reçus et les envoyer aux utilisateurs ; les informer de modifications
        apportées à nos conditions générales, nos services ou nos politiques ;
        ou encore envoyer des communications qui n'ont pas pour objet de faire
        la promotion des services ou produits de MORNING BAGUETTE ou de ses
        partenaires.
        <h4>
          6. Engager des procédures judiciaires et respecter les exigences
          légales
        </h4>
        Nous pouvons utiliser les données personnelles recueillies pour examiner
        ou traiter des réclamations ou des litiges relatifs à l'utilisation des
        services MORNING BAGUETTE ; pour tout autre cas autorisé par la loi en
        vigueur ; ou sur demande d'un organisme de réglementation, d'une
        administration ou dans le cadre d'une enquête officielle.
      </div>
      <div>
        <h3>C. Cookies et technologies tierces</h3>MORNING BAGUETTE utilise des
        cookies et d'autres technologies d'identification sur ses applications,
        sites Web, e-mails et annonces en ligne aux fins décrites dans cette
        déclaration. Les cookies sont de petits fichiers textes qui sont stockés
        dans les navigateurs Web ou les appareils par les sites Web, les
        applications, les médias en ligne et les annonces. MORNING BAGUETTE
        utilise des cookies et d'autres technologies semblables à des fins
        telles que : ​ l'authentification des utilisateurs ; l'enregistrement
        des préférences et paramètres utilisateur ; l'évaluation de la
        popularité d'un contenu ; l'amélioration et la mesure de l'efficacité
        des campagnes publicitaires ; l'analyse des tendances et de la
        fréquentation des sites Web, et la compréhension globale des
        comportements en ligne et des centres d'intérêt des personnes qui
        interagissent avec nos services. ​ Nous pouvons également autoriser des
        tiers à fournir des mesures d'audience et des services d'analyse pour
        notre compte, à diffuser des publicités en notre nom sur Internet, et à
        effectuer le suivi et le compte-rendu des performances desdites
        publicités. Ces sociétés peuvent utiliser des cookies, des pixels
        espions, des kits de développement logiciel et d'autres technologies
        afin d'identifier les appareils utilisés par les visiteurs pour accéder
        à notre site Web ainsi qu'à d'autres sites Web et services en ligne.
      </div>
      <div>
        <h3>D. Conservation et suppression des données</h3>​ MORNING BAGUETTE
        conserve les données des utilisateurs aussi longtemps que nécessaire aux
        fins décrites ci-dessus. ​<h4>IV. Mises à jour de cette déclaration</h4>
        ​ Nous pouvons mettre à jour cette déclaration de manière occasionnelle.
        L'utilisation de nos services après une mise à jour vaut acceptation de
        la nouvelle déclaration dans la limite autorisée par la loi. Nous
        pouvons mettre à jour cette déclaration de manière occasionnelle. En cas
        de modifications importantes, nous en informons les utilisateurs par
        l'intermédiaire des applications MORNING BAGUETTE ou par d'autres
        moyens, par exemple par e-mail. Nous encourageons les utilisateurs à
        consulter régulièrement cette déclaration afin de connaître les
        dernières informations relatives à nos pratiques en matière de
        confidentialité. Après cette notification, l'utilisation de nos services
        par les utilisateurs vaut acceptation des mises à jour dans la limite
        autorisée par la loi.
      </div>
    </div>
  );
};

export default CGU;
