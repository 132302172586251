import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomSection from "../../../component/common/bottomSection/bottomSection";
import Button from "../../../component/common/button/Button";
import PageColor from "../../../component/common/pageColor/PageColor";
import { useAppSelector } from "../../../store/hooks";
import { Role } from "../../../store/reducers/auth/type";
import { Tabs } from "../../../store/reducers/navBar/type";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import Chiffre from "../components/chiffre/Chiffre";
import styles from "./index.module.css";

interface AnnualResp {
  annualNbOrders: number;
  annualTotalAmount: number;
  currentMonthNbOrders: number;
  currentTotalAmount: number;
}

const SuiviCaParent = ({ adminBakeryId }: { adminBakeryId?: string }) => {
  const [annualReport, setAnnualReport] = useState<AnnualResp>({
    annualNbOrders: 0,
    annualTotalAmount: 0,
    currentMonthNbOrders: 0,
    currentTotalAmount: 0,
  });
  const userBakeryId = useAppSelector((state) => state.auth.user.bakeryId);
  const userRole = useAppSelector((state) => state.auth.user.role);

  const bakeryId = adminBakeryId ?? userBakeryId;
  const navigate = useNavigate();

  useEffect(() => {
    async function getAnnualReport() {
      const resp = await axiosRequest<AnnualResp>({
        method: "get",
        url: `order/annualRecap/${bakeryId}`,
      });
      if (resp.status < 300) {
        setAnnualReport(resp.data);
      }
    }

    getAnnualReport();
  }, [bakeryId]);

  return (
    <PageColor>
      <div className={styles.topPartBakerSmall}>Suivi chiffre d’affaire</div>
      <div className={styles.annualReportPage}>
        <div className={styles.annualTitle}>
          Année en cours {format(Date.now(), "yyyy")}
        </div>
        <Chiffre
          nb={annualReport.annualNbOrders}
          caption={"Nombre commande (année en cours)"}
        />
        <Chiffre
          nb={annualReport.annualTotalAmount}
          caption={"CA (année en cours)"}
          price
        />
        <Chiffre
          nb={annualReport.currentMonthNbOrders}
          caption={"Nombre commande (mois en cours)"}
        />
        <Chiffre
          nb={annualReport.currentTotalAmount}
          caption={"CA (mois en cours)"}
          price
        />
        {userRole === Role.BAKER ? (
          <Button
            text={"Voir le détail"}
            onClick={() => navigate("/suiviCa/tables")}
          />
        ) : null}
      </div>
      {userRole === Role.BAKER ? <BottomSection tab={Tabs.SUIVI_CA} /> : null}
    </PageColor>
  );
};

export default SuiviCaParent;
