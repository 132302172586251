import { MenuItem } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomSection from "../../../../component/common/bottomSection/bottomSection";
import Button from "../../../../component/common/button/Button";
import CustomTextField from "../../../../component/common/customTextField/CustomTextField";
import PageColor from "../../../../component/common/pageColor/PageColor";
import { useSwipeableTemporaryDrawer } from "../../../../component/common/SwipeableTemporaryDrawer/hook";
import { useAppSelector } from "../../../../store/hooks";
import { capitalizeFirstLetter } from "../../../../technical/string/capitalize";
import { Category } from "../../../bakery/components/categoryCard/categoryCard";
import { IProduct } from "../../../categoryDetail/components/product/product";
import productValidator from "../../page/validator";
import Gallery from "../gallery/Gallery";
import styles from "./index.module.css";
import { submitNewProduct, updateProduct } from "./submit";

interface Props {
  initialValues: IProduct;
}

const ProductForm = ({ initialValues }: Props) => {
  const [error, setError] = useState<boolean>(false);
  const bakeryId = useAppSelector((state) => state.auth.user.bakeryId);
  const navigate = useNavigate();
  const { drawerOpen, toggleDrawer, closeDrawer } =
    useSwipeableTemporaryDrawer();

  const formik = useFormik<IProduct>({
    initialValues: initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: productValidator,
    onSubmit: async (values: IProduct) => {
      const { price } = values;
      if (!bakeryId) {
        return;
      }
      if (initialValues.id) {
        await updateProduct(
          formik,
          values,
          price,
          bakeryId,
          () => navigate(-1),
          () => setError(true)
        );
      } else {
        await submitNewProduct(
          formik,
          values,
          price,
          bakeryId,
          () => navigate(-1),
          () => setError(true)
        );
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    navigate(-1);
  };

  const handleSubmit = () => {
    formik.submitForm();
  };

  const NAME_CHARACTER_LIMIT = 30;
  const DESCRIPTION_CHARACTER_LIMIT = 75;

  return (
    <PageColor>
      <div className={styles.topPartBaker}>Ma boutique</div>
      <div className={styles.padding}>
        <div className={styles.title}>Ajouter un Produit</div>
        <div className={styles.description}>
          Remplissez les cases ci dessous pour ajouter votre nouvel article
        </div>
      </div>
      <div className={styles.container}>
        <form onSubmit={formik.handleSubmit} className={styles.formContainer}>
          <Button
            outlined
            text={"Selectionner une image"}
            onClick={toggleDrawer(true)}
          />
          {formik.values.imageUrl ? (
            <div className={styles.description}>Une image est sélectionnée</div>
          ) : null}
          <CustomTextField
            fullWidth
            id="name"
            name="name"
            label="Titre"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.name)}
            required
            helperText={
              Boolean(formik.errors.name)
                ? formik.errors.name
                : `${formik.values.name.length}/${NAME_CHARACTER_LIMIT}`
            }
          />
          <CustomTextField
            fullWidth
            id="price"
            name="price"
            label="Prix"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={formik.values.price}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.price)}
            helperText={
              Boolean(formik.errors.price) ? formik.errors.price : null
            }
            required
          />
          <CustomTextField
            select
            fullWidth
            id="category"
            name="category"
            value={formik.values.category}
            label="Catégorie"
            onChange={formik.handleChange}
            required
          >
            {Object.values(Category).map((cat) => (
              <MenuItem key={cat} value={cat}>
                {capitalizeFirstLetter(cat)}
              </MenuItem>
            ))}
          </CustomTextField>
          <CustomTextField
            fullWidth
            id="description"
            name="description"
            label="Description"
            multiline
            rows={4}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.description)}
            required
            helperText={
              Boolean(formik.errors.description)
                ? formik.errors.description
                : `${formik.values.description.length}/${DESCRIPTION_CHARACTER_LIMIT}`
            }
          />
          {error
            ? "Une erreur est survenue. Veuillez réessayer plus tard"
            : null}
          <div className={styles.buttonSection}>
            <div className={styles.buttonBase}>
              <Button text={"Annuler"} onClick={handleCancel} outlined />
            </div>
            <div className={styles.buttonBase}>
              <Button onClick={handleSubmit} text={"Valider"} />
            </div>
          </div>
        </form>
      </div>
      <Gallery
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        onImageSelect={(value: string) =>
          formik.setFieldValue("imageUrl", value)
        }
        closeDrawer={closeDrawer}
        preSelectedImage={formik.values.imageUrl}
      />
      <BottomSection />
    </PageColor>
  );
};

export default ProductForm;
