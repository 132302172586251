import { axiosRequest } from "../../../technical/api/axiosRequest";

export const createCheckoutSession = async (
  item: {
    price: string;
    quantity: number;
  },
  mode: "subscription" | "payment"
) => {
  const req = await axiosRequest<{
    checkoutUrl: string;
    [key: string]: unknown;
  }>({
    method: "post",
    url: "stripe/create-checkout-session",
    data: {
      item,
      mode,
    },
  });

  if (req.status !== 201) {
    return null;
  }
  return req.data.checkoutUrl;
};
