import { Errors } from "./errors";

export function findCorrespondingError(error: string) {
  switch (error) {
    case Errors.EMAIL_TAKEN:
      return "Cette adresse e-mail est déjà utilisée";
    case Errors.PHONE_NUMBER_TAKEN:
      return "Ce numéro de téléphone est déjà utilisé";
    case Errors.INVALID_CREDENTIALS:
      return "Adresse e-mail ou mot de passe incorrect";
    case Errors.BAKERY_NOT_OPEN:
      return "La boulangerie n'est pas ouverte ce jour-là";
    case Errors.INEXISTANT_PRODUCT:
      return "Un ou plusieurs produits n'existent pas";
    case Errors.WRONG_ORDER:
      return "Une erreur est survenue lors de l'enregistrement de votre commande";
    case Errors.INCORRECT_DATE:
      return "Veuillez sélectionner une date disponible";
    case Errors.NOT_ENOUGH_FUNDS:
      return "Vous n'avez pas assez de fonds";
    case Errors.ORDER_MINIMUM_NOT_REACHED:
      return "Le montant minimum de 2€ par commande n'est pas atteint";
  }
}
