import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../component/common/button/Button";
import { Progress } from "../../../component/common/circularProgress/Progress";
import Marker from "../../../component/marker/Marker";
import { axiosRequest } from "../../../technical/api/axiosRequest";
import { BakeryDetails } from "../../firstSteps/pages/types";
import DrawingMapComponent from "../components/DrawingMapComponent/DrawingMapComponent";
import { SelectBakery } from "../components/selectBakery/SelectBakery";
import styles from "./index.module.css";
import { updateBakeryValidator } from "./updateValidator";

interface UpdateBakery {
  bakeryId: string;
  zone: google.maps.LatLngLiteral[];
}

const render = (status: Status) => {
  switch (status) {
    case Status.LOADING:
      return <div>loading</div>;
    case Status.FAILURE:
      return <div>failed</div>;
    case Status.SUCCESS:
      return <div>success</div>;
  }
};

export const AdminUpdateZone = () => {
  const [bakeries, setBakeries] = useState<BakeryDetails[]>([]);
  const [selectedBakery, setSelectedBakery] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const zoom = 16;
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 48.8566,
    lng: 2.3522,
  });
  const [map, setMap] = useState<google.maps.drawing.DrawingManager>();

  const [polygon, setPolygon] = useState<any>();

  const [success, setSuccess] = useState<string | undefined>();
  const [updateError, setUpdateError] = useState<string | undefined>();

  const initialValues = {
    bakeryId: "",
    zone: [],
  };

  const bakeryUpdateFormik = useFormik<UpdateBakery>({
    initialValues: initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: updateBakeryValidator,
    onSubmit: async (val: UpdateBakery) => {
      try {
        await axiosRequest({
          method: "patch",
          url: "bakery/updateZone",
          data: val,
        });

        setSuccess("Zone modifiée avec succès");
        setUpdateError(undefined);
      } catch (err) {
        setUpdateError("Une erreur est survenue");
        setSuccess(undefined);
      }
    },
  });

  if (map) {
    google.maps.event.addListener(
      map,
      "overlaycomplete",
      function (event: any) {
        if (event.type === "polygon") {
          setPolygon(event.overlay);
          const zone = event.overlay
            .getPath()
            .getArray()
            .map((elt: any) => ({
              lat: elt.lat(),
              lng: elt.lng(),
            }));

          bakeryUpdateFormik.setFieldValue("zone", zone);
        }
      }
    );
  }

  const handleReset = () => {
    if (polygon) {
      polygon.setVisible(false);
    }
  };

  useEffect(() => {
    async function fetchBakeries() {
      const { data, status } = await axiosRequest<BakeryDetails[]>({
        method: "get",
        url: "bakery",
      });
      if (status === 200) {
        setLoading(false);
        setBakeries(data);
        setError(false);
      } else {
        setLoading(false);
        setError(true);
      }
    }
    fetchBakeries();
  }, []);

  useEffect(() => {
    const bakery = bakeries.find((elt) => elt.id === selectedBakery);
    if (bakery) {
      bakeryUpdateFormik.setFieldValue("bakeryId", selectedBakery);
      setCenter({
        lat: bakery.addressInformations.latAndLng[1],
        lng: bakery.addressInformations.latAndLng[0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBakery]);

  const disableButton = () => {
    return (
      bakeryUpdateFormik.values.bakeryId.length === 0 ||
      bakeryUpdateFormik.values.zone.length < 3
    );
  };

  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <div className={styles.updateBakery}>
          {!error ? (
            <>
              <div className={styles.selectSection}>
                <div className={styles.marginBottom}>
                  <SelectBakery
                    bakeries={bakeries}
                    setSelectedBakery={setSelectedBakery}
                    selectedBakery={selectedBakery}
                  />
                </div>
                <div className={styles.buttonsSection}>
                  <div className={styles.buttonSection}>
                    <Button
                      text={"Retour"}
                      onClick={() => navigate("/profile")}
                      outlined
                    />
                  </div>
                  <div className={styles.buttonSection}>
                    <Button
                      text={"Recommencer zone"}
                      onClick={handleReset}
                      outlined
                    />
                  </div>
                  <div className={styles.buttonSection}>
                    <Button
                      text={"Confirmer"}
                      onClick={() => bakeryUpdateFormik.submitForm()}
                      disabled={disableButton()}
                    />
                  </div>
                </div>
                {success ? <div>{success}</div> : null}
                {updateError ? <div>{updateError}</div> : null}
              </div>

              <div className={styles.marginBottom}>
                <Wrapper
                  apiKey={"AIzaSyDbGc10YTTIxoe-c_MP3_spsCPrEamAZY4"}
                  render={render}
                >
                  <DrawingMapComponent
                    zoom={zoom}
                    map={map}
                    setMap={setMap}
                    center={center}
                    className={styles.mapComponent}
                  >
                    <Marker position={center} />
                  </DrawingMapComponent>
                </Wrapper>
              </div>
            </>
          ) : (
            "Une erreur est survenue"
          )}
        </div>
      )}
    </>
  );
};
