import * as yup from "yup";
const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,}$/;

const resetPasswordValidator = yup.object().shape({
  password: yup
    .string()
    .matches(
      passwordRegex,
      "Le mot de passe doit contenir au moins 8 caractères, avec au minimum une lettre majuscule, une lettre minuscule et un chiffre"
    )
    .required(
      "Le mot de passe doit contenir au moins 8 caractères, avec au minimum une lettre majuscule, une lettre minuscule et un chiffre"
    ),
  confirmPassword: yup
    .string()
    .test(
      "passwords-match",
      "Veuillez confirmer votre mot de passe",
      function (value) {
        return this.parent.password === value;
      }
    )
    .required("Veuillez confirmer votre mot de passe"),
});

export default resetPasswordValidator;
