import React from "react";
import styles from "./index.module.css";

const CGV = () => {
  return (
    <div className={styles.page}>
      <h2>CGV </h2>
      Date de dernière mise à jour : 13/01/2023
      <h2>ARTICLE 1 : Objet</h2>
      Les conditions générales de ventes décrites ci-après détaillent les droits
      et obligations de Morning Baguette, site internet / application mobile et
      marque de la société Morning Baguette et de ses clients dans le cadre de
      la vente et de la livraison des marchandises suivantes : Boulangerie /
      Viennoiserie et produits additionnels. Toute prestation accomplie par
      Morning Baguette implique l’adhésion sans réserve de l’acheteur aux
      présentes conditions générales de vente. Les conditions générales
      d'utilisation doivent être acceptées par tout Utilisateur, et son accès au
      site vaut acceptation de ces conditions.
      <h2>ARTICLE 2 : Abonnement</h2>
      L'accès au service Morning Baguette est disponible à partir de 11,90€ par
      mois. Ce tarif comprend tous les frais annexes et la livraison. Les
      mensualités sont prélevées automatiquement une fois par mois ; le jour de
      prélèvement varie en fonction du jour d’abonnement initial du client.
      <h2>ARTICLE 3 : Présentation des produits</h2>
      Les caractéristiques des produits proposés à la vente sont présentées dans
      le menu principal de notre site. Les photographies n’entrent pas dans le
      champ contractuel. La responsabilité de Morning Baguette ne peut être
      engagée si des erreurs s’y sont introduites. Tous les textes et images
      présentés sur le site et l’application mobile de Morning Baguette sont
      réservés, pour le monde entier, au titre des droits d’auteur et de
      propriété intellectuelle ; leur reproduction, même partielle, est
      strictement interdite.
      <h2>ARTICLE 4 : Prix des produits</h2>
      Les pages produits de notre application mobile / site indiquent les prix
      en euros toutes taxes comprises. Morning Baguette se réserve le droit de
      modifier ses prix à tout moment en fonction des demandes de nos
      partenaires. Les produits commandés sont facturés au prix en vigueur lors
      de l'enregistrement de la commande. Aucun escompte ne sera accordé en cas
      de paiement anticipé.
      <h2>ARTICLE 5 : Informations relatives au Planning des livraisons</h2>
      Un planning de livraison est mis à disposition du client. Il se présente
      sous la forme d’un calendrier sur lequel il est possible de commander pour
      le lendemain ou plusieurs jours à l'avance. La commande est validée
      lorsque le client ajoute des produits en cliquant sur le bouton
      "commander".
      <br />
      <br />
      Il est possible de visualiser, modifier ou supprimer sa commande
      directement depuis le planning ou dans l'espace "commande validée". Les
      données enregistrées par Morning Baguette constituent la preuve de la
      nature, du contenu et de la date de la commande. Celle-ci est archivée
      dans les conditions et les délais légaux ; le client peut accéder à cet
      archivage dans "Calendrier".
      <h2>ARTICLE 6 : Informations relatives aux paiements</h2>
      Une cagnotte sera mise à disposition du client. Ce dernier pourra la
      créditer en effectuant un paiement en ligne d’un montant minimum de 25
      euros. <br />
      Chaque livraison débitera la cagnotte du client du montant correspondant à
      ses achats programmés dans le planning.
      <h2>ARTICLE 7 : Les livraisons</h2>
      Les livraisons ont lieu le matin avant 7h du lundi au vendredi. Les
      samedi, dimanches et jours fériés, les livraisons ont lieu avant 8h selon
      l'ouverture de nos partenaires fournisseur. La livraison n'est donc pas
      garantie 365 jours par an.
      <h2>ARTICLE 8 : Cas de force majeures</h2>
      Morning Baguette ne pourra être tenu pour responsable en cas de survenance
      d’un événement de force majeure tel qu’entendu par la jurisprudence
      habituelle des tribunaux français (événement imprévisible, irrésistible et
      extérieur). Morning Baguette sera notamment déchargé de son obligation de
      livraison si celle-ci est perturbée, empêchée, limitée en raison
      d’intempéries, pannes matérielles, incendie, problème d’approvisionnement
      ou tout autre circonstance hors du contrôle raisonnable de Morning
      Baguette. Dans ce cas, Morning Baguette contactera le Client pour l’en
      informer, et ce, dans les plus brefs délais. Tout événement résultant de
      l’action du livreur ne pourra incomber à la responsabilité de l’entreprise
      Morning Baguette. Cette responsabilité incombera à la boulangerie
      partenaire ou directement au livreur, en fonction de son statut ( employé
      ou auto-entrepreneur).
      <h2>ARTICLE 9 : Relation Client - Service Après-Vente</h2>
      Pour toute information, question ou réclamation, le client peut s’adresser
      : Par téléphone au numéro de contact indiqué dans son espace client du
      lundi au vendredi de 8h à 12h et de 13h à 17h, le week-end et les jours
      férié de 8h à 11h. Ou via le formulaire de contact, nous vous répondrons
      dans les meilleurs délais.
      <h2>ARTICLE 10 : Résiliation de l'abonnement</h2>
      Le client peut résilier à tout moment par simple notification via le
      formulaire de contact. NB : Morning Baguette ne procèdera pas au
      remboursement des cagnottes, il est donc conseillé de consommer
      l'intégralité de son crédit.
    </div>
  );
};

export default CGV;
