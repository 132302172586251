import * as yup from "yup";

const forgotPassValidator = yup.object().shape({
  email: yup
    .string()
    .email("L'adresse mail doit être valide")
    .required("Ce champ est requis"),
});

export default forgotPassValidator;
