import React from "react";
import styles from "./index.module.css";
import BottomSection from "../../../../component/common/bottomSection/bottomSection";
import { ReactComponent as Validation } from "../../../../svg/success.svg";
import { Tabs } from "../../../../store/reducers/navBar/type";
import PageColor from "../../../../component/common/pageColor/PageColor";
import classNames from "classnames";

const SuccessPayment = () => {
  return (
    <PageColor>
      <div className={classNames(styles.title, styles.centerFlex)}>Merci !</div>
      <div className={classNames(styles.centerFlex, styles.container)}>
        <Validation
          className={classNames(styles.svgSize, styles.marginBottom)}
        />
        <p className={styles.text}>Paiement effectué avec succès</p>
      </div>
      <BottomSection tab={Tabs.PROFILE} />
    </PageColor>
  );
};

export default SuccessPayment;
