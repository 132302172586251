import React from "react";
import UnconnectedPage from "../../../component/common/unconnectedPage/UnconnectedPage";
import { UserLogin } from "../../../store/reducers/auth/type";
import LoginForm from "../components/loginForm/LoginForm";
import styles from "./index.module.css";

const LoginPage = () => {
  const initialValues: UserLogin = {
    email: "",
    password: "",
  };

  return (
    <UnconnectedPage className={styles.unconnectedPage}>
      <div className={styles.firstContainer}>
        <div className={styles.registrationText}></div>
        <img
          src="/assets/logo/orange_black.png"
          alt="Logo Morning Baguette"
          className={styles.logo}
        />
      </div>
      <LoginForm initialValues={initialValues} className={styles.marginAuto} />
    </UnconnectedPage>
  );
};

export default LoginPage;
