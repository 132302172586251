import React from "react";
import Button from "../../../../component/common/button/Button";
import { ReactComponent as Fail } from "../../../../svg/fail.svg";
import styles from "./index.module.css";

const FailedPage = ({ handleGoBack }: { handleGoBack: () => void }) => {
  return (
    <div className={styles.container}>
      <Fail />
      <div className={styles.paragraph}>
        <div className={styles.title}>
          Votre boîte aux lettre n’est pas éligible.
        </div>
        <div className={styles.text}>
          Vous ne pouvez malheureusement pas vous abonner à notre service pour
          l’instant.
        </div>
        <div className={styles.text}>
          N’hésitez pas à re-évaluer vos informations de livraison si vous
          pensez que celles-ci ont évolué.
        </div>
        <div className={styles.text}>
          Si vous avez un doute, veuillez prendre contact avec notre équipe à
          l’adresse suivante:
        </div>
        <a
          href="mailto:morningbaguettecontact@gmail.com"
          className={styles.text}
        >
          morningbaguettecontact@gmail.com
        </a>
        <div className={styles.text}>
          Vous pourrez éventuellement trouver une solution ensemble.
        </div>
      </div>
      <div className={styles.button}>
        <Button onClick={handleGoBack} text={"Retour"} />
      </div>
    </div>
  );
};

export default FailedPage;
