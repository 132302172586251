import classNames from "classnames";
import React from "react";
import styles from "./index.module.css";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const UnconnectedPage = ({ className, children }: Props) => {
  return (
    <div className={classNames(styles.mainContainer, className)}>
      {children}
    </div>
  );
};

export default UnconnectedPage;
