import { isTomorrow, format, isToday } from "date-fns";
import fr from "date-fns/locale/fr";

export function convertDateToString(date: Date) {
  if (isToday(date)) {
    return `Aujourd'hui, ${format(date, "EEEE dd MMM", { locale: fr })}`;
  }
  if (isTomorrow(date)) {
    return `Demain, ${format(date, "EEEE dd MMM", { locale: fr })}`;
  }
  return format(date, "EEEE dd MMM", { locale: fr });
}
