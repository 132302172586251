import React from "react";
import styles from "./index.module.css";

const MentionLegale = () => {
  return (
    <div className={styles.page}>
      <h2>Mentions Légale :</h2>
      <h2>Editeur</h2>
      <h3>Siège social :</h3> MORNING BAGUETTE SAS
      <br /> 5 bis avenue Manin
      <br /> 92160 Antony
      <h3>Nos bureaux :</h3>
      MORNING BAGUETTE SAS
      <br />
      63 bis Rue de Sevres <br />
      92100 Boulogne-Billancourt <br />
      <br />
      Siret : 94795504300015
      <br /> Forme juridique : SAS au capital de 1000€
      <br />
      <br />
      <b>Email de contact</b>
      <br /> morningbaguettecontact@gmail.com
      <br />
      <br />
      <b>Numéro de téléphone </b>
      <br /> 06 86 46 80 54 / 06 76 66 37 13
      <br />
      <br />
      <b>Contact via le site </b>
      <br />
      <a
        target="_blank"
        href="https://www.morningbaguette.fr/particulier"
        rel="noreferrer"
      >
        Nous contacter par formulaire
      </a>
      <h2>Editeur</h2>
      <a target="_blank" href="https://www.ovhcloud.com/fr/" rel="noreferrer">
        <b>OVH Cloud</b>
      </a>
      <br />
      2 rue Kellermann
      <br />
      59100 Roubaix
      <br />
      France
    </div>
  );
};

export default MentionLegale;
