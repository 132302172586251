import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  appState,
  initialState as appStateInitialState,
} from "./reducers/appState";
import { authSlice, initialState as authInitialState } from "./reducers/auth";
import { cartSlice, initialState as cartInitialState } from "./reducers/cart";
import {
  habitCartSlice,
  initialState as habitInitialState,
} from "./reducers/habitCart";
import {
  navBarSlice,
  initialState as navBarInitialState,
} from "./reducers/navBar";
import {
  registrationSlice,
  initialState as registrationInitialState,
} from "./reducers/registration";

const combinedReducer = combineReducers({
  cart: cartSlice.reducer,
  auth: authSlice.reducer,
  navBar: navBarSlice.reducer,
  registration: registrationSlice.reducer,
  habitCart: habitCartSlice.reducer,
  appState: appState.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["cart", "auth", "habitCart"],
};

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") {
    storage.removeItem("persist:root");
    return combinedReducer(
      {
        cart: cartInitialState,
        auth: authInitialState,
        navBar: navBarInitialState,
        registration: registrationInitialState,
        habitCart: habitInitialState,
        appState: appStateInitialState,
      },
      action
    );
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
