import React from "react";
import HorizontalDivider from "../../../../component/common/horizontalDivider/horizontalDivider";
import { capitalizeFirstLetter } from "../../../../technical/string/capitalize";
import { Product } from "../../../cart/OrderCart";
import { IProduct } from "../../../categoryDetail/components/product/product";
import styles from "./index.module.css";

interface Props {
  category: string;
  orders: Omit<IProduct, "imageUrl">[];
}

export const ProductLineQuantity = ({
  name,
  orders,
}: {
  name: string;
  orders: Omit<IProduct, "imageUrl">[] | Product[];
}) => (
  <div className={styles.maxHeight} key={name}>
    <div className={styles.orderLine}>
      <div className={styles.text}>{name}</div>
      <div className={styles.text}>
        {orders.filter((elt) => elt.name === name).length}
      </div>
    </div>
  </div>
);

const DisplayOrder = ({ category, orders }: Props) => {
  if (!orders || orders.length === 0) {
    return null;
  }

  const arrayFromSetOfNames = Array.from(
    new Set(orders.map((item) => item.name))
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>{capitalizeFirstLetter(category)}</div>
      <div className={styles.orderContainer}>
        <div className={styles.orderLine}>
          <div className={styles.subTitles}>Articles</div>
          <div className={styles.subTitles}>Quantité</div>
        </div>
        {arrayFromSetOfNames.map((name) => (
          <>
            <ProductLineQuantity name={name} orders={orders} />

            <HorizontalDivider />
          </>
        ))}
      </div>
    </div>
  );
};

export default DisplayOrder;
