import { Divider } from "@mui/material";
import classNames from "classnames";
import React from "react";
import styles from "./index.module.css";

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const HorizontalDivider = (props: Props) => {
  return (
    <Divider
      className={classNames(styles.divider, props.className)}
      style={props.style}
    />
  );
};

export default HorizontalDivider;
