import React from "react";
import HorizontalDivider from "../../../../component/common/horizontalDivider/horizontalDivider";
import { useAppSelector } from "../../../../store/hooks";
import { Role } from "../../../../store/reducers/auth/type";
import { addZero } from "../../../../technical/string/addZero";
import { capitalizeFirstLetter } from "../../../../technical/string/capitalize";
import { Category } from "../../../bakery/components/categoryCard/categoryCard";
import SelectHabitProductQuantity from "../selectHabitProductQuantity/SelectHabitProductQuantity";
import SelectProductModify from "../selectProductModify/selectProductModify";
import SelectProductQuantity from "../selectProductQuantity/selectProductQuantity";
import styles from "./index.module.css";

export interface IProduct {
  id: string;
  name: string;
  description: string;
  price: number;
  category: Category;
  imageUrl: string;
}

interface Props {
  productProps: IProduct;
  isHabit: boolean;
}

const Product = ({ productProps, isHabit }: Props) => {
  const { id, name, description, price, category, imageUrl } = productProps;
  const userRole = useAppSelector((state) => state.auth.user?.role);
  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        <div className={styles.productContainer}>
          <div className={styles.name}>{capitalizeFirstLetter(name)}</div>
          <div>
            <div className={styles.price}>
              {addZero((price / 100).toString())} €
            </div>
            <div className={styles.description}>{description}</div>
          </div>
        </div>
        {userRole === Role.USER ? (
          isHabit ? (
            <SelectHabitProductQuantity
              id={id}
              name={name}
              price={price}
              category={category}
              imageUrl={imageUrl}
            />
          ) : (
            <SelectProductQuantity
              id={id}
              name={name}
              price={price}
              category={category}
              imageUrl={imageUrl}
            />
          )
        ) : (
          <SelectProductModify id={id} imageUrl={imageUrl} />
        )}
      </div>
      <HorizontalDivider className={styles.divider} />
    </div>
  );
};

export default Product;
