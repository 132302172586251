import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";

export enum Category {
  PAINS_SPECIAUX = "special",
  VIENNOISERIES = "viennoiserie",
  PAINS_CLASSIQUES = "classique",
  AUTRES = "autre",
}

interface Props {
  category: Category;
  imageUri: string;
  className?: string;
  isHabit?: boolean;
}

const CategoryCard: React.FC<Props> = ({
  category,
  imageUri,
  className,
  isHabit,
}: Props) => {
  const navigate = useNavigate();

  const onClickNavigateToCategoryPage = () => {
    navigate(
      `/category/${category}/${isHabit === undefined ? false : isHabit}`
    );
  };

  return (
    <div
      style={{
        backgroundImage: `url(${imageUri})`,
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
      }}
      className={classNames(className, styles.ombre)}
      onClick={onClickNavigateToCategoryPage}
    ></div>
  );
};

export default CategoryCard;
