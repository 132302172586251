import { HabitsDays } from "../../business/habits/page/useHabitsSelector";

const weekDayMap = new Map<HabitsDays, string>([
  [HabitsDays.DIMANCHE, "Habitudes du Dimanche"],
  [HabitsDays.SAMEDI, "Habitudes du Samedi"],
  [HabitsDays.VENDREDI, "Habitudes du Vendredi"],
  [HabitsDays.JEUDI, "Habitudes du Jeudi"],
  [HabitsDays.MERCREDI, "Habitudes du Mercredi"],
  [HabitsDays.MARDI, "Habitudes du Mardi"],
  [HabitsDays.LUNDI, "Habitudes du Lundi"],
]);

export function convertWeekDayToString(day: HabitsDays): string {
  if (Object.values(HabitsDays).includes(day)) {
    return weekDayMap.get(day)!;
  }
  return "Erreur";
}
